import axios from "axios";
import { appConfig } from "../configs/app-config";
import { loggerService } from "./logger";
import { store } from "../state-management/store";
import { triggerNotification } from "../state-management/actions";
import { errorStr } from "./utils";
import { tokenManager } from "./token-manager";
import { loginService } from "./login";
import {
  goToLoginPage,
  NUMBERS,
  triggerSuccessNotification,
} from "../ui/pages/reports/components/common-utils";
import { UIText } from "../ui/pages/reports/components/label-constants";

/**
 * This service is responsible for making HTTP calls to the remote server.
 */
class HttpService {
  /**
   * This function logs the error gracefully to the end user, depending on what environment the application is running on.
   * The error includes any error that might occur while making an API call.
   * @param   {string} err  the error object that contains the error information.
   */
  triggerErrorNotification(err) {
    if (appConfig.isDev) {
      if (err?.hasOwnProperty("message")) {
        store.dispatch(
          triggerNotification({
            type: {
              style: errorStr,
              icon: true,
            },
            message: err.response.data?.error_details?.emailAddress[0]
              ? err.response.data?.error_details?.emailAddress[0]
              : err?.message,
          })
        );
      } else {
        store.dispatch(
          triggerNotification({
            type: {
              style: errorStr,
              icon: true,
            },
            message: UIText.Failed_HTTP_Call,
          })
        );
      }
    }
  }

  getAuthHeader(requestHeaders) {
    const accessToken = store.getState()?.login?.access_token;
    const idToken = store.getState()?.login?.id_token;
    return {
      headers: {
        Authorization: `Bearer#${accessToken}#${idToken}`,
        ...requestHeaders,
      },
    };
  }

  isTokenExpired = () => {
    const access_token = store.getState()?.login?.access_token;
    const decodedToken = tokenManager.decodeToken(access_token);
    let valid = true;
    if (decodedToken?.exp) {
      const tokenExpiryTime = new Date(decodedToken?.exp * 1000);
      const currentDate = new Date();
      const oneMinuteAgo = new Date(
        currentDate.getTime() - NUMBERS.TWO * NUMBERS.SIXTY * NUMBERS.THOUSAND
      );
      if (tokenExpiryTime < oneMinuteAgo) {
        valid = false;
      }
    }
    return valid;
  };

  async getTokenStatus() {
    let tokenValid = this.isTokenExpired();
    if (!tokenValid) {
      const refreshed = await loginService.refreshAuthToken();
      if (!refreshed) {
        localStorage.clear();
      } else {
        tokenValid = true;
      }
    }
    return tokenValid;
  }

  handleHttpError(error) {
    this.triggerErrorNotification(error);
    if (error?.status === NUMBERS.FOUR_ZERO_ONE) {
      goToLoginPage();
      return null;
    }
    return error;
  }
  /**
   * Makes an HTTP GET request to the remote server.
   * @param {string} URL the URL of the GET endpoint.
   */
  async get(URL, headers = {}) {
    const tokenValid = await this.getTokenStatus();
    if (tokenValid) {
      const authHeader = this.getAuthHeader(headers);
      try {
        const response = await axios.get(URL, authHeader);
        if (response?.status === NUMBERS.TWO_ZERO_FOUR) {
          triggerSuccessNotification(UIText.no_records_available);
          return null;
        }
        return response;
      } catch (error) {
        return this.handleHttpError(error);
      }
    }
    return null;
  }

  async checkTokenExpiryStatus(options) {
    const loginTokenCall = options?.loginTokenCall;
    let tokenValid = false;
    if (loginTokenCall) {
      tokenValid = true;
    } else {
      tokenValid = await this.getTokenStatus();
    }
    return { tokenValid, loginTokenCall };
  }

  /**
   * Makes an HTTP POST request to the remote server.
   * @param {string} URL the URL of the POST endpoint.
   */
  async post(URL, payload = {}, options = {}) {
    const { tokenValid, loginTokenCall } = await this.checkTokenExpiryStatus(
      options
    );
    if (!tokenValid) {
      return null;
    }
    if (options?.headers?.Authorization) {
      try {
        const response = await axios.post(URL, payload, options);
        if (response?.status === NUMBERS.TWO_ZERO_FOUR) {
          triggerSuccessNotification(UIText.no_records_available);
          return null;
        }
        return response;
      } catch (error) {
        return this.handleHttpError(error);
      }
    } else {
      const authHeader = this.getAuthHeader(options.headers);
      try {
        const response = await axios.post(URL, payload, authHeader);
        if (response?.status === NUMBERS.TWO_ZERO_FOUR) {
          triggerSuccessNotification(UIText.no_records_available);
          return null;
        }
        return response;
      } catch (error) {
        if (!loginTokenCall) {
          return this.handleHttpError(error);
        }
        return error;
      }
    }
  }

  async patch(URL, payload = {}) {
    const tokenValid = await this.getTokenStatus();
    if (tokenValid) {
      const authHeader = this.getAuthHeader();
      try {
        return await axios.patch(URL, payload, authHeader);
      } catch (error) {
        return this.handleHttpError(error);
      }
    }
    return null;
  }

  async put(URL, payload = {}, headers = {}) {
    const tokenValid = await this.getTokenStatus();
    if (tokenValid) {
      const authHeader = this.getAuthHeader();
      authHeader.headers = { ...authHeader.headers, ...headers };
      try {
        return await axios.put(URL, payload, authHeader);
      } catch (error) {
        return this.handleHttpError(error);
      }
    }
    return null;
  }

  async downloadFile(URL, fileName) {
    const tokenValid = await this.getTokenStatus();
    if (tokenValid) {
      const authHeader = this.getAuthHeader();
      try {
        const response = await axios.get(URL, {
          headers: authHeader.headers,
          responseType: "arraybuffer",
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        this.handleHttpError(error);
      }
    }
  }
}

loggerService.dev("Creating HTTP Service");
const httpService = new HttpService();

loggerService.dev("Exporting HTTP Service");
export { httpService };
