import "./eom-summary.scss";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { reportsService } from "../../../../../services/reports";
import { store } from "../../../../../state-management/store";
import {
  childColumnsToShow,
  colsToShow,
  LogDetails,
} from "./eom-summary-utils";
import { MultiSelectionDropDown } from "../../../../components/multi-select-dropdown/multi-select-dropdown";
import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import { SiteInfoScreen } from "../out-of-tolerance-trend-analysis/out-of-tolerance-site-info";
import {
  getFilterRegions,
  getYearsFrom,
  getMonths,
  getTankGroupListForMaterial,
  getMonthsData,
} from "../../reportUtils";
import {
  DEFAULT_PAGE_SIZE,
  getSelectedCluster,
  isDachCluster,
  isDefaultDataLoadEnabled,
  NUMBERS,
  triggerErrorNotification,
  triggerSuccessNotification,
} from "../common-utils";
import { Button, DropDownList } from "@progress/kendo-react-all";
import ConfirmPopup from "../../../../components/confirmation-popup/confirmation-popup";
import { UIText } from "../label-constants";
import { getFormattedFullDate } from "../../../../../services/utils";
import {
  onFilterSelectionChange,
  onOBNChange,
  onShipToValueChange,
  onSiteNameChange,
  setProductDetailsWhenShipToChange,
  setShipToDetailsWhenRegionChange,
} from "../common-report-filters";

const DropDownSelection = {
  ShipTo: NUMBERS.ZERO,
  SiteName: NUMBERS.ONE,
  OBN: NUMBERS.TWO,
};

const EOMSummaryScreen = () => {
  const [appliedFilters, setAppliedFilters] = useState(null);
  const [resetIndex, setResetIndex] = useState(false);
  const [currentField, setCurrentField] = useState(null);
  const [showSiteInfoModal, setShowSiteInfoModal] = useState(false);
  const [selectedSite, setSelectedSite] = useState();
  const [masterData, setMasterData] = useState();
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [currentSubmitItem, setCurrentSubmitItem] = useState();
  const [currentLogItem, setCurrentLogItem] = useState();
  const [currentOffSet, setCurrentOffSet] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [modalContent, setModalContent] = useState();
  const [firstLoad, setFirstLoad] = useState(false);

  const getDefaultFilters = () => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - NUMBERS.ONE);
    return {
      selectedYear: getYearsFrom(NUMBERS.TWO_THOUSAND_TWENTY_THREE)?.[0],
      years: getYearsFrom(NUMBERS.TWO_THOUSAND_TWENTY_THREE),
      selectedMonth: {
        id: new Date().getMonth(),
        text: currentDate.toLocaleString("en-US", { month: "short" }),
      },
      months: getMonths(),
      selectedShipTo: null,
      selectedRegions: [],
      regions: getFilterRegions(masterData, false),
      selectedSiteName: null,
      selectedOBN: [],
      allOBNs: [],
      selectedProducts: [],
      products: [],
      selectedTanks: [],
      tankGroup: [],
      allSiteIds: [],
      allSiteNames: [],
    };
  };

  const [filters, setFilters] = useState(getDefaultFilters());
  const [eomSummaryData, setEomSummaryData] = useState([]);
  const [showError, setShowError] = useState(false);
  const [exporting, setExporting] = useState(false);
  const exportGridRef = useRef(null);
  const [error, setError] = useState("");
  const [filterDataLoading, setFilterDataLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [logsLoading, setLogsLoading] = useState(false);
  const defaultCountry = useSelector(
    (state) => state?.reports?.selectedCountry
  );
  const [currentCountryCode, setCurrentCountryCode] = useState(defaultCountry);
  const [regionSelectedValues, setRegionSelectedValues] = useState(
    filters.selectedRegions
  );

  store.subscribe(() => {
    const { selectedCountry } = store.getState().reports;
    setCurrentCountryCode(selectedCountry);
  });

  const [shipToSelectedValues, setShipToSelectedValues] = useState(
    filters.selectedShipTo
  );

  const [siteNameSelectedValues, setSiteNameSelectedValues] = useState(
    filters.selectedSiteName
  );
  const [obnSelectedValues, setObnSelectedValues] = useState(
    filters.selectedOBN
  );

  const [productSelectedValues, setProductSelectedValues] = useState(
    filters?.selectedProducts
  );
  const [tankSelectedValues, setTankSelectedValues] = useState(
    filters?.selectedTanks
  );

  useEffect(() => {
    setFilters((_prev) => getDefaultFilters());
    setShipToSelectedValues([]);
    setRegionSelectedValues([]);
    fetchMasterData();
    setFirstLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountryCode]);

  useEffect(() => {
    if (
      !firstLoad &&
      filters.selectedTanks?.length > 0 &&
      isDefaultDataLoadEnabled()
    ) {
      fetchEomSummaryData();
      setFirstLoad(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedTanks]);

  useEffect(() => {
    const year = parseInt(filters?.selectedYear?.text);
    const months = getMonthsData(year, filters, false);
    const currentYear = new Date().getFullYear();

    let selectedMonth = filters.selectedMonth;
    const currentMonth = new Date().getMonth();
    if (year === currentYear && selectedMonth.id > currentMonth + NUMBERS.ONE) {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - NUMBERS.ONE);
      selectedMonth = {
        id: currentDate.getMonth() + NUMBERS.ONE,
        text: currentDate.toLocaleString("en-US", { month: "short" }),
      };
    }

    setFilters((prev) => {
      return {
        ...prev,
        months: months,
        selectedMonth: selectedMonth,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedYear]);

  useEffect(() => {
    const regions = getFilterRegions(masterData, false, currentCountryCode);
    const selectedRegions =
      regions?.length > NUMBERS.ZERO ? [regions?.[0]] : [];

    setFilters((prev) => {
      return {
        ...prev,
        selectedRegions: selectedRegions,
        regions: regions,
      };
    });
    setRegionSelectedValues(selectedRegions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterData]);

  useEffect(() => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedRegions: regionSelectedValues,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionSelectedValues]);

  useEffect(() => {
    setShipToDetailsWhenRegionChange(
      filters,
      masterData,
      setFilters,
      setShipToSelectedValues,
      setSiteNameSelectedValues,
      setObnSelectedValues
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedRegions]);

  useEffect(() => {
    setProductDetailsWhenShipToChange(
      masterData,
      filters,
      setFilters,
      setProductSelectedValues,
      true
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.selectedShipTo]);

  useEffect(() => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedProducts: productSelectedValues,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSelectedValues]);

  const setTankValuesOnProductChange = () => {
    const tankGroupsData = getTankGroupListForMaterial(
      masterData,
      filters,
      true
    );
    const selectedTanks = tankGroupsData?.length > 0 ? [tankGroupsData[0]] : [];
    setFilters((prev) => {
      return {
        ...prev,
        tankGroup: tankGroupsData,
        selectedTanks: selectedTanks,
      };
    });

    setTankSelectedValues(selectedTanks);
  };

  useEffect(() => {
    setTankValuesOnProductChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedProducts]);

  useEffect(() => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedTanks: tankSelectedValues,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tankSelectedValues]);

  useEffect(() => {
    if (currentField === DropDownSelection.ShipTo) {
      onShipToValueChange(
        filters,
        setFilters,
        shipToSelectedValues,
        setObnSelectedValues,
        setSiteNameSelectedValues
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipToSelectedValues]);

  useEffect(() => {
    if (currentField === DropDownSelection.SiteName) {
      onSiteNameChange(
        filters,
        setFilters,
        siteNameSelectedValues,
        setShipToSelectedValues,
        setObnSelectedValues
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteNameSelectedValues]);

  useEffect(() => {
    if (currentField === DropDownSelection.OBN) {
      onOBNChange(
        filters,
        setFilters,
        obnSelectedValues,
        setShipToSelectedValues,
        setSiteNameSelectedValues
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obnSelectedValues]);

  const onShipToSelection = (event) => {
    setCurrentField(DropDownSelection.ShipTo);
    onFilterSelectionChange(
      event,
      setShipToSelectedValues,
      filters?.allSiteIds,
      true
    );
  };

  const onSiteNameSelection = (event) => {
    setCurrentField(DropDownSelection.SiteName);
    onFilterSelectionChange(
      event,
      setSiteNameSelectedValues,
      filters?.allSiteNames,
      true
    );
  };

  const onOBNSelection = (event) => {
    setCurrentField(DropDownSelection.OBN);
    onFilterSelectionChange(
      event,
      setObnSelectedValues,
      filters?.allOBNs,
      true
    );
  };

  const onProductSelection = (event) => {
    onFilterSelectionChange(
      event,
      setProductSelectedValues,
      filters?.products,
      true
    );
  };

  const onTankSelection = (event) => {
    onFilterSelectionChange(
      event,
      setTankSelectedValues,
      filters?.tankGroup,
      true
    );
  };
  const onYearSelection = (event) => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedYear: event,
      };
    });
  };

  const onMonthSelection = (event) => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedMonth: event,
      };
    });
  };

  const fetchMasterData = async () => {
    try {
      setEomSummaryData([]);
      setShowError(false);
      setFilterDataLoading(true);
      const response = await reportsService.fetchToleranceTrendMasterData({
        country: currentCountryCode,
      });
      setFilterDataLoading(false);
      if (response) {
        setMasterData(response);
      } else {
        triggerErrorNotification(UIText.Unable_to_fetch_the_data);
      }
    } catch (error1) {
      triggerErrorNotification(
        UIText.Server_error_Unable_to_submit_the_request
      );
      setError(UIText.Server_error_Unable_to_submit_the_request);
      setFilterDataLoading(false);
      setError("");
    }
  };

  const onRegionSelection = (event) => {
    onFilterSelectionChange(
      event,
      setRegionSelectedValues,
      filters?.regions,
      true
    );
  };

  const showHideLoaders = (show = false) => {
    setExporting(show);
  };
  const exportData = async () => {
    showHideLoaders(true);
    exportGridRef?.current?.exportDataInExcel &&
      (await exportGridRef.current.exportDataInExcel());

    setTimeout(showHideLoaders, NUMBERS.THOUSAND);
  };

  const getSelectedFilter = (refresh) => {
    return refresh ? appliedFilters : filters;
  };

  const getSelectedShipTo = (filter) => {
    return filter?.selectedShipTo?.[0]?.id === "all"
      ? UIText.ALL
      : filter?.selectedShipTo?.[0]?.localSiteId;
  };

  const getFilteredProducts = (selectedProducts) => {
    return selectedProducts?.length > NUMBERS.ZERO
      ? selectedProducts
      : [UIText.ALL];
  };

  const getFilteredTanks = (selectedTankIds) => {
    return selectedTankIds?.length > NUMBERS.ZERO
      ? selectedTankIds
      : [UIText.ALL];
  };

  const checkMandatoryValues = (
    filteredTanks,
    filteredProducts,
    selectedShipTo,
    selectedRegions
  ) => {
    const filterSelected =
      selectedRegions && selectedShipTo && filteredTanks && filteredProducts;

    const valuesSelected = getSelectedCluster() && filterSelected;

    return valuesSelected;
  };

  const getDataPayload = (
    selectedShipTo,
    filteredTanks,
    filteredProducts,
    selectedYear,
    selectedMonth
  ) => {
    let payload = {};
    if (currentCountryCode) {
      payload = { ...payload, CountryCode: currentCountryCode };
    }
    if (selectedYear) {
      payload = { ...payload, Year: selectedYear };
    }
    if (selectedMonth) {
      payload = { ...payload, Month: selectedMonth };
    }
    if (selectedShipTo && selectedShipTo !== UIText.ALL) {
      payload = { ...payload, LegacySiteIds: [selectedShipTo] };
    }
    if (filteredTanks && filteredTanks?.[0] !== UIText.ALL) {
      payload = { ...payload, TankGroupIds: filteredTanks };
    }
    if (filteredProducts && filteredProducts?.[0] !== UIText.ALL) {
      payload = { ...payload, MaterialNumbers: filteredProducts };
    }

    return payload;
  };

  const getStockParentItem = (item) => {
    return {
      childItems: item?.childItems,
      globalSiteId: item?.globalSiteId,
      obn: item?.obn,
      partitionKey: item?.partitionKey,
      plant: item?.plant,
      requestDateUTC: item?.requestDateUTC,
      rowKey: item?.rowKey,
      siteId: item?.siteId,
      region: item?.region,
      sloc: item?.sloc,
      totalStock: item?.totalStock > 0 ? Math.round(item.totalStock) : null,
    };
  };

  const getSummaryResponse = async (payload) => {
    const response = await reportsService.fetchEomSummaryData(payload);
    let stockData = [];
    response?.forEach((item) => {
      let childItems = [];
      const itemExist = stockData?.find(
        (stock) => stock.globalSiteId === item.globalSiteId
      );
      if (itemExist) {
        childItems = itemExist?.childItems ? itemExist?.childItems : [];
      }
      childItems = [
        ...childItems,
        {
          ...item,
          currentStock: item?.currentStock
            ? Math.round(item?.currentStock)
            : null,
        },
      ];
      item.childItems = childItems;
      item.totalStock = childItems.reduce(
        (total, childItem) => total + childItem.currentStock,
        0
      );
      if (itemExist) {
        stockData = stockData.map((stockItem) =>
          stockItem.globalSiteId === item.globalSiteId
            ? getStockParentItem(item)
            : stockItem
        );
      } else {
        stockData = [...stockData, getStockParentItem(item)];
      }
    });
    return stockData;
  };

  const fetchEomSummaryData = async (refresh = false) => {
    const filter = getSelectedFilter(refresh);
    const selectedShipTo = getSelectedShipTo(filter);
    const selectedYear = filter.selectedYear?.id;
    const selectedMonth = filter.selectedMonth?.id;

    const selectedRegions = filter?.selectedRegions?.map((region) =>
      region?.id === "all" ? UIText.ALL : region?.id
    );
    const selectedTankIds = filter?.selectedTanks?.map((tank) => {
      return tank?.id === "all" ? UIText.ALL : tank?.id;
    });
    const selectedProducts = filter?.selectedProducts?.map((product) =>
      product?.id === "all" ? UIText.ALL : product?.id
    );
    const filteredProducts = getFilteredProducts(selectedProducts);
    const filteredTanks = getFilteredTanks(selectedTankIds);

    const valuesSelected = checkMandatoryValues(
      filteredTanks,
      filteredProducts,
      selectedShipTo,
      selectedRegions
    );

    if (valuesSelected) {
      const payload = getDataPayload(
        selectedShipTo,
        filteredTanks,
        filteredProducts,
        selectedYear,
        selectedMonth
      );
      setShowError(false);
      setSubmitLoading(true);
      if (!refresh) {
        setAppliedFilters(filter);
      }
      try {
        const response = await getSummaryResponse(payload);
        if (response instanceof Array) {
          response?.sort((a, b) => new Date(b.date) - new Date(a.date));
        }

        setSubmitLoading(false);
        setEomSummaryData(response);
      } catch (error1) {
        triggerErrorNotification(
          UIText.Server_error_Unable_to_submit_the_request
        );
        setError(UIText.Server_error_Unable_to_submit_the_request);
        setSubmitLoading(false);
        setError("");
      }
    } else {
      setShowError(true);
    }
  };

  const submitGSAPRequest = async () => {
    setSendLoading(true);
    try {
      const selectedYear = filters.selectedYear?.id;
      const selectedMonth = filters.selectedMonth?.id;
      const payload = {
        country: currentCountryCode,
        globalSiteId: currentSubmitItem?.globalSiteId,
        targetMonth: selectedMonth,
        targetYear: selectedYear,
      };
      const response = await reportsService.sendToGSAPStock(payload);
      if (
        response?.status >= NUMBERS.TWO_HUNDRED &&
        response?.status < NUMBERS.THREE_HUNDRED
      ) {
        triggerSuccessNotification(
          UIText.Stock_level_accepted_submitted_for_processing
        );
      }
    } catch (error1) {
      triggerErrorNotification(
        UIText.Server_error_Unable_to_submit_the_request
      );
    }
    setSendLoading(false);
  };

  const sendToGSAPAction = async (item, offset) => {
    setCurrentOffSet(offset);
    setShowConfirmationPopup(true);
    setCurrentSubmitItem(item);
  };

  const viewLogAction = async (item) => {
    setCurrentLogItem(item);
    setLogsLoading(true);
    try {
      const payload = {
        country: currentCountryCode,
        rowKey: item.rowKey,
      };
      const response = await reportsService.getMonthEndStockLogs(payload);
      if (
        response &&
        response?.status >= NUMBERS.TWO_HUNDRED &&
        response?.status < NUMBERS.THREE_HUNDRED
      ) {
        try {
          const parsedMessage = JSON.parse(response?.data?.Message);
          const messageJSON = JSON.stringify(parsedMessage, null, NUMBERS.TWO);
          const logContent = {
            MessageId: response?.data?.MessageId,
            Timestamp: getFormattedFullDate(
              new Date(response?.data?.Timestamp)
            ),
            Message: messageJSON,
            ApiResponse: response?.data?.ApiResponse,
          };
          setModalContent(logContent);
          setShowDetailsModal(true);
        } catch (dataError) {
          triggerErrorNotification(UIText.Data_Error);
        }
      }
      setLogsLoading(false);
    } catch (error1) {
      triggerErrorNotification(
        UIText.Server_error_Unable_to_submit_the_request
      );
      setLogsLoading(false);
    }
  };

  const showMaterialGrid = (details) => {
    const item = details.dataItem;
    const data = item?.childItems;
    return (
      <AppDataGrid
        style={{ maxHeight: "50vh", width: "40vw", borderColor: "#b8e2ea" }}
        take={10000000}
        pageable={false}
        filterable={true}
        data={data}
        columnsToShow={childColumnsToShow()}
        sortable={true}
      />
    );
  };

  const showGridDataView = () => (
    <AppDataGrid
      resetIndex={resetIndex}
      setResetIndex={setResetIndex}
      take={DEFAULT_PAGE_SIZE}
      style={{ height: "63vh", margin: "0 0.5vw 0.5vw 0.5vw" }}
      pageable={true}
      filterable={true}
      data={eomSummaryData}
      columnsToShow={colsToShow(
        handleShipToLinkAction,
        sendToGSAPAction,
        viewLogAction,
        filters.regions?.length > NUMBERS.ONE
      )}
      expandChange={(data) => {
        setEomSummaryData(data);
      }}
      sortable={true}
      ref={exportGridRef}
      expandable={true}
      detailsContainer={(detail) => showMaterialGrid(detail)}
      exportFileName={UIText.EOM_Summary}
    />
  );

  const getConfirmationMessage = () => {
    let message = UIText.Are_you_sure_you_want_to_send_the_month_end_stocks;
    if (currentSubmitItem?.totalStock) {
      message = `${message} (${currentSubmitItem?.totalStock}) to GSAP`;
    }
    if (currentSubmitItem?.globalSiteId) {
      message = `${message} for ${UIText.ShipTo} ${currentSubmitItem?.globalSiteId}`;
    }
    return message;
  };

  const showTabContent = () => (
    <div>
      {showHeaderFilters()}
      {showHeaderButtons()}
      {showGridDataView()}
      {showConfirmationPopup && (
        <ConfirmPopup
          offset={currentOffSet}
          position="top"
          isOpen={showConfirmationPopup}
          title={""}
          customStyle={{ maxWidth: "20vw" }}
          message={getConfirmationMessage()}
          saveAction={() => {
            setShowConfirmationPopup(false);
            submitGSAPRequest();
          }}
          cancelAction={(e) => {
            if (e.target.textContent !== "YES") {
              setShowConfirmationPopup(false);
            }
          }}
        />
      )}
    </div>
  );

  const showHeaderFilters = () => {
    return (
      <div className="header-container">
        <div className="filters-container">
          <span className="filter">
            <label htmlFor="year">{UIText.Year}</label>
            <DropDownList
              data={filters.years}
              textField="text"
              dataItemKey="id"
              onChange={(event) => {
                onYearSelection(event.target.value);
              }}
              value={filters.selectedYear}
              style={{
                width: "5vw",
                marginRight: "1vw",
                fontSize: "10px",
              }}
            />
          </span>
          <span className="filter">
            <label htmlFor="month">{UIText.Month}</label>
            <DropDownList
              data={filters.months}
              textField="text"
              filterable={true}
              dataItemKey="id"
              onChange={(event) => {
                onMonthSelection(event.target.value);
              }}
              value={filters.selectedMonth}
              style={{
                width: "5vw",
                marginRight: "1vw",
                fontSize: "10px",
              }}
            />
          </span>
          {filters.regions?.length > NUMBERS.ONE && (
            <span className="filter">
              <label htmlFor="region">{UIText.Region}</label>
              <MultiSelectionDropDown
                data={filters.regions}
                values={regionSelectedValues}
                onChange={onRegionSelection}
                maxSelection={NUMBERS.ONE}
                filterable={false}
                showError={showError || !filterDataLoading}
                customStyle={{
                  width: isDachCluster() ? "9vw" : "7vw",
                  minHeight: "3vh",
                }}
              />
            </span>
          )}

          <span className="filter">
            <label htmlFor="shipTo">{UIText.ShipTo}</label>
            <MultiSelectionDropDown
              data={filters.allSiteIds}
              values={shipToSelectedValues}
              onChange={onShipToSelection}
              filterable={true}
              showError={showError || !filterDataLoading}
              customStyle={{ width: "7vw", minHeight: "3vh" }}
              maxSelection={NUMBERS.ONE}
            />
          </span>
          <span className="filter">
            <label htmlFor="siteName">{UIText.Site_Name}</label>
            <MultiSelectionDropDown
              data={filters.allSiteNames}
              values={siteNameSelectedValues}
              onChange={onSiteNameSelection}
              filterable={true}
              showError={showError || !filterDataLoading}
              customStyle={{
                width: "15vw",
                minHeight: "3vh",
              }}
              maxSelection={NUMBERS.ONE}
            />
          </span>
          <span className="filter">
            <label htmlFor="obn">{UIText.OBN}</label>
            <MultiSelectionDropDown
              data={filters.allOBNs}
              values={obnSelectedValues}
              onChange={onOBNSelection}
              filterable={true}
              showError={showError || !filterDataLoading}
              customStyle={{ width: "6vw", minHeight: "3vh" }}
              maxSelection={NUMBERS.ONE}
            />
          </span>
          <span className="filter">
            <label htmlFor="materialNo">{UIText.Material_No}</label>
            <MultiSelectionDropDown
              data={filters.products}
              values={productSelectedValues}
              onChange={onProductSelection}
              filterable={false}
              placeholder={UIText.Select_dots}
              customStyle={{ width: "10vw", minHeight: "3vh" }}
            />
          </span>
          <span className="filter">
            <label htmlFor="tankGroup">{UIText.Tank_Group}</label>
            <MultiSelectionDropDown
              data={filters.tankGroup}
              values={tankSelectedValues}
              onChange={onTankSelection}
              filterable={false}
              placeholder={UIText.Select_dots}
              customStyle={{ width: "7vw", minHeight: "3vh" }}
            />
          </span>
        </div>
      </div>
    );
  };

  const handleShipToLinkAction = (siteId, country) => {
    setSelectedSite({ siteId: siteId, country: country });
    setShowSiteInfoModal(true);
  };

  const closeModal = () => {
    setShowSiteInfoModal(false);
  };

  const closeMessageModal = () => {
    setShowDetailsModal(false);
  };

  const showHeaderButtons = () => {
    return (
      <div className="buttonsContainer">
        <div>
          <span className="filter-button">
            <Button
              className="get-data-btn"
              onClick={() => {
                fetchEomSummaryData();
                setResetIndex(!resetIndex);
              }}
              disabled={filterDataLoading}
            >
              {filterDataLoading ? UIText.Fetching : UIText.Get_data}
            </Button>
          </span>
          <span className="filter-button">
            <Button
              className="refresh-btn"
              onClick={() => {
                fetchEomSummaryData(true);
              }}
              disabled={!appliedFilters}
            >
              {UIText.Refresh}
            </Button>
          </span>
        </div>
        <div>
          <span className="filter-button">
            <Button
              className="get-data-btn"
              onClick={exportData}
              disabled={exporting || !eomSummaryData?.length}
            >
              {exporting ? UIText.Exporting : UIText.Export}
            </Button>
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="eom-summary-screen-container data-grid">
      <div className="row ">
        {error && <span>{error}</span>}
        {showDetailsModal && (
          <LogDetails
            content={modalContent}
            close={closeMessageModal}
            globalSiteId={currentLogItem?.globalSiteId}
          />
        )}
        <div className="data-grid-container">
          {showTabContent()}
          {showSiteInfoModal && (
            <SiteInfoScreen
              globalSiteId={selectedSite?.siteId}
              countryCode={currentCountryCode}
              close={closeModal}
            />
          )}
          {submitLoading && <LoadingPanel message={UIText.Fetching_the_data} />}
          {sendLoading && <LoadingPanel message={UIText.Sending_to_GSAP} />}
          {filterDataLoading && (
            <LoadingPanel message={UIText.Fetching_the_config_data} />
          )}
          {exporting && <LoadingPanel message={UIText.Exporting_the_data} />}
          {logsLoading && <LoadingPanel message={UIText.Fetching_the_data} />}
        </div>
      </div>
    </div>
  );
};

export { EOMSummaryScreen };
