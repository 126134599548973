import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveLoggedInUserUUID } from "../../../state-management/actions";

import { LoginPageFooter } from "./components/footer";
import { SelectionPanel } from "./components/selectionPanel";
import { loginService } from "../../../services/login";
import { saveLoggedInUser } from "../../../state-management/actions";
import { LoadingOverlay } from "../../components/loading-overlay/loading-overlay";

import "./login.scss";
import { UIText } from "../reports/components/label-constants";
import { triggerErrorNotification } from "../reports/components/common-utils";

const LoginPage = () => {
  const [loggingIn, setLoggingInStatus] = useState(false);
  const [showError, setShowError] = useState({ status: false, message: "" });
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const promptForExternalNavigation = (e) => {
    const response = window.confirm(
      UIText.You_will_be_taken_to_different_website
    );
    !response && e.preventDefault();
  };

  const handelSelection = (e) => {
    setShowError(false);
    const isShellUser = e;
    if (isShellUser) {
      setLoggingInStatus(true);
      loginService.shellUserLogin();
    } else {
      setLoggingInStatus(true);
      loginService.nonShellUserLogin();
    }
  };

  async function handleShellUserLoginNavigation(code, codeVerifier) {
    const shellUser = await loginService.handelUserAfterPINGRedirection(
      code,
      codeVerifier
    );
    if (shellUser) {
      setLoggingInStatus(false);
      dispatch(saveLoggedInUser(shellUser));
      dispatch(saveLoggedInUserUUID(shellUser?.UniqueUserId));
      loginService.captureLoginAttempt(shellUser);
      navigate("/home");
    } else {
      setLoggingInStatus(false);
      setShowError({
        status: true,
        message: UIText.Unable_to_identify_Shell_user,
      });
      triggerErrorNotification(UIText.Unable_to_identify_Shell_user);
      localStorage.clear();
      sessionStorage.clear();
    }
  }

  async function handleNonShellUserLoginNavigation(code, codeVerifier) {
    const nonShellUser = await loginService.handelUserAfterCIPMRedirection(
      code,
      codeVerifier
    );
    if (nonShellUser) {
      setLoggingInStatus(false);
      dispatch(saveLoggedInUser(nonShellUser));
      dispatch(saveLoggedInUserUUID(nonShellUser?.UniqueUserId));
      loginService.captureLoginAttempt(nonShellUser);
      navigate("/home");
    } else {
      setLoggingInStatus(false);
      setShowError({
        status: true,
        message: UIText.Unable_to_identify_Non_Shell_user,
      });
      triggerErrorNotification(UIText.Unable_to_identify_Shell_user);
      localStorage.clear();
      sessionStorage.clear();
    }
  }
  const handelAfterRedirection = async () => {
    const code = searchParams.get("code");
    const error = searchParams.get("error");

    const loginPayload = localStorage.getItem("loginPayload")
      ? JSON.parse(localStorage.getItem("loginPayload"))
      : null;

    if (code && !error && loginPayload) {
      /**
       * This is the case when the user visits the portal after being redirected.
       * From here depending on what kind of user (shell/non-shell) tried logging in, the PKCE login flow will proceed.
       */
      setLoggingInStatus(true);
      const loginType = loginPayload?.hasOwnProperty("loginType")
        ? loginPayload.loginType
        : null;

      const codeVerifier = loginPayload?.codeVerifier
        ? loginPayload.codeVerifier
        : null;

      if (loginType === "shell") {
        await handleShellUserLoginNavigation(code, codeVerifier);
      } else if (loginType === "nonshell") {
        await handleNonShellUserLoginNavigation(code, codeVerifier);
      }
    } else if (!code && error) {
      /**
       * This is the case when the user visits the portal after getting redirected 
       but with some login error, mainly because of a failed login attempt.
       */
      setLoggingInStatus(false);
      setShowError({
        status: true,
        message: UIText.It_appears_unauthorized_application,
      });
      triggerErrorNotification(UIText.It_appears_you_are_unauthorized);
    }
  };

  // This UseEffect hook will continue the PKCE auth. after redirection
  useEffect(() => {
    handelAfterRedirection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="container-fluid login-page">
        <div className="row login-container">
          <div className="brand-container">
            <div className="brand-container-row">
              <img src={require("../../../assets/logo.png")} alt="" />
              <h1>{UIText.WSM_Analytics}</h1>
              {loggingIn && (
                <LoadingOverlay message={UIText.Please_wait_login} />
              )}
              {showError.status && (
                <p className="error-message">{showError.message}</p>
              )}
            </div>
            <div className="login-options-row">
              <SelectionPanel
                handelSelection={handelSelection}
                text={UIText.I_have_a_Shell_login_ID}
                eg="Eg: John.Smit@Shell.com"
                isShellUser={true}
              />
              <SelectionPanel
                handelSelection={handelSelection}
                text={UIText.I_don_have_a_Shell_login_ID}
                eg="Eg: John.Smit@Gmail.com"
                isShellUser={false}
              />
            </div>
          </div>
        </div>
        <LoginPageFooter
          promptForExternalNavigation={promptForExternalNavigation}
        />
      </div>
    </>
  );
};

export { LoginPage };
