import React, { useState, useCallback, useEffect } from "react";
import "./dropdown-tree.scss";
import { DropDownTree } from "@progress/kendo-react-dropdowns";
import {
  processTreeData,
  expandedState,
} from "./shared-dd-tree-data-operations.js";
import {
  De,
  Be,
  Fr,
  Lu,
  Nl,
  At,
  Ch,
  Bg,
  Si,
  Hu,
} from "@sede-x/shell-ds-react-framework/build/esm/components/Flag/components";
import { UIText } from "../../pages/reports/components/label-constants";

const selectField = "selected";
const expandField = "expanded";
const dataItemKey = "id";
const textField = "text";
const subItemsField = "items";
const iconSize = 15;
const fields = {
  selectField,
  expandField,
  dataItemKey,
  subItemsField,
};
const allIcons = {
  DE: De,
  BE: Be,
  FR: Fr,
  LU: Lu,
  NL: Nl,
  AT: At,
  CH: Ch,
  BG: Bg,
  SI: Si,
  HU: Hu,
};

const Item = ({ item }) => {
  const iconName = item.text?.toUpperCase();
  const isParent = item?.parent;
  const className = isParent ? "" : "childItem";
  const Icon = item.text ? allIcons?.[iconName] : "";
  return (
    <div className={className} disabled={isParent}>
      {Icon && <Icon height={iconSize} width={iconSize} className={"icon"} />}{" "}
      {item.text}
    </div>
  );
};

const ValueHolder = ({ item, children }) => {
  return item ? (
    <React.Fragment>
      &nbsp;
      {children}
    </React.Fragment>
  ) : (
    children
  );
};
const DropDownTreeList = (props) => {
  const { data, userPreferredCountry } = props;
  const [isOpen, setIsOpen] = useState(false);
  const getDefaultCountry = (nodes) => {
    for (const node of nodes) {
      if (node.text?.toLowerCase() === userPreferredCountry?.toLowerCase()) {
        return node;
      } else if (node.items) {
        const found = getDefaultCountry(node.items);
        if (found) {
          return found;
        }
      }
    }
    return false;
  };
  const getDefaultValue = (currentValue) => {
    let defaultVal = null;
    data?.forEach((parent) => {
      const selectedItem = parent?.items?.find(
        (item) => item.id === currentValue?.id
      );
      if (selectedItem) {
        const text = `${parent?.text} - ${selectedItem.text}`;
        defaultVal = {
          ...selectedItem,
          text,
        };
      }
    });
    return defaultVal;
  };

  useEffect(() => {
    const defaultCountry = userPreferredCountry
      ? getDefaultCountry(data)
      : data?.[0]?.items?.[0];
    const defaultValue = getDefaultValue(defaultCountry);
    setValue(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, userPreferredCountry]);

  const [value, setValue] = useState();
  const [expanded, setExpanded] = useState([data?.[0]?.[dataItemKey]]);

  const onExpandChange = useCallback(
    (event) => setExpanded(expandedState(event.item, dataItemKey, expanded)),
    [expanded]
  );

  const treeData = React.useMemo(
    () =>
      processTreeData(
        data,
        {
          expanded,
          value,
        },
        fields
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [expanded, value, data]
  );

  const handleOpen = () => {
    setIsOpen(true);
  };

  const onChange = (event) => {
    let region = null;
    let country = null;
    const currentValue = event.value;
    if (currentValue?.items) {
      setIsOpen(true);
    } else {
      data?.forEach((parent) => {
        const selectedItem = parent?.items?.find(
          (item) => item.id === currentValue.id
        );
        if (selectedItem) {
          region = parent?.text;
          currentValue.text = `${region} - ${currentValue.text}`;
          country = selectedItem.text;
        }
      });
      setValue(currentValue);
      setIsOpen(false);
      props?.onChange(region, country);
    }
  };
  return (
    <div className="dropdown-tree">
      <DropDownTree
        style={{
          width: "8vw",
        }}
        data={treeData}
        value={value}
        onChange={onChange}
        placeholder={UIText.Select_dots}
        textField={textField}
        dataItemKey={dataItemKey}
        selectField={selectField}
        expandField={expandField}
        onExpandChange={onExpandChange}
        item={Item}
        valueHolder={ValueHolder}
        onOpen={handleOpen}
        opened={isOpen}
        onBlur={() => {
          setIsOpen(false);
        }}
      />
    </div>
  );
};
export default DropDownTreeList;
