import "./pump-index-report.scss";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { reportsService } from "../../../../../services/reports";
import { store } from "../../../../../state-management/store";
import {
  PDFHeaderTemplate,
  PumpIndexViewOptions,
  colsToShow,
  fetchAndFormatPumpIndexReportResponse,
  getFetchApiRequestPayload,
  isPumpFilterValuesSelected,
} from "./pump-index-utils";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import { Button, DropDownList } from "@progress/kendo-react-all";
import { PDFExport } from "@progress/kendo-react-pdf";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import {
  getMonths,
  getMonthsData,
  getProducts,
  getShipTos,
  getSiteNames,
  getTankGroupList,
  getThresholdYears,
} from "../../reportUtils";

import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";
import AlertPopup from "../../../../components/alert-popup/alert-popup";
import { SearchableDropdown } from "../../../../components/searchable-dropdown/searchable-dropdown";
import { useNavigate } from "react-router-dom";
import { Switch } from "@progress/kendo-react-inputs";
import { TabComponent } from "../../../../components/tab-component/tab-component";
import {
  isBFXCluster,
  isDefaultDataLoadEnabled,
  NUMBERS,
  triggerErrorNotification,
  triggerSuccessNotification,
} from "../common-utils";
import { UIText } from "../label-constants";

const PumpIndexScreen = () => {
  const [pumpIndexReportData, setPumpIndexReportData] = useState([]);
  const defaultCountry = useSelector(
    (state) => state?.reports?.selectedCountry
  );
  const [currentCountryCode, setCurrentCountryCode] = useState(defaultCountry);
  const [exporting, setExporting] = useState(false);
  const pdfExportComponent = useRef(null);
  const [translatedContent, setTranslatedContent] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState(null);
  const [selectedTab, setSelectedTab] = useState(PumpIndexViewOptions.MATERIAL);
  const [cursorPosition, setCursorPosition] = useState(null);
  const exportGridRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [resetIndex, setResetIndex] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [changed, setChanged] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [changedItems, setChangedItems] = useState([]);
  const [filterDataLoading, setFilterDataLoading] = useState(false);
  const [error, setError] = useState("");
  const [masterData, setMasterData] = useState();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const divRef = useRef(null);
  const gridRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [salesDifferenceToggleOn, setSalesDifferenceToggleOn] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [filters, setFilters] = useState({
    selectedSiteId: null,
    selectedRegions: [
      {
        id: "all",
        text: UIText.All,
      },
    ],
    selectedYear: getThresholdYears(NUMBERS.ONE)?.[0],
    years: getThresholdYears(NUMBERS.TEN),
    selectedMonth: {
      id: new Date().getMonth() + NUMBERS.ONE,
      text: new Date().toLocaleString("en-US", { month: "short" }),
    },
    months: getMonths(),
    selectedSiteName: null,
    selectedProduct: null,
    products: [],
    selectedTankGroup: [],
    tankGroup: [],
    allSiteIds: [],
    allSiteNames: [],
  });
  const inputRef = useRef(null);
  const languageOptions = [
    { id: "dutch", text: UIText.Dutch },
    { id: "french", text: UIText.French },
  ];

  const [pdfLanguage, setPdfLanguage] = useState(languageOptions?.[0]);
  const navigate = useNavigate();

  store.subscribe(() => {
    const { selectedCountry } = store.getState().reports;
    setCurrentCountryCode(selectedCountry);
  });

  useEffect(() => {
    if (divRef.current.offsetWidth) {
      setWidth(divRef.current.offsetWidth);
    }

    // to handle page resize
    const getwidth = () => {
      setWidth(divRef.current.offsetWidth);
    };
    window.addEventListener("resize", getwidth);
    // remove the event listener before the component gets unmounted
    return () => window.removeEventListener("resize", getwidth);
  }, [divRef]);

  useEffect(() => {
    if (!isBFXCluster()) {
      navigate("/home");
      return;
    }
    setFirstLoad(true);
    fetchMasterData();
    setChanged(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountryCode]);

  useEffect(() => {
    setChanged(false);
    setChangedItems([]);
    const updatedItems = originalData?.filter((item) => {
      if (salesDifferenceToggleOn) {
        return item?.salesDifference !== 0;
      }
      return true;
    });
    setPumpIndexReportData(updatedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesDifferenceToggleOn]);

  useEffect(() => {
    cancelChanges();
    fetchPumpIndexData();
    setChanged(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    const content = require("./translation.json");
    setTranslatedContent(content?.[pdfLanguage.id]);
  }, [pdfLanguage]);

  const getTranslatedContent = (keyItem) => {
    const key = keyItem.trim();
    return translatedContent?.[key] ? translatedContent?.[key] : key;
  };

  useEffect(() => {
    inputRef?.current?.focus();
    setTimeout(() => {
      inputRef?.current?.element?.setSelectionRange(
        cursorPosition,
        cursorPosition
      );
    }, NUMBERS.TEN);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pumpIndexReportData]);

  const showHideLoaders = (show = false) => {
    setExporting(show);
  };

  const exportData = async () => {
    showHideLoaders(true);
    await cancelChanges();
    exportGridRef?.current?.exportDataInExcel &&
      (await exportGridRef.current.exportDataInExcel());

    setTimeout(showHideLoaders, NUMBERS.THOUSAND);
  };

  const exportPDFData = () => {
    setExporting(true);
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
    setExporting(false);
  };

  const createPayload = () => {
    const Comments = changedItems?.map((item) => ({
      MaterialRowKey: item.materialRowKey,
      Text: item.comments,
    }));
    const payload = {
      Year: filters.selectedYear?.text,
      Month: filters.selectedMonth?.id,
      GlobalSiteId: filters?.selectedSiteId?.text,
      MaterialCode: filters?.selectedProduct?.id,
      Comments,
    };

    return payload;
  };

  const onYearSelection = (event) => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedYear: event,
      };
    });
  };

  const onMonthSelection = (event) => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedMonth: event,
      };
    });
  };

  const onShipToSelection = (value) => {
    const siteId = value?.split(" | ")?.[0];
    if (siteId?.length) {
      const selectedSiteId = filters?.allSiteIds?.find(
        (item) => item.text === siteId
      );
      setFilters((prev) => {
        return {
          ...prev,
          selectedSiteId: selectedSiteId,
        };
      });
    }
  };

  const onSiteNameSelection = (value) => {
    const selectedSiteName = filters?.allSiteNames?.find(
      (item) => item.text === value
    );
    setFilters((prev) => {
      return {
        ...prev,
        selectedSiteName: selectedSiteName,
      };
    });
  };

  const onProductSelection = (event) => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedProduct: event,
      };
    });
  };
  const onTankSelection = (event) => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedTankGroup: event,
      };
    });
  };

  useEffect(() => {
    const siteIds =
      filters.selectedRegions?.length > 0
        ? getShipTos(masterData, filters.selectedRegions, false)
        : [];

    const siteNames =
      filters.selectedRegions?.length > 0
        ? getSiteNames(masterData, filters.selectedRegions, false)
        : [];

    setFilters((prev) => {
      return {
        ...prev,
        selectedSiteId: siteIds?.[0],
        selectedSiteName: siteNames?.[0],
        allSiteIds: siteIds,
        allSiteNames: siteNames,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterData]);

  useEffect(() => {
    const year = parseInt(filters?.selectedYear?.text);
    const months = getMonthsData(year, filters);
    const currentYear = new Date().getFullYear();

    let selectedMonth = filters.selectedMonth;
    const currentMonth = new Date().getMonth();
    if (year === currentYear && selectedMonth.id > currentMonth + NUMBERS.ONE) {
      selectedMonth = {
        id: new Date().getMonth() + NUMBERS.ONE,
        text: new Date().toLocaleString("en-US", { month: "short" }),
      };
    }

    setFilters((prev) => {
      return {
        ...prev,
        months: months,
        selectedMonth: selectedMonth,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedYear]);

  useEffect(() => {
    let selectedSiteName = null;
    if (filters?.selectedSiteId) {
      const site = filters?.allSiteNames?.find(
        (siteInfo) => siteInfo?.globalSiteId === filters?.selectedSiteId?.id
      );
      if (site) {
        selectedSiteName = site;
      }
    }

    const productsData = getProducts(masterData, filters, false);
    const selectedProduct = productsData?.[0];

    setFilters((prev) => {
      return {
        ...prev,
        products: productsData,
        selectedProduct: selectedProduct,
        selectedSiteName: selectedSiteName,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.selectedSiteId]);

  useEffect(() => {
    let selectedSiteId = null;
    if (filters?.selectedSiteName) {
      const site = filters?.allSiteIds?.find(
        (siteInfo) =>
          siteInfo?.globalSiteId === filters?.selectedSiteName?.globalSiteId
      );
      if (site) {
        selectedSiteId = site;
      }
    }

    const productsData = getProducts(masterData, filters, false);
    const selectedProduct = productsData?.[0];

    setFilters((prev) => {
      return {
        ...prev,
        products: productsData,
        selectedProduct: selectedProduct,
        selectedSiteId: selectedSiteId,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.selectedSiteName]);

  useEffect(() => {
    const tankGroupsData = getTankGroupList(masterData, filters, false);
    const selectedTanks = tankGroupsData?.[0];
    setFilters((prev) => {
      return {
        ...prev,
        tankGroup: tankGroupsData,
        selectedTankGroup: selectedTanks,
      };
    });

    if (filters.selectedProduct && firstLoad && isDefaultDataLoadEnabled()) {
      fetchPumpIndexData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedProduct]);

  const fetchMasterData = async () => {
    try {
      setPumpIndexReportData([]);
      setFilterDataLoading(true);
      const response = await reportsService.fetchToleranceTrendMasterData({
        country: currentCountryCode,
      });
      setFilterDataLoading(false);
      if (response) {
        setMasterData(response);
      } else {
        triggerErrorNotification(UIText.Unable_to_fetch_the_data);
      }
    } catch (error1) {
      triggerErrorNotification(
        UIText.Server_error_Unable_to_submit_the_request
      );
      setError(UIText.Server_error_Unable_to_submit_the_request);
      setFilterDataLoading(false);
      setError("");
    }
  };

  const handleDataChange = (props) => {
    const { dataItem, field, position } = props;
    setSelectedItem({ ...dataItem, editField: field });

    const changeItem = props?.dataItem;
    const updatedItems = pumpIndexReportData?.map((item) => {
      if (
        item.dateOfPumpIndex === dataItem.dateOfPumpIndex &&
        item.dateOfFuel === dataItem.dateOfFuel &&
        item.outBound === dataItem.outBound
      ) {
        return dataItem;
      }
      return item;
    });
    setPumpIndexReportData(updatedItems);

    const actualItem = originalData?.find(
      (item) =>
        item.dateOfPumpIndex === changeItem.dateOfPumpIndex &&
        item.dateOfFuel === changeItem.dateOfFuel &&
        item.outBound === changeItem.outBound
    );
    const isChanged = actualItem.comments !== changeItem.comments;

    setChanged(isChanged);
    if (isChanged) {
      const sameItem = changedItems?.find(
        (item) =>
          item.dateOfPumpIndex === changeItem.dateOfPumpIndex &&
          item.dateOfFuel === changeItem.dateOfFuel &&
          item.outBound === changeItem.outBound
      );
      if (sameItem) {
        const items = changedItems?.map((item) => {
          if (
            item.dateOfPumpIndex === changeItem.dateOfPumpIndex &&
            item.dateOfFuel === changeItem.dateOfFuel &&
            item.outBound === changeItem.outBound
          ) {
            return changeItem;
          }
          return item;
        });
        setChangedItems(items);
      } else {
        setChangedItems([...changedItems, changeItem]);
      }
    } else {
      setChangedItems(
        changedItems?.filter(
          !(
            actualItem.dateOfPumpIndex === changeItem.dateOfPumpIndex &&
            actualItem.dateOfFuel === changeItem.dateOfFuel &&
            actualItem.outBound === changeItem.outBound
          )
        )
      );
    }
    setCursorPosition(position);
  };

  const saveChanges = async () => {
    setSubmitting(true);
    const payload = createPayload();
    const response = await reportsService.savePumpIndexValues(payload);
    if (
      response?.status >= NUMBERS.TWO_HUNDRED &&
      response?.status < NUMBERS.THREE_HUNDRED
    ) {
      setChanged(false);
      setChangedItems([]);
      fetchPumpIndexData();
      triggerSuccessNotification(
        response?.data || UIText.Comments_updated_successfully
      );
    } else {
      triggerErrorNotification(UIText.Unable_to_submit_the_comments);
    }
    setSubmitting(false);
  };

  const cancelChanges = async () => {
    setPumpIndexReportData(originalData);
    setChanged(false);
    setChangedItems([]);
  };

  const showHeaderFilters = () => {
    const siteIds = filters.allSiteIds?.map(
      (site) => `${site?.globalSiteId} | ${site?.localSiteId}`
    );
    const siteNames = filters.allSiteNames?.map((site) => site?.siteName);
    return (
      <div className="header-container">
        <div className="filters-container">
          <span className="filter">
            <label htmlFor="year">{UIText.Year}</label>
            <DropDownList
              data={filters.years}
              textField="text"
              dataItemKey="id"
              onChange={(event) => {
                onYearSelection(event.target.value);
              }}
              value={filters.selectedYear}
              style={{
                width: "7vw",
                marginRight: "1vw",
                fontSize: "10px",
              }}
            />
          </span>
          <span className="filter">
            <label htmlFor="month">{UIText.Month}</label>
            <DropDownList
              data={filters.months}
              textField="text"
              filterable={true}
              dataItemKey="id"
              onChange={(event) => {
                onMonthSelection(event.target.value);
              }}
              value={filters.selectedMonth}
              style={{
                width: "7vw",
                marginRight: "1vw",
                fontSize: "10px",
              }}
            />
          </span>

          <span className="filter">
            <label htmlFor="shipTo">{UIText.Site_Id}</label>
            <SearchableDropdown
              data={siteIds}
              filterable={true}
              textField="text"
              dataItemKey="id"
              onSelect={(event) => {
                onShipToSelection(event.value.text);
              }}
              value={{
                text: filters?.selectedSiteId
                  ? `${filters?.selectedSiteId?.text} | ${filters?.selectedSiteId?.localSiteId}`
                  : "",
              }}
              style={{
                minWidth: "7vw",
                marginRight: "1vw",
                fontSize: "10px",
              }}
            />
          </span>
          <span className="filter">
            <label htmlFor="shipTo">{UIText.Site_Name}</label>
            <SearchableDropdown
              data={siteNames}
              textField="text"
              dataItemKey="id"
              filterable={true}
              onSelect={(event) => {
                onSiteNameSelection(event.value.text);
              }}
              value={{ text: filters?.selectedSiteName?.text }}
              style={{
                minWidth: "8vw",
                marginRight: "1vw",
                fontSize: "10px",
              }}
            />
          </span>
          <span className="filter">
            <label htmlFor="region">{UIText.Material_No}</label>
            <DropDownList
              data={filters.products}
              textField="idText"
              dataItemKey="id"
              onChange={(event) => {
                onProductSelection(event.target.value);
              }}
              value={filters.selectedProduct}
              style={{
                minWidth: "7vw",
                marginRight: "1vw",
                fontSize: "10px",
              }}
            />
          </span>
          {selectedTab === PumpIndexViewOptions.TANK && (
            <span className="filter">
              <label htmlFor="region">{UIText.Tank_Group}</label>
              <DropDownList
                data={filters.tankGroup}
                textField="text"
                dataItemKey="id"
                onChange={(event) => {
                  onTankSelection(event.target.value);
                }}
                value={filters.selectedTankGroup}
                style={{
                  minWidth: "7vw",
                  marginRight: "1vw",
                  fontSize: "10px",
                }}
              />
            </span>
          )}
        </div>
        <span className="filter-button">
          <Button
            className="get-data-btn"
            onClick={() => {
              setSalesDifferenceToggleOn(false);
              fetchPumpIndexData();
              setResetIndex(!resetIndex);
            }}
            disabled={filterDataLoading}
          >
            {filterDataLoading ? UIText.Fetching : UIText.Get_data}
          </Button>
        </span>
        <span className="filter-button">
          <Button
            className="refresh-btn"
            onClick={() => {
              fetchPumpIndexData(true);
            }}
            disabled={!appliedFilters}
          >
            {UIText.Refresh}
          </Button>
        </span>
      </div>
    );
  };

  const showHeaderButtons = () => {
    const saveColor = changed && "#0092a5";
    const cancelColor = changed && "#fbce07";
    const borderWidth = changed && "0.15vw";
    return (
      <div className="buttonsContainer">
        {selectedTab === PumpIndexViewOptions.TANK && (
          <div className="languageContainer">
            <DropDownList
              data={languageOptions}
              textField="text"
              dataItemKey="id"
              onChange={(event) => {
                setPdfLanguage(event.target.value);
              }}
              value={pdfLanguage}
              style={{
                width: "7vw",
                marginRight: "1vw",
                fontSize: "10px",
              }}
              disabled={exporting || pumpIndexReportData?.length === 0}
            />
            <Button
              className="exportButton"
              onClick={exportPDFData}
              disabled={exporting || pumpIndexReportData?.length === 0}
            >
              {exporting ? UIText.Exporting : UIText.Export_to_PDF}
            </Button>
          </div>
        )}
        {selectedTab === PumpIndexViewOptions.MATERIAL && (
          <div className="submitButtonContainer">
            <Button
              className="exportButton"
              onClick={exportData}
              disabled={exporting || !pumpIndexReportData?.length}
            >
              {exporting ? UIText.Exporting : UIText.Export}
            </Button>
          </div>
        )}
        {selectedTab === PumpIndexViewOptions.MATERIAL && (
          <div className="submitButtonContainer">
            <div className="switchContainer">
              <span className="switch-label">
                {UIText.Show_only_sales_differences}
              </span>
              <Switch
                onLabel={""}
                offLabel={""}
                size="small"
                checked={salesDifferenceToggleOn}
                onChange={() => {
                  setSalesDifferenceToggleOn(!salesDifferenceToggleOn);
                }}
              />
            </div>
            <button
              title={UIText.Save}
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base toolbar-button"
              onClick={saveChanges}
              style={{ borderColor: saveColor, borderWidth: borderWidth }}
              disabled={!changed}
            >
              {UIText.Save}
            </button>
            <button
              title={UIText.Cancel}
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base toolbar-button"
              onClick={cancelChanges}
              style={{ borderColor: cancelColor, borderWidth: borderWidth }}
              disabled={!changed}
            >
              {UIText.Cancel}
            </button>
          </div>
        )}
      </div>
    );
  };

  const renderPDFContent = () => {
    const colsToExport = colsToShow(pumpIndexReportData, selectedTab);
    return (
      <div className="pdfContainer">
        <PDFExport
          ref={pdfExportComponent}
          pageTemplate={PDFHeaderTemplate}
          scale={0.6}
          paperSize="A2"
          margin={40}
          repeatHeaders={true}
          fileName={UIText.Sample_PDF_Report}
        >
          <div>
            <div className="pdfHeader">
              <div style={{ textAlign: "center" }}>
                <div>{getTranslatedContent("Site company")}</div>
                <div>{getTranslatedContent("WSMA -Pump index Register")}</div>
                <div>
                  {getTranslatedContent(
                    "Material id, Material name name in french and Dutch"
                  )}
                </div>
                <div>{getTranslatedContent("Tank group number")}</div>
              </div>
            </div>
            <Grid data={pumpIndexReportData}>
              {colsToExport.map((col) => (
                <GridColumn
                  key={`${col.field}-${col.title}`}
                  field={col.field}
                  title={getTranslatedContent(col.title)}
                >
                  {col?.columns?.map((childCol) => (
                    <GridColumn
                      key={`${childCol.field}-${childCol.title}`}
                      field={childCol.field}
                      title={getTranslatedContent(childCol.title)}
                    />
                  ))}
                </GridColumn>
              ))}
            </Grid>
          </div>
        </PDFExport>
      </div>
    );
  };

  const showGridDataView = () => (
    <div ref={divRef} className={gridClassName}>
      <AppDataGrid
        hasLockedRow={true}
        gridRef={gridRef}
        take={1000000000}
        style={{ height: "58vh", margin: "0 0.5vw 0.5vw 0.5vw" }}
        pageable={false}
        filterable={true}
        data={pumpIndexReportData}
        columnsToShow={columns}
        onItemChange={handleDataChange}
        sortable={true}
        cellRender={(td, props) => {
          return cellRender(td, props, columns);
        }}
        ref={exportGridRef}
        exportFileName={UIText.Pump_Register_Report}
      />
    </div>
  );

  const showTabContent = () => (
    <div>
      {showHeaderFilters()}
      {showHeaderButtons()}
      {showGridDataView()}
    </div>
  );

  const showTabComponent = () => {
    const items = [
      {
        label: UIText.Material_View,
        key: PumpIndexViewOptions.MATERIAL,
      },
      {
        label: UIText.Tank_Group_View,
        key: PumpIndexViewOptions.TANK,
      },
    ];
    return (
      <TabComponent
        items={items}
        activeIndex={selectedTab}
        onChange={(index) => {
          if (changed) {
            setIsPopupOpen(true);
          } else {
            setSelectedTab(index);
          }
        }}
      />
    );
  };

  const getFilter = (refresh) => {
    return refresh ? appliedFilters : filters;
  };

  const fetchPumpIndexData = async (refresh = false) => {
    const filter = getFilter(refresh);
    const month = filter.selectedMonth;
    const year = filter.selectedYear;
    const valuesSelected = isPumpFilterValuesSelected(
      month,
      year,
      filter,
      currentCountryCode,
      selectedTab
    );

    if (valuesSelected) {
      const payload = getFetchApiRequestPayload(
        month,
        year,
        filter,
        selectedTab
      );
      setSubmitLoading(true);
      setFirstLoad(false);
      if (!refresh) {
        setAppliedFilters(filter);
      }
      try {
        setChangedItems([]);
        setChanged(false);
        const response = await fetchAndFormatPumpIndexReportResponse(
          payload,
          selectedTab,
          reportsService
        );

        setPumpIndexReportData(response);
        setOriginalData(response);
        setSubmitLoading(false);
        if (gridRef.current) {
          gridRef.current.scrollIntoView({
            rowIndex: NUMBERS.ZERO,
          });
        }
      } catch (error1) {
        setPumpIndexReportData([]);
        setOriginalData([]);
        setSubmitLoading(false);
        triggerErrorNotification(
          UIText.Server_error_Unable_to_submit_the_request
        );
        setError(UIText.Server_error_Unable_to_submit_the_request);
        setSubmitLoading(false);
        setError("");
      }
    }
  };

  const isColumnLocked = (colsToExport, field) => {
    let columnData = colsToExport?.find((col) => col?.field === field);
    if (!columnData) {
      colsToExport?.forEach((item) => {
        const data = item?.columns?.find((column) => column?.field === field);
        if (data) {
          columnData = data;
        }
      });
    }
    return columnData?.locked;
  };

  const cellRender = (td, props, colsToExport) => {
    const isLocked = isColumnLocked(colsToExport, props?.field);
    const extraProps = {};
    const styles = isLocked ? props.style : {};
    if (props?.dataItem?.locked) {
      extraProps.style = {
        ...styles,
        bottom: NUMBERS.ZERO,
      };
      extraProps.className = props.className + " k-grid-row-sticky";
    }
    return React.cloneElement(
      td,
      {
        ...td.props,
        ...extraProps,
      },
      td.props.children
    );
  };

  const columns = colsToShow(
    pumpIndexReportData,
    selectedTab,
    inputRef,
    handleDataChange,
    selectedItem,
    width
  );

  const gridClassName =
    selectedTab === PumpIndexViewOptions.MATERIAL
      ? "material-grid"
      : "tank-grid";
  return (
    <div className="pump-index-screen-container data-grid">
      <div className="row ">
        {showTabComponent()}
        {error && <span>{error}</span>}
        <div className="data-grid-container">
          {showTabContent()}
          {isPopupOpen && (
            <AlertPopup
              isOpen={isPopupOpen}
              setIsPopupOpen={setIsPopupOpen}
              title={UIText.Information}
              message={UIText.You_have_unsaved_comments}
            />
          )}
          {renderPDFContent()}
          {submitLoading && <LoadingPanel message={UIText.Fetching_the_data} />}
          {filterDataLoading && (
            <LoadingPanel message={UIText.Fetching_the_config_data} />
          )}
          {submitting && (
            <LoadingPanel message={UIText.Submitting_the_values} />
          )}
        </div>
      </div>
    </div>
  );
};

export { PumpIndexScreen };
