import "./tank-threshold.scss";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { reportsService } from "../../../../../services/reports";
import { Button, Input } from "@progress/kendo-react-all";
import { SearchableDropdown } from "../../../../components/searchable-dropdown/searchable-dropdown";
import { store } from "../../../../../state-management/store";
import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";
import {
  getMonthIndex,
  getTankGroups,
  thresholdDaySort,
  thresholdMonthSort,
  thresholdYearSort,
} from "./tank-threshold-utils";
import { readOnlyUser } from "../../../../../services/utils";
import {
  getMonths,
  getFilterRegions,
  getShipTos,
  getSiteNames,
  getThresholdYears,
} from "../../reportUtils";
import {
  MultiSelectionDropDown,
} from "../../../../components/multi-select-dropdown/multi-select-dropdown";
import {
  getSelectedCluster,
  isDachCluster,
  NUMBERS,
  triggerErrorNotification,
  triggerSuccessNotification,
} from "../common-utils";
import { UIText } from "../label-constants";
import { onFilterSelectionChange } from "../common-report-filters";

const TankThresholdScreen = () => {
  const [readOnly, setReadOnly] = useState(false);
  useEffect(() => {
    setReadOnly(readOnlyUser());
  }, []);
  const allOption = {
    id: UIText.All,
    text: UIText.All,
    group: true,
    items: [],
  };

  const FIRST_COLUMN_HEADER = "1";
  const SECOND_COLUMN_HEADER = "11";
  const THIRD_COLUMN_HEADER = "21";
  const defaultCountry = useSelector(
    (state) => state?.reports?.selectedCountry
  );
  const [currentCountryCode, setCurrentCountryCode] = useState(defaultCountry);

  const defaultRegionSelection = {
    id: "all",
    text: UIText.All,
  };

  const [filters, setFilters] = useState({
    selectedSiteId: null,
    selectedSiteName: null,
    selectedProducts: [],
    products: [],
    selectedTanks: [],
    tankGroup: [],
    selectedYears: getThresholdYears(NUMBERS.ONE),
    years: getThresholdYears(NUMBERS.TWO),
    selectedMonths: [
      {
        id: new Date().getMonth() + NUMBERS.ONE,
        text: new Date().toLocaleString("en-US", { month: "short" }),
      },
    ],
    months: getMonths(),
    regions: [],
    selectedRegion: [defaultRegionSelection],
    allSiteIds: [],
    allSiteNames: [],
  });

  const [masterData, setMasterData] = useState([]);
  const [thresholdData, setThresholdData] = useState([]);
  const [currentThreshold, setCurrentThreshold] = useState();
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [editEnabled, setEditEnabled] = useState(false);

  store.subscribe(() => {
    const { selectedCountry } = store.getState().reports;
    setCurrentCountryCode(selectedCountry);
  });

  const [productSelectedValues, setProductSelectedValues] = useState(
    filters?.selectedProducts
  );
  const [tankSelectedValues, setTankSelectedValues] = useState(
    filters?.selectedTanks
  );
  const [yearSelectedValues, setYearSelectedValues] = useState(
    filters?.selectedYears
  );
  const [monthSelectedValues, setMonthSelectedValues] = useState(
    filters?.selectedMonths
  );
  const [regionSelectedValues, setRegionSelectedValues] = useState(
    filters?.selectedRegion
  );

  useEffect(() => {
    setThresholdData([]);
    fetchMasterData();
    setFilters((prev) => {
      return {
        ...prev,
        selectedRegion: [defaultRegionSelection],
      };
    });
    setRegionSelectedValues([defaultRegionSelection]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountryCode]);

  useEffect(() => {
    const regions = getFilterRegions(masterData, true, currentCountryCode);
    const siteIds =
      filters.selectedRegion?.length > 0
        ? getShipTos(masterData, filters.selectedRegion, false)
        : [];

    const siteNames =
      filters.selectedRegion?.length > 0
        ? getSiteNames(masterData, filters.selectedRegion, false)
        : [];

    setFilters((prev) => {
      return {
        ...prev,
        regions,
        selectedSiteId: siteIds?.[0]?.globalSiteId,
        selectedSiteName: siteNames?.[0]?.siteName,
        allSiteIds: siteIds,
        allSiteNames: siteNames,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterData]);

  useEffect(() => {
    const siteIds =
      filters.selectedRegion?.length > 0
        ? getShipTos(masterData, filters.selectedRegion, false)
        : [];

    const siteNames =
      filters.selectedRegion?.length > 0
        ? getSiteNames(masterData, filters.selectedRegion, false)
        : [];

    setFilters((prev) => {
      return {
        ...prev,
        selectedSiteId: siteIds?.[0]?.globalSiteId,
        selectedSiteName: siteNames?.[0]?.siteName,
        allSiteIds: siteIds,
        allSiteNames: siteNames,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedRegion]);

  useEffect(() => {
    const siteData = filters?.allSiteIds?.find(
      (site) => site?.globalSiteId === filters?.selectedSiteId
    );
    const productsData = siteData?.materials?.map((product) => ({
      id: product?.materialNumber,
      text: product?.productName,
      ...product,
    }));
    setFilters((prev) => {
      return { ...prev, products: productsData };
    });
    setProductSelectedValues([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.selectedSiteId]);

  useEffect(() => {
    setFilters((prev) => {
      return { ...prev, selectedRegion: regionSelectedValues };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionSelectedValues]);

  useEffect(() => {
    setFilters((prev) => {
      return { ...prev, selectedMonths: monthSelectedValues };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthSelectedValues]);

  useEffect(() => {
    setFilters((prev) => {
      return { ...prev, selectedYears: yearSelectedValues };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearSelectedValues]);

  useEffect(() => {
    let tankGroups = getTankGroups(masterData, filters, productSelectedValues);
    if (productSelectedValues?.length > 0 && tankGroups?.length === 0) {
      tankGroups = [allOption];
    }
    setFilters((prev) => {
      return {
        ...prev,
        selectedProducts: productSelectedValues,
        tankGroup: tankGroups,
      };
    });
    if (tankGroups?.length > 0) {
      const tankGroup = tankGroups[0];
      if (tankGroup?.items?.length > 0) {
        setTankSelectedValues([tankGroup, ...tankGroup.items]);
      } else {
        setTankSelectedValues([tankGroup]);
      }
    } else {
      setTankSelectedValues([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSelectedValues]);

  useEffect(() => {
    setFilters((prev) => {
      return { ...prev, selectedTanks: tankSelectedValues };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tankSelectedValues]);

  const onSiteIdSelection = (e) => {
    const siteId = e?.value?.text;
    const siteData = filters?.allSiteIds?.find((site) => site?.id === siteId);

    setFilters((prev) => {
      return {
        ...prev,
        selectedSiteId: siteId,
        selectedSiteName: siteData?.siteName,
      };
    });
  };

  const onSiteNameSelection = (e) => {
    const siteName = e?.value?.text;
    const siteData = filters?.allSiteIds?.find(
      (site) => site?.siteName === siteName
    );
    setFilters((prev) => {
      return {
        ...prev,
        selectedSiteId: siteData?.globalSiteId,
        selectedSiteName: siteName,
      };
    });
  };

  const onProductSelection = (event) => {
    onFilterSelectionChange(
      event,
      setProductSelectedValues,
      filters?.products,
      true
    );
  };

  const onTankSelection = (event) => {
    onFilterSelectionChange(
      event,
      setTankSelectedValues,
      filters?.tankGroup,
      true
    );
  };

  const onYearSelection = (event) => {
    onFilterSelectionChange(
      event,
      setYearSelectedValues,
      filters?.years,
      true
    );
  };

  const onMonthSelection = (event) => {
    onFilterSelectionChange(
      event,
      setMonthSelectedValues,
      filters?.months,
      true
    );
  };

  const onRegionSelection = (event) => {
    onFilterSelectionChange(
      event,
      setRegionSelectedValues,
      filters?.regions,
      true
    );
  };

  const editThresholdAction = () => {
    setEditEnabled(true);
  };
  const saveThresholdAction = () => {
    setEditEnabled(false);
    const selectedTankIds = filters?.selectedTanks
      ?.filter((tank) => !tank?.group)
      ?.map((item) => item?.text);
    updateThresholdRequest({
      ...thresholdData?.[thresholdData?.length - NUMBERS.ONE],
      threshold: currentThreshold?.threshold,
      region: getSelectedCluster(),
      tankIds: selectedTankIds,
    });
  };

  const cancelThresholdAction = () => {
    setEditEnabled(false);
    setCurrentThreshold(thresholdData?.[thresholdData?.length - NUMBERS.ONE]);
  };

  const fetchMasterData = async () => {
    try {
      setLoading(true);
      const response = await reportsService.fetchToleranceTrendMasterData(
        {
          country: currentCountryCode,
        },
        true
      );
      setLoading(false);
      if (response) {
        setMasterData(response);
      } else {
        triggerErrorNotification(UIText.Unable_to_submit_the_request);
      }
    } catch (error1) {
      triggerErrorNotification(
        UIText.Server_error_Unable_to_submit_the_request
      );
      setError(UIText.Server_error_Unable_to_submit_the_request);
      setLoading(false);
      setError("");
    }
  };

  const onHandleThresholdResponse = (response) => {
    if (response) {
      const responseData = response?.map((record) => ({
        ...record,
        monthIndex: getMonthIndex(record?.month),
      }));
      responseData?.sort(thresholdDaySort);
      responseData?.sort(thresholdMonthSort);
      responseData?.sort(thresholdYearSort);
      if (responseData?.length > 0) {
        setCurrentThreshold(responseData?.[responseData?.length - NUMBERS.ONE]);
      }
      setThresholdData(responseData);
    } else {
      triggerErrorNotification(UIText.Unable_to_fetch_the_data);
    }
  };

  const fetchThresholdData = async () => {
    const siteId = filters?.allSiteIds?.find(
      (site) => site?.globalSiteId === filters?.selectedSiteId
    )?.localSiteId;
    const selectedTankIds = filters?.selectedTanks
      ?.filter((tank) => tank?.group)
      ?.map((item) => item?.id);
    const selectedMonths = filters?.selectedMonths?.map((month) => month?.id);
    const selectedYears = filters?.selectedYears?.map((year) => year?.text);
    const selectedMaterials = filters?.selectedProducts?.map(
      (product) => product?.materialNumber
    );

    let valid =
      siteId &&
      selectedMonths?.length &&
      selectedYears?.length &&
      selectedMaterials?.length;

    if (valid && isDachCluster()) {
      valid = valid && selectedTankIds?.length;
    }

    if (valid) {
      setShowError(false);
      setLoading(true);
      try {
        let payload = {
          region: getSelectedCluster(),
          globalSiteId: filters?.selectedSiteId,
          siteId: siteId,
          selectedMonths: selectedMonths,
          selectedYears: selectedYears,
          selectedMaterials: selectedMaterials,
        };
        if (isDachCluster()) {
          payload = {
            ...payload,
            selectedTankIds: selectedTankIds,
          };
        }
        const response = await reportsService.fetchThresholdData(payload);
        setLoading(false);
        onHandleThresholdResponse(response);
      } catch (error1) {
        triggerErrorNotification(
          UIText.Server_error_Unable_to_submit_the_request
        );
        setError(UIText.Server_error_Unable_to_submit_the_request);
        setLoading(false);
        setError("");
      }
    } else {
      setShowError(true);
    }
  };

  const updateThresholdRequest = async (updatedData) => {
    setSubmitLoading(true);
    try {
      const response = await reportsService.updateThreshold(updatedData);
      setSubmitLoading(false);
      if (
        response.status >= NUMBERS.TWO_HUNDRED &&
        response.status < NUMBERS.THREE_HUNDRED
      ) {
        fetchThresholdData();
        triggerSuccessNotification(
          response?.data?.returnMessage || response?.data
        );
      } else {
        triggerErrorNotification(
          response?.data?.returnMessage || UIText.Unable_to_submit_the_request
        );
      }
    } catch (error1) {
      triggerErrorNotification(
        UIText.Server_error_Unable_to_submit_the_request
      );
      setSubmitLoading(false);
      setError(UIText.Server_error_Unable_to_submit_the_request);
      setError("");
    }
  };

  const showThresholdEntries = () => {
    const thresholdEntries = Object.groupBy(
      thresholdData,
      (item) => item?.month
    );
    const monthsKeys = Object.keys(thresholdEntries);
    if (thresholdData?.length > 0) {
      return (
        <div className="threshold-table-container">
          <span className="threshold-header-label">
            {UIText.Threshold_values}
          </span>
          <table className="report-table">
            <thead className="table-header">
              <tr className="header-row">
                <td style={{ width: "8vw", fontSize: "1vw" }}>
                  {UIText.Period}
                </td>
                <td style={{ width: "8vw", fontSize: "1vw" }}>1st</td>
                <td style={{ width: "8vw", fontSize: "1vw" }}>11th</td>
                <td style={{ width: "8vw", fontSize: "1vw" }}>21st</td>
              </tr>
            </thead>
            <tbody className="table-body">
              {monthsKeys.map((month) => {
                const monthData = thresholdEntries?.[month];
                const firstColumnData = monthData?.find(
                  (data) => data?.day === FIRST_COLUMN_HEADER
                );
                const secondColumnData = monthData?.find(
                  (data) => data?.day === SECOND_COLUMN_HEADER
                );
                const thirdColumnData = monthData?.find(
                  (data) => data?.day === THIRD_COLUMN_HEADER
                );
                const year =
                  firstColumnData?.year ||
                  secondColumnData?.year ||
                  thirdColumnData?.year;
                return (
                  <tr className="report-row" key={`${month}-${year}`}>
                    <td style={{ fontSize: "0.9vw", fontWeight: "600" }}>
                      <span>{`${month} ${year}`}</span>
                    </td>
                    <td style={{ fontSize: "0.9vw" }}>
                      {firstColumnData?.threshold
                        ? firstColumnData?.threshold
                        : ""}
                    </td>
                    <td style={{ fontSize: "0.9vw" }}>
                      {secondColumnData?.threshold
                        ? secondColumnData?.threshold
                        : ""}
                    </td>
                    <td style={{ fontSize: "0.9vw" }}>
                      {thirdColumnData?.threshold
                        ? thirdColumnData?.threshold
                        : ""}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    }
    return (
      <div className="no-data-container">
        <h6 className="no-data-label">{UIText.No_Threshold_Entries}</h6>
      </div>
    );
  };

  const showCurrentThreshold = () => {
    return (
      <div className="edit-threshold-container">
        <span className="threshold-label">
          {editEnabled ? "Update Threshold" : "Current Threshold"}
        </span>
        <Input
          className="threshold-input"
          maxLength={8}
          disabled={!editEnabled}
          value={currentThreshold?.threshold}
          onChange={(e) => {
            setCurrentThreshold({
              ...currentThreshold,
              threshold: e?.target?.value,
            });
          }}
        />
        {!readOnly && (
          <Button
            className="edit-button"
            onClick={() => {
              editEnabled ? saveThresholdAction() : editThresholdAction();
            }}
          >
            {editEnabled ? UIText.Save : UIText.Edit}
          </Button>
        )}
        {editEnabled && (
          <Button className="cancel-button" onClick={cancelThresholdAction}>
            {UIText.Cancel}
          </Button>
        )}
      </div>
    );
  };

  const showHeaders = () => {
    const siteIds = filters.allSiteIds?.map((site) => site?.globalSiteId);
    const siteNames = filters.allSiteNames?.map((site) => site?.siteName);
    return (
      <div className="tank-threshold-container">
        <span className="header-container">
          <span className="filters-container">
            <span className="filter">
              <label htmlFor="year">{UIText.Year}</label>
              <MultiSelectionDropDown
                data={filters.years}
                values={yearSelectedValues}
                onChange={onYearSelection}
                filterable={false}
                showError={showError}
                customStyle={{ width: "7vw", minHeight: "3vh" }}
              />
            </span>
            <span className="filter">
              <label htmlFor="month">{UIText.Month}</label>
              <MultiSelectionDropDown
                data={filters.months}
                values={monthSelectedValues}
                onChange={onMonthSelection}
                filterable={true}
                showError={showError}
                customStyle={{ width: "7vw", minHeight: "3vh" }}
              />
            </span>
            {filters.regions?.length > NUMBERS.ONE && (
              <span className="filter">
                <label htmlFor="region">{UIText.Region}</label>
                <MultiSelectionDropDown
                  data={filters.regions}
                  values={regionSelectedValues}
                  onChange={onRegionSelection}
                  filterable={false}
                  showError={showError}
                  customStyle={{ width: "8vw", minHeight: "3vh" }}
                />
              </span>
            )}
            <span className="filter-siteId">
              <label htmlFor="region">{UIText.ShipTo}</label>
              <SearchableDropdown
                filterable
                style={{ minWidth: "5vw" }}
                onSelect={onSiteIdSelection}
                value={{ text: filters.selectedSiteId }}
                data={siteIds}
              />
            </span>
            <span className="filter-siteName">
              <label htmlFor="region">{UIText.Site_Name}</label>
              <SearchableDropdown
                filterable
                style={{ minWidth: "8vw" }}
                onSelect={onSiteNameSelection}
                value={{ text: filters.selectedSiteName }}
                data={siteNames}
              />
            </span>
            <span className="filter">
              <label htmlFor="region">{UIText.Material}</label>
              <MultiSelectionDropDown
                data={filters.products}
                values={productSelectedValues}
                onChange={onProductSelection}
                filterable={false}
                showError={showError}
                maxSelection={NUMBERS.ONE}
                customStyle={{ minWidth: "10vw" }}
              />
            </span>
            {isDachCluster() && (
              <span className="filter">
                <label htmlFor="region">{UIText.Tank_Group}</label>
                <MultiSelectionDropDown
                  data={filters.tankGroup}
                  values={getTankGroupValues(tankSelectedValues)}
                  onChange={onTankSelection}
                  filterable={false}
                  showError={showError}
                  maxSelection={NUMBERS.ONE}
                  customStyle={{ minWidth: "7vw" }}
                />
              </span>
            )}
          </span>
          <span className="filter-button">
            <Button
              className="get-data-btn"
              onClick={fetchThresholdData}
              disabled={loading}
            >
              {loading ? UIText.Fetching : UIText.Get_data}
            </Button>
          </span>
        </span>
      </div>
    );
  };

  const getTankGroupValues = (values) => {
    return values?.filter((tank) => tank?.group);
  };

  return (
    <div className="container data-grid site-info-container">
      <div className="row ">
        {error && <span>{error}</span>}
        {showHeaders()}
        {submitLoading && (
          <LoadingPanel message={UIText.Submitting_the_values} />
        )}
        <div className="threshold-data-container">
          {loading && <LoadingPanel message={UIText.Fetching_the_data} />}
          {showThresholdEntries()}
        </div>
        <div className="threshold-data-container">
          {thresholdData?.length > 0 && showCurrentThreshold()}
        </div>
      </div>
    </div>
  );
};

export { TankThresholdScreen };
