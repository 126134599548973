import { useState } from "react";
import { Button } from "@progress/kendo-react-all";
import "./pre-posting-container.scss";
import { useSelector } from "react-redux";
import { prePostingService } from "../../../../../services/pre-posting";
import { EditableDataGrid } from "../../../../components/editable-data-grid/editable-data-grid";
import { useEffect } from "react";
import {
  months,
  NUMBERS,
  PrePosting,
  triggerErrorNotification,
  triggerSuccessNotification,
} from "../common-utils";
import { UIText } from "../label-constants";

const PrePostingContainer = () => {
  const [prePostingData, setPrePostingData] = useState([]);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [loading, setLoading] = useState(false);
  const country = useSelector((state) => state?.user?.user?.Country);

  useEffect(() => {
    getPrepostingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const fieldsToShow = [
    {
      id: NUMBERS.ONE,
      title: PrePosting.Pre_Posting_Activities,
      field: PrePosting.Pre_Posting_Activities,
      editable: false,
    },
    {
      id: NUMBERS.TWO,
      title: PrePosting.Supporting_Evidences,
      field: PrePosting.Supporting_Evidences,
      editable: false,
      isLink: true,
    },
    {
      id: NUMBERS.THREE,
      title: UIText.Highlights_Disclosures_500_Chars,
      field: PrePosting.Highlighs_Disclosures,
      editable: true,
    },
  ];

  async function getPrepostingData() {
    const date = new Date();
    const currentMonth =
      months[
        date.getMonth() === NUMBERS.ZERO
          ? NUMBERS.ZERO
          : date.getMonth() - NUMBERS.ONE
      ];
    const currentYear = date.getFullYear();
    setMonth(currentMonth);
    setYear(currentYear);
    const response = await prePostingService.getPrepostingData(
      country,
      (months.indexOf(currentMonth) + NUMBERS.ONE)
        .toString()
        .padStart(NUMBERS.TWO, "0") || NUMBERS.ZERO,
      currentYear
    );
    if (response && response?.length > 0) {
      const trimmedResponse = response?.slice(NUMBERS.ONE);

      const sanitized = trimmedResponse?.map((e, index) => {
        return {
          key: index,
          [PrePosting.Pre_Posting_Activities]: e?.Col1,
          [PrePosting.Supporting_Evidences]: e?.Col2,
          [PrePosting.Highlighs_Disclosures]: e?.Col3,
        };
      });
      setPrePostingData(sanitized);
    } else {
      setPrePostingData([]);
      triggerErrorNotification(
        UIText.Could_not_get_records_for_the_selected_date
      );
    }
  }

  const handleOnSave = async (e) => {
    const content = [
      {
        Col1: PrePosting.Pre_Posting_Activities,
        Col2: PrePosting.Supporting_Evidences,
        Col3: PrePosting.Highlighs_Disclosures,
      },
    ];
    for (let i = 0; i < e.length; i++) {
      const element = e[i];
      content.push({
        Col1: element[PrePosting.Pre_Posting_Activities],
        Col2: element[PrePosting.Supporting_Evidences],
        Col3: element[PrePosting.Highlighs_Disclosures],
      });
    }
    const payload = {
      countryCode: country,
      month:
        (months.indexOf(month) === NUMBERS.ZERO
          ? NUMBERS.ONE
          : months.indexOf(month) + NUMBERS.ONE
        )
          .toString()
          .padStart(NUMBERS.TWO, "0") || NUMBERS.ZERO,
      year: year,
      content: content,
    };
    const response = await prePostingService.savePrePostDate(payload);
    if (response && response?.Status === "Success") {
      triggerSuccessNotification(UIText.Record_Updated_Successfully);
    } else {
      triggerErrorNotification(UIText.Unable_to_update_the_records);
    }
  };

  const sendEmail = async () => {
    setLoading(true);
    const response = await prePostingService.sendEmail();
    if (response?.Status === "Success") {
      triggerSuccessNotification(UIText.Mail_sent_successfully);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="pre-posting-activities-container hidden-xs hidden-sm">
        <>
          <div className="heading-container">
            <h3>
              {month
                ? `Showing data for ${month} ${year}`
                : UIText.Pre_Posting_Activities}
            </h3>
          </div>
          <div className="data-grid">
            <EditableDataGrid
              onDataUpdate={handleOnSave}
              fieldsToShow={fieldsToShow}
              gridData={prePostingData}
            />
            <div className="email-btn-row">
              <Button
                disabled={loading}
                className="button email-btn"
                onClick={sendEmail}
              >
                {UIText.Trigger_Email_Monthly_MEC_Report}
              </Button>
            </div>
          </div>
        </>
      </div>
      <div className="pre-posting-activities-container hidden-md hidden-lg hidden-xl">
        <div className="invalid-resolution-message-container">
          <h4>{UIText.Cannot_display_the_page_at_this_resolution}</h4>
          <p>{UIText.Either_zoom_out_to_100_or_switch_to_bigger_screen}</p>
        </div>
      </div>
    </>
  );
};

export { PrePostingContainer };
