import { getMultiSelectTreeValue } from "@progress/kendo-react-dropdowns";
import {
  getAllSelectedOptions,
  getMaterials,
  getOBNs,
  getShipTos,
  getSiteNames,
  getTankList,
} from "../reportUtils";
import { multiSelectionDropDownFields } from "../../../components/multi-select-dropdown/multi-select-dropdown";
import { NUMBERS } from "./common-utils";

export const onRegionChange = (props) => {
  const {
    filters,
    setFilters,
    masterData,
    setShipToSelectedValues,
    setObnSelectedValues,
    addAllOption,
    setSiteNameSelectedValues = null,
  } = props;

  const allSiteIds =
    filters.selectedRegions?.length > NUMBERS.ZERO
      ? getShipTos(masterData, filters.selectedRegions, addAllOption)
      : [];

  if (!allSiteIds || allSiteIds?.length === NUMBERS.ZERO) {
    setShipToSelectedValues?.([]);
    setObnSelectedValues?.([]);
    setSiteNameSelectedValues?.([]);
  }

  const siteNames =
    filters.selectedRegions?.length > NUMBERS.ZERO
      ? getSiteNames(masterData, filters.selectedRegions, addAllOption)
      : [];

  const obns =
    filters.selectedRegions?.length > NUMBERS.ZERO
      ? getOBNs(masterData, filters.selectedRegions, addAllOption)
      : [];
  setFilters((prev) => {
    return {
      ...prev,
      allSiteIds,
      allSiteNames: siteNames,
      allOBNs: obns,
    };
  });
};

export const onShipToValueChange = (
  filters,
  setFilters,
  shipToSelectedValues = null,
  setObnSelectedValues = null,
  setSiteNameSelectedValues = null
) => {
  const siteData = filters?.allSiteIds?.find(
    (siteInfo) => siteInfo?.id === shipToSelectedValues?.[0]?.id
  );

  const siteName = filters?.allSiteNames?.find(
    (siteInfo) => siteInfo?.siteName === siteData?.siteName
  );
  const siteNames = siteName && siteData ? [siteName] : [];

  const obn = filters?.allOBNs?.find(
    (siteInfo) => siteInfo?.obn === siteData?.obn
  );
  const obns = obn && siteData ? [obn] : [];

  setFilters((prev) => {
    return {
      ...prev,
      selectedShipTo: shipToSelectedValues ? shipToSelectedValues : [],
      selectedSiteName: siteNames,
      selectedOBN: obns,
    };
  });
  setSiteNameSelectedValues?.(siteNames);
  setObnSelectedValues?.(obns);
};

export const onSiteNameChange = (
  filters,
  setFilters,
  siteNameSelectedValues,
  setShipToSelectedValues,
  setObnSelectedValues
) => {
  const siteData = filters?.allSiteNames?.find(
    (siteInfo) => siteInfo?.id === siteNameSelectedValues?.[0]?.id
  );
  const site = filters?.allSiteIds?.find(
    (siteInfo) => siteInfo?.globalSiteId === siteData?.globalSiteId
  );
  const selectedShipTo = site && siteData ? [site] : [];

  const obn = filters?.allOBNs?.find(
    (siteInfo) => siteInfo?.globalSiteId === siteData?.globalSiteId
  );
  const selectedOBN = obn && siteData ? [obn] : [];
  setFilters((prev) => {
    return {
      ...prev,
      selectedShipTo: selectedShipTo,
      selectedSiteName: siteNameSelectedValues,
      selectedOBN: selectedOBN,
    };
  });

  setShipToSelectedValues(selectedShipTo);
  setObnSelectedValues(selectedOBN);
};

export const onOBNChange = (
  filters,
  setFilters,
  obnSelectedValues,
  setShipToSelectedValues,
  setSiteNameSelectedValues = null
) => {
  const siteData = filters?.allOBNs?.find(
    (siteInfo) => siteInfo?.id === obnSelectedValues?.[0]?.id
  );
  const site = filters?.allSiteIds?.find(
    (siteInfo) => siteInfo?.globalSiteId === siteData?.globalSiteId
  );
  const selectedShipTo = site && siteData ? [site] : [];

  const siteName = filters?.allSiteNames?.find(
    (siteInfo) => siteInfo?.siteName === siteData?.siteName
  );
  const siteNames = siteName && siteData ? [siteName] : [];
  setFilters((prev) => {
    return {
      ...prev,
      selectedShipTo: selectedShipTo,
      selectedSiteName: siteNames,
      selectedOBN: obnSelectedValues,
    };
  });

  setShipToSelectedValues?.(selectedShipTo);
  setSiteNameSelectedValues?.(siteNames);
};

export const onFilterSelectionChange = (
  event,
  setSelectedValues,
  currentValue,
  allOption = false
) => {
  if (event.operation === "delete") {
    setSelectedValues([]);
  } else {
    setSelectedValues((prev) => {
      let selectedValues = prev;
      if (allOption) {
        selectedValues = getAllSelectedOptions(event, prev);
      }
      return getMultiSelectTreeValue(currentValue, {
        ...multiSelectionDropDownFields,
        ...event,
        value: selectedValues,
      });
    });
  }
};

export const setShipToDetailsWhenRegionChange = (
  filters,
  masterData,
  setFilters,
  setShipToSelectedValues,
  setSiteNameSelectedValues,
  setObnSelectedValues
) => {
  const siteIds =
    filters.selectedRegions?.length > 0
      ? getShipTos(masterData, filters.selectedRegions)
      : [];

  const selectedShipTo =
    filters.selectedRegions?.length > 0 && siteIds?.length > 0
      ? [siteIds?.[0]]
      : [];

  const siteNames =
    filters.selectedRegions?.length > 0
      ? getSiteNames(masterData, filters.selectedRegions)
      : [];

  const selectedSiteName = siteNames?.length > 0 ? [siteNames?.[0]] : [];
  const obns =
    filters.selectedRegions?.length > 0
      ? getOBNs(masterData, filters.selectedRegions, true)
      : [];
  const selectedOBN = obns?.length > 0 ? [obns?.[0]] : [];

  setFilters((prev) => {
    return {
      ...prev,
      selectedShipTo: selectedShipTo,
      selectedSiteName: selectedSiteName,
      allSiteIds: siteIds,
      allSiteNames: siteNames,
      allOBNs: obns,
      selectedOBN: selectedOBN,
    };
  });
  setShipToSelectedValues(selectedShipTo);
  setSiteNameSelectedValues(selectedSiteName);
  setObnSelectedValues(selectedOBN);
};

export const setProductDetailsWhenShipToChange = (
  masterData,
  filters,
  setFilters,
  setProductSelectedValues,
  addAllOption
) => {
  const productsData = getMaterials(masterData, filters, addAllOption);
  const selectedProducts = productsData?.length > 0 ? [productsData[0]] : [];

  setFilters((prev) => {
    return {
      ...prev,
      products: productsData,
      selectedProducts: selectedProducts,
      tankGroup: [],
    };
  });
  setProductSelectedValues(selectedProducts);
};

export const setTankValuesOnProductChange = (
  masterData,
  filters,
  setFilters,
  setTankSelectedValues
) => {
  const tankGroupsData = getTankList(masterData, filters);
  const selectedTanks = tankGroupsData?.length > 0 ? [tankGroupsData[0]] : [];
  setFilters((prev) => {
    return {
      ...prev,
      tankGroup: tankGroupsData,
      selectedTanks: selectedTanks,
    };
  });

  setTankSelectedValues(selectedTanks);
};
