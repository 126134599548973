import React, { useEffect, useRef, useState } from "react";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import "./account-for-deliveries.scss";
import { GridToolbar } from "@progress/kendo-react-grid";
import {
  auditColsToShow,
  colsToExport,
  colsToShow,
  createSubmitPayload,
  getAFDPayload,
  getPlantValues,
  getSlocValues,
  hasEmptyValues,
  updateExportData,
} from "./account-for-deliveries-utils";
import {
  getLastDates,
  getMaterials,
  getFilterRegions,
  getTanks,
} from "../../reportUtils";
import { MultiSelectionDropDown } from "../../../../components/multi-select-dropdown/multi-select-dropdown";
import { Button } from "@progress/kendo-react-buttons";
import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";
import { reportsService } from "../../../../../services/reports";
import { useSelector } from "react-redux";
import { store } from "../../../../../state-management/store";
import {
  getFormattedDate,
  getFormattedDateAndTime,
  getDaysBeforeDate,
} from "../../../../../services/utils";
import { AddDelivery } from "./add-delivery-screen";
import { SiteInfoScreen } from "../out-of-tolerance-trend-analysis/out-of-tolerance-site-info";
import {
  DEFAULT_PAGE_SIZE,
  getSelectedCluster,
  NUMBERS,
  isDachCluster,
  triggerSuccessNotification,
  triggerErrorNotification,
} from "../common-utils";
import { DateRangePickerComponent } from "../../../../components/date-range-picker/date-range-picker";
import { SliderRangeComponent } from "../../../../components/slider-range/slider-range";
import { TabComponent } from "../../../../components/tab-component/tab-component";
import AFDDetailsController from "./account-for-deliveries-detail";
import { EndDateInput, StartDateInput } from "../common-grid-cells";
import { UIText } from "../label-constants";
import {
  onFilterSelectionChange,
  onOBNChange,
  onRegionChange,
  onShipToValueChange,
} from "../common-report-filters";

export const AFDTabOptions = {
  DELIVERY: NUMBERS.ZERO,
  AUDIT: NUMBERS.ONE,
};

const DropDownSelection = {
  ShipTo: NUMBERS.ZERO,
  OBN: NUMBERS.ONE,
};

const AccountForDeliveries = () => {
  const [originalData, setOriginalData] = useState([]);
  const [changedItems, setChangedItems] = useState([]);
  const [accountForDeliveriesData, setAccountForDeliveriesData] = useState([]);
  const [auditData, setAuditData] = useState([]);
  const [exportExcelData, setExportExcelData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [commentsOptions, setCommentsOptions] = useState([]);
  const [masterDataLoading, setMasterDataLoading] = useState(true);
  const [changed, setChanged] = useState(false);
  const exportGridRef = useRef(null);
  const exportAuditGridRef = useRef(null);
  const [showError, setShowError] = useState(false);
  const defaultCountry = useSelector(
    (state) => state?.reports?.selectedCountry
  );
  const [currentCountryCode, setCurrentCountryCode] = useState(defaultCountry);
  const defaultCluster = useSelector(
    (state) => state?.reports?.selectedCluster
  );
  const [currentCluster, setCurrentCluster] = useState(defaultCluster);
  const [resetIndex, setResetIndex] = useState(false);
  const [showAddDelivery, setShowAddDelivery] = useState(false);
  const inputRef = useRef(null);
  const [showSiteInfoModal, setShowSiteInfoModal] = useState(false);
  const [selectedSite, setSelectedSite] = useState();
  const [selectedTab, setSelectedTab] = useState(AFDTabOptions.DELIVERY);
  const [sliderValue, setSliderValue] = useState({
    start: -NUMBERS.THOUSAND,
    end: NUMBERS.THOUSAND,
  });
  const [currentField, setCurrentField] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const differenceVal = {
    min: -300000,
    max: 300000,
  };
  store.subscribe(() => {
    const { selectedCountry, selectedCluster } = store.getState().reports;
    setCurrentCountryCode(selectedCountry);
    setCurrentCluster(selectedCluster);
  });

  const getDefaultDates = () => {
    return {
      start: isDachCluster()
        ? getDaysBeforeDate(NUMBERS.TWO)
        : getDaysBeforeDate(NUMBERS.ONE),
      end: isDachCluster()
        ? getDaysBeforeDate(NUMBERS.TWO)
        : getDaysBeforeDate(NUMBERS.ONE),
    };
  };

  const [filters, setFilters] = useState({
    selectedDates: getDefaultDates(),
    dates: getLastDates(NUMBERS.FIVE, NUMBERS.ONE),
    selectedRegions: [],
    regions: getFilterRegions(filterData, false),
    selectedShipTo: [],
    allSiteIds: [],
    selectedOBN: [],
    allOBNs: [],
    selectedMaterials: [],
    materials: getMaterials(filterData),
    selectedSlocs: [],
    slocs: getSlocValues(filterData),
    selectedPlants: [],
    plants: [],
  });

  const [appliedFilters, setAppliedFilters] = useState(null);
  const [exporting, setExporting] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [masterDataCount, setMasterDataCount] = useState(0);

  const [dateSelectedValues, setDateSelectedValues] = useState(
    filters.selectedDates
  );

  const [regionSelectedValues, setRegionSelectedValues] = useState(
    filters.selectedRegions
  );

  const [shipToSelectedValues, setShipToSelectedValues] = useState(
    filters.selectedShipTo
  );

  const [obnSelectedValues, setObnSelectedValues] = useState(
    filters.selectedOBN
  );

  const [materialSelectedValues, setMaterialSelectedValues] = useState(
    filters.selectedMaterials
  );

  const [slocSelectedValues, setSlocSelectedValues] = useState(
    filters.selectedSlocs
  );

  const [plantSelectedValues, setPlantSelectedValues] = useState(
    filters.selectedPlants
  );

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedDates: getDefaultDates(),
    }));
  }, [currentCluster]);

  const handleAFDDeliveryResponse = (response) => {
    response?.sort((a, b) => a.materialNumber - b.materialNumber);
    response?.sort((a, b) => a.sloc - b.sloc);
    response?.sort((a, b) => a.shipTo - b.shipTo);

    const responseData = response?.map((item) => {
      const documentDate = item?.documentDate
        ? getFormattedDate(new Date(item?.documentDate))
        : "";
      const reportDate = item?.reportDate
        ? getFormattedDate(new Date(item?.reportDate))
        : "";
      const outBoundDeliveryId = item?.outBoundDeliveryId?.replace(",", "\n");
      return {
        ...item,
        documentDate: documentDate,
        reportDate: reportDate,
        outBoundDeliveryId: outBoundDeliveryId,
        outBoundDeliveryIdStr: item?.outBoundDeliveryId,
      };
    });

    setOriginalData(responseData ? responseData : []);
    setAccountForDeliveriesData(responseData ? responseData : []);
  };

  const handleAFDAuditResponse = (response) => {
    const responseData = response?.map((item) => {
      const reportDate = item?.reportDate
        ? getFormattedDate(new Date(item?.reportDate))
        : "";
      const outBoundDeliveryId = item?.outBoundDeliveryId?.replace(",", "\n");
      const isComment = item?.entity?.toLowerCase() === "comment";
      return {
        ...item,
        documentDate: item?.partitionKey,
        confirmedDelivery: isComment ? "" : item?.confimedDelivery,
        tankId: isComment ? "" : item?.tankId,
        outBoundDeliveryId: isComment ? "" : outBoundDeliveryId,
        outBoundDeliveryIdStr: item?.outBoundDeliveryId,
        reportDate: reportDate,
        modifiedOn: item?.modifiedOn
          ? getFormattedDateAndTime(new Date(item?.modifiedOn))
          : "",
      };
    });
    setAuditData(responseData);
  };

  const fetchAccountForDeliveriesReport = async (refresh = false) => {
    const filter = refresh ? appliedFilters : filters;
    setChanged(false);
    setChangedItems([]);
    setFetchingData(true);
    let payload = getAFDPayload(filter);

    if (selectedTab === AFDTabOptions.DELIVERY) {
      payload = {
        ...payload,
        LossDifference: sliderValue.start,
        GainDifference: sliderValue.end,
      };
      const response = await reportsService.fetchAccountForDeliveriesData(
        payload
      );
      handleAFDDeliveryResponse(response);
    } else {
      const response = await reportsService.fetchAFDAuditData(payload);

      handleAFDAuditResponse(response);
    }
    if (!refresh) {
      setAppliedFilters(filter);
    }

    setFetchingData(false);
  };

  const fetchMasterData = async () => {
    setMasterDataLoading(true);
    const masterData = await reportsService.fetchToleranceTrendMasterData({
      country: currentCountryCode,
    });
    setFilterData(masterData ? masterData : []);
    setMasterDataCount((prev) => prev + NUMBERS.ONE);
  };
  const fetchCommentsMasterData = async () => {
    setMasterDataLoading(true);
    const response = await reportsService.fetchCommentsMasterData({
      country: getSelectedCluster(),
    });
    const options = response?.map((option) => option?.categoryText);
    const optionsData = [UIText.Select, ...options];
    setCommentsOptions(options ? optionsData : []);
    setMasterDataCount((prev) => prev + NUMBERS.ONE);
  };

  useEffect(() => {
    if (masterDataCount === NUMBERS.TWO) {
      setMasterDataCount(0);
      setMasterDataLoading(false);
    }
  }, [masterDataCount]);

  useEffect(() => {
    updateExportData(accountForDeliveriesData, setExportExcelData);
    inputRef?.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountForDeliveriesData]);

  useEffect(() => {
    fetchMasterData();
    fetchCommentsMasterData();
    setChanged(false);
    setAccountForDeliveriesData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountryCode]);

  useEffect(() => {
    const regions = getFilterRegions(filterData, false, currentCountryCode);
    const selectedRegions =
      regions?.length > NUMBERS.ZERO ? [regions?.[0]] : [];
    setFilters((prev) => {
      return {
        ...prev,
        regions,
        selectedRegions,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  useEffect(() => {
    setDateSelectedValues(filters.selectedDates);
    setRegionSelectedValues(filters.selectedRegions);
    onRegionChange({
      filters,
      setFilters,
      masterData: filterData,
      setShipToSelectedValues,
      setObnSelectedValues,
      addAllOption: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedRegions]);

  useEffect(() => {
    const selectedShipTo =
      filters.allSiteIds?.length > NUMBERS.ZERO
        ? [filters.allSiteIds?.[0]]
        : [];

    setShipToSelectedValues(selectedShipTo);
    const selectedOBN =
      filters.allOBNs?.length > NUMBERS.ZERO ? [filters.allOBNs?.[0]] : [];
    setObnSelectedValues(selectedOBN);

    setFilters(prev => {
      return {
        ...prev,
        selectedShipTo,
        selectedOBN,
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.allSiteIds]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedDates: dateSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedRegions: regionSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionSelectedValues]);

  useEffect(() => {
    if (currentField === DropDownSelection.ShipTo) {
      onShipToValueChange(
        filters,
        setFilters,
        shipToSelectedValues,
        setObnSelectedValues
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipToSelectedValues]);

  useEffect(() => {
    if (currentField === DropDownSelection.OBN) {
      onOBNChange(
        filters,
        setFilters,
        obnSelectedValues,
        setShipToSelectedValues
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obnSelectedValues]);

  useEffect(() => {
    const tanks =
      filters.selectedShipTo?.length > NUMBERS.ZERO
        ? getTanks(filterData, filters)
        : [];
    const selectedTanks = tanks?.length > 0 ? [tanks?.[0]] : [];
    const materials =
      filters.selectedShipTo?.length > NUMBERS.ZERO
        ? getMaterials(filterData, filters)
        : [];
    const selectedMaterial =
      materials?.length > NUMBERS.ZERO ? [materials[0]] : [];
    const slocs = getSlocValues(filterData, filters);
    const selectedSlocs = slocs?.length > NUMBERS.ZERO ? [slocs[0]] : [];
    const plants = getPlantValues(filterData, filters);
    const selectedPlants = plants?.length > NUMBERS.ZERO ? [plants[0]] : [];

    setFilters((prev) => {
      return {
        ...prev,
        materials,
        selectedMaterials: selectedMaterial,
        tanks,
        selectedTanks,
        slocs,
        selectedSlocs,
        plants,
        selectedPlants,
      };
    });
    setMaterialSelectedValues(selectedMaterial);
    setSlocSelectedValues(selectedSlocs);
    setPlantSelectedValues(selectedPlants);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedShipTo]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedMaterials: materialSelectedValues,
    }));
    // loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedSlocs: slocSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slocSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedPlants: plantSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plantSelectedValues]);

  const handleDataChange = (e) => {
    const { dataItem } = e;
    const updatedItems = accountForDeliveriesData?.map((item, index) => {
      return index === dataItem.id ? e?.dataItem : item;
    });
    setAccountForDeliveriesData(updatedItems);

    const actualItem = originalData?.find(
      (item) => item.rowKey === dataItem.rowKey
    );

    const comment =
      actualItem.comment === UIText.Select ? "" : actualItem.comment;
    const currentComment =
      dataItem.comment === UIText.Select ? "" : dataItem.comment;

    const itemChanged =
      comment !== currentComment ||
      parseFloat(actualItem.confirmedDelivery) !==
        parseFloat(dataItem.confirmedDelivery) ||
      parseFloat(actualItem.outBoundDeliveryId) !==
        parseFloat(dataItem.outBoundDeliveryId);

    const onlyCommentUpdated =
      comment !== currentComment &&
      parseFloat(actualItem.confirmedDelivery) ===
        parseFloat(dataItem.confirmedDelivery);

    setChanged(itemChanged);
    if (itemChanged) {
      const sameItem = changedItems?.find(
        (item) => item.rowKey === dataItem.rowKey
      );

      if (sameItem) {
        const items = changedItems?.map((item) => {
          if (item.rowKey === dataItem.rowKey) {
            return dataItem;
          }
          return item;
        });
        setChangedItems(items);
      } else {
        setChangedItems([
          ...changedItems,
          { ...dataItem, onlyCommentUpdated: onlyCommentUpdated },
        ]);
      }
    } else {
      const items = changedItems?.filter(
        (item) => item.rowKey !== dataItem.rowKey
      );
      setChangedItems(items);
    }
  };

  const saveChanges = async () => {
    setSubmitting(true);
    const payload = createSubmitPayload(changedItems);
    const response = await reportsService.updateAFDDelivery(payload);
    if (
      response?.status >= NUMBERS.TWO_HUNDRED &&
      response?.status < NUMBERS.THREE_HUNDRED
    ) {
      setChanged(false);
      setChangedItems([]);
      fetchAccountForDeliveriesReport();
      setSubmitting(false);
      triggerSuccessNotification(
        response?.data?.message || UIText.Delivery_updated_successfully
      );
    } else {
      triggerErrorNotification(UIText.Unable_to_submit_the_comments);
    }
    setSubmitting(false);
  };

  const cancelChanges = async () => {
    setAccountForDeliveriesData(originalData);
    setChanged(false);
    setChangedItems([]);
  };

  const showHideLoaders = (show = false) => {
    setExporting(show);
  };
  const exportData = async () => {
    showHideLoaders(true);
    await cancelChanges();

    exportGridRef?.current?.exportDataInExcel &&
      (await exportGridRef.current.exportDataInExcel());

    setTimeout(showHideLoaders, NUMBERS.THOUSAND);
  };

  const exportAuditData = async () => {
    showHideLoaders(true);

    exportAuditGridRef?.current?.exportDataInExcel &&
      (await exportAuditGridRef.current.exportDataInExcel());

    setTimeout(showHideLoaders, NUMBERS.THOUSAND);
  };

  const addDelivery = () => {
    setShowAddDelivery(true);
  };

  const cancelDelivery = () => {
    setShowAddDelivery(false);
  };

  const saveDelivery = () => {
    setShowAddDelivery(false);
    fetchData();
  };

  const detailController = (propValues) => (
    <AFDDetailsController
      {...propValues}
      fetchAccountForDeliveriesReport={fetchAccountForDeliveriesReport}
    />
  );
  const showDeliveryView = () => {
    return (
      <span className="grid-container">
        <AppDataGrid
          resetIndex={resetIndex}
          setResetIndex={setResetIndex}
          take={DEFAULT_PAGE_SIZE}
          style={{
            height: "56vh",
            margin: "0 0.5vw 0.5vw 0.5vw",
          }}
          pageable={true}
          filterable={true}
          data={accountForDeliveriesData}
          expandable={true}
          detailsContainer={detailController}
          columnsToShow={colsToShow(commentsOptions, handleShipToLinkAction)}
          onItemChange={handleDataChange}
          sortable={true}
          ref={exportGridRef}
          exportFileName={UIText.AFD_Report}
          exportData={exportExcelData}
          columnsToExport={colsToExport()}
          noRecordsMessage={UIText.No_data_found_filter_text}
        />
      </span>
    );
  };

  const showChangeHistoryView = () => {
    return (
      <span className="grid-container">
        <AppDataGrid
          resetIndex={resetIndex}
          setResetIndex={setResetIndex}
          take={DEFAULT_PAGE_SIZE}
          style={{
            height: "59vh",
            margin: "0 0.5vw 0.5vw 0.5vw",
          }}
          pageable={true}
          filterable={true}
          data={auditData}
          expandable={false}
          columnsToShow={auditColsToShow(handleShipToLinkAction)}
          sortable={true}
          ref={exportAuditGridRef}
          exportFileName={UIText.AFD_Audit_Report}
          noRecordsMessage={UIText.No_data_found_filter_text}
        />
      </span>
    );
  };
  const showTabContent = () => (
    <div>
      {showGridToolBar()}
      {selectedTab === AFDTabOptions.DELIVERY && showDeliveryView()}
      {selectedTab === AFDTabOptions.AUDIT && showChangeHistoryView()}
    </div>
  );

  const showTabComponent = () => {
    const items = [
      {
        label: UIText.Delivery_View,
        key: AFDTabOptions.DELIVERY,
      },
      {
        label: UIText.Change_History,
        key: AFDTabOptions.AUDIT,
      },
    ];
    return (
      <TabComponent
        items={items}
        activeIndex={selectedTab}
        onChange={(index) => {
          setSelectedTab(index);
        }}
      />
    );
  };

  const headerButtons = () => {
    const saveColor = changed && "#0092a5";
    const cancelColor = changed && "#fbce07";
    const borderWidth = changed && "0.15vw";
    return (
      <div className="buttonsContainer">
        {selectedTab === AFDTabOptions.DELIVERY ? (
          <div className="buttonsLeftContainer">
            <Button
              className="exportButton"
              onClick={exportData}
              disabled={
                exporting || !accountForDeliveriesData?.length || changed
              }
            >
              {exporting ? UIText.Exporting : UIText.Export}
            </Button>
            <Button className="exportButton" onClick={addDelivery}>
              {UIText.Add_New_Delivery}
            </Button>
          </div>
        ) : (
          <div className="buttonsLeftContainer">
            <Button
              className="exportButton"
              onClick={exportAuditData}
              disabled={!auditData?.length}
            >
              {exporting ? UIText.Exporting : UIText.Export}
            </Button>
          </div>
        )}
        <div className="submitButtonContainer">
          {selectedTab === AFDTabOptions.DELIVERY && (
            <>
              <button
                title={UIText.Save}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base toolbar-button"
                onClick={saveChanges}
                style={{ borderColor: saveColor, borderWidth: borderWidth }}
                disabled={!changed}
              >
                {UIText.Save}
              </button>
              <button
                title={UIText.Cancel}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base toolbar-button"
                onClick={cancelChanges}
                style={{ borderColor: cancelColor, borderWidth: borderWidth }}
                disabled={!changed}
              >
                {UIText.Cancel}
              </button>
            </>
          )}
        </div>
      </div>
    );
  };

  const onDateSelection = (event) => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedDates: event.value,
      };
    });
  };

  const onRegionSelection = (event) => {
    onFilterSelectionChange(
      event,
      setRegionSelectedValues,
      filters?.regions,
      true
    );
  };

  const onShipToSelection = (event) => {
    setCurrentField(DropDownSelection.ShipTo);
    onFilterSelectionChange(
      event,
      setShipToSelectedValues,
      filters?.allSiteIds,
      true
    );
  };

  const onOBNSelection = (event) => {
    setCurrentField(DropDownSelection.OBN);
    onFilterSelectionChange(
      event,
      setObnSelectedValues,
      filters?.allOBNs,
      true
    );
  };

  const onMaterialSelection = (event) => {
    onFilterSelectionChange(
      event,
      setMaterialSelectedValues,
      filters?.materials,
      true
    );
  };

  const onSlocSelection = (event) => {
    onFilterSelectionChange(event, setSlocSelectedValues, filters?.slocs, true);
  };

  const onPlantSelection = (event) => {
    onFilterSelectionChange(
      event,
      setPlantSelectedValues,
      filters?.plants,
      true
    );
  };

  const fetchData = (showErrorUI = false) => {
    const canShowError = hasEmptyValues(filters, selectedTab);
    if (showErrorUI) {
      setShowError(canShowError);
    }

    if (!canShowError) {
      fetchAccountForDeliveriesReport();
      setResetIndex(true);
    }
  };

  const startDateInput = (propValues) => (
    <StartDateInput {...propValues} showError={showError} />
  );

  const endDateInput = (propValues) => (
    <EndDateInput {...propValues} showError={showError} />
  );

  const headerFilters = () => {
    return (
      <div className="filters-container">
        <div className="filter-date">
          <DateRangePickerComponent
            placeholder={UIText.Select_date_range}
            defaultValue={filters.selectedDates}
            value={filters.selectedDates}
            startDateInput={startDateInput}
            endDateInput={endDateInput}
            onChange={onDateSelection}
            max={getDaysBeforeDate(NUMBERS.ONE)}
          />
        </div>
        {filters.regions?.length > NUMBERS.ONE && (
          <span className="filter">
            <label htmlFor="region">{UIText.Region}</label>
            <MultiSelectionDropDown
              data={filters.regions}
              values={regionSelectedValues}
              onChange={onRegionSelection}
              filterable={false}
              showError={showError && !masterDataLoading}
              customStyle={{
                width: "7.5vw",
                minHeight: "3vh",
              }}
              maxSelection={NUMBERS.ONE}
            />
          </span>
        )}

        <span className="filter">
          <label htmlFor="shipTo">{UIText.ShipTo}</label>
          <MultiSelectionDropDown
            data={filters.allSiteIds}
            values={shipToSelectedValues}
            onChange={onShipToSelection}
            filterable={true}
            showError={showError && !masterDataLoading}
            customStyle={{ width: "6.5vw", minHeight: "3vh" }}
          />
        </span>
        <span className="filter">
          <label htmlFor="shipTo">{UIText.OBN}</label>
          <MultiSelectionDropDown
            data={filters.allOBNs}
            values={obnSelectedValues}
            onChange={onOBNSelection}
            filterable={true}
            showError={showError}
            customStyle={{ width: "5.2vw", minHeight: "3vh" }}
            maxSelection={NUMBERS.ONE}
          />
        </span>
        <span className="filter">
          <label htmlFor="material">{UIText.Material_No}</label>
          <MultiSelectionDropDown
            data={filters.materials}
            values={materialSelectedValues}
            onChange={onMaterialSelection}
            filterable={true}
            showError={showError && !masterDataLoading}
            customStyle={{
              width: filters.regions?.length > NUMBERS.ONE ? "7.5vw" : "12vw",
              minHeight: "3vh",
            }}
          />
        </span>

        {selectedTab === AFDTabOptions.DELIVERY && (
          <>
            <span className="filter">
              <label htmlFor="SLOC">{UIText.SLOC}</label>
              <MultiSelectionDropDown
                data={filters.slocs}
                values={slocSelectedValues}
                onChange={onSlocSelection}
                filterable={false}
                showError={showError && !masterDataLoading}
                customStyle={{
                  width: "6.0vw",
                  minHeight: "3vh",
                }}
              />
            </span>
            <span className="filter">
              <label htmlFor="Plant">{UIText.Plant}</label>
              <MultiSelectionDropDown
                data={filters.plants}
                values={plantSelectedValues}
                onChange={onPlantSelection}
                filterable={false}
                showError={showError && !masterDataLoading}
                customStyle={{
                  width: "5vw",
                  minHeight: "3vh",
                }}
              />
            </span>
            <SliderRangeComponent
              label={UIText.Threshold}
              sliderValue={sliderValue}
              differenceVal={differenceVal}
              setSliderValue={setSliderValue}
            />
          </>
        )}

        <Button
          className={
            selectedTab === AFDTabOptions.DELIVERY
              ? "get-data-btn-delivery"
              : "get-data-btn-change"
          }
          onClick={() => {
            fetchData(true);
          }}
          disabled={fetchingData}
        >
          {fetchingData ? UIText.Fetching : UIText.Get_data}
        </Button>
        <Button
          className={
            selectedTab === AFDTabOptions.DELIVERY
              ? "refresh-btn-delivery"
              : "refresh-btn-change"
          }
          onClick={() => {
            fetchAccountForDeliveriesReport(true);
          }}
          disabled={!appliedFilters}
        >
          {UIText.Refresh}
        </Button>
      </div>
    );
  };

  const showGridToolBar = () => {
    return (
      <GridToolbar>
        <div className="gridToolbar">
          {headerFilters()}
          <div className="rowContainer">{headerButtons()}</div>
        </div>
      </GridToolbar>
    );
  };

  const handleShipToLinkAction = (siteId, country) => {
    setSelectedSite({ siteId: siteId, country: country });
    setShowSiteInfoModal(true);
  };

  const closeModal = () => {
    setShowSiteInfoModal(false);
  };
  return (
    <>
      <div className="account-for-deliveries-screen-container data-grid">
        <div className="row ">
          {showTabComponent()}
          <div className="data-grid-container">
            {showTabContent()}
            {showSiteInfoModal && (
              <SiteInfoScreen
                globalSiteId={selectedSite?.siteId}
                countryCode={currentCountryCode}
                close={closeModal}
              />
            )}

            {masterDataLoading && (
              <LoadingPanel message={UIText.Fetching_the_config_data} />
            )}
            {exporting && <LoadingPanel message={UIText.Exporting_the_data} />}
            {fetchingData && (
              <LoadingPanel message={UIText.Fetching_the_data} />
            )}
            {submitting && (
              <LoadingPanel message={UIText.Updating_the_values} />
            )}
          </div>
        </div>
        {showAddDelivery && (
          <AddDelivery
            filters={filters}
            filterData={filterData}
            commentOptions={commentsOptions}
            cancelDelivery={cancelDelivery}
            saveDelivery={saveDelivery}
          />
        )}
      </div>
    </>
  );
};
export { AccountForDeliveries };
