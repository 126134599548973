import { CustomHeaderCell } from "../account-for-deliveries/account-for-deliveries-utils";
import { UIText } from "../label-constants";
import { IconCell, MissingMandatoryCell } from "../common-grid-cells";

export const colsToShow = () => {
  const columns = [
    {
      title: UIText.Product,
      field: "productCode",
      headerCell: CustomHeaderCell,
      cell: (props) => {
        return <MissingMandatoryCell {...props} />;
      },
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.Material_No,
      field: "materialNumber",
      filterable: true,
      sortable: true,
      headerCell: CustomHeaderCell,
      cell: (props) => {
        return <MissingMandatoryCell {...props} />;
      },
    },
    {
      title: UIText.Material_Name,
      field: "genericMaterialName",
      headerCell: CustomHeaderCell,
      cell: (props) => {
        return <MissingMandatoryCell {...props} />;
      },
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.Official_Material_Name,
      field: "officialName",
      filterable: true,
      sortable: true,
      headerCell: CustomHeaderCell,
      cell: (props) => {
        return <MissingMandatoryCell {...props} />;
      },
    },

    {
      title: UIText.Is_Active,
      field: "active",
      sortable: true,
      headerCell: CustomHeaderCell,
      cell: (props) => {
        return <IconCell {...props}/>;
      },
    },
  ];
  return columns;
};
