import React, { useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import "./out-of-tolerance-trend-analysis.scss";
import { reportsService } from "../../../../../services/reports";
import {
  ExpansionPanel,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import { tankColsToShow } from "./out-of-tolerance-trend-analysis-utils";
import { isDachCluster, NUMBERS } from "../common-utils";
import { UIText } from "../label-constants";

const SiteInfoItems = {
  BASIC_INFO: UIText.Basic_Information,
  TANK_INFO: UIText.Tank_Details,
};

const SiteInfoConfig = [
  {
    id: SiteInfoItems.BASIC_INFO,
    category: SiteInfoItems.BASIC_INFO,
  },
  {
    id: SiteInfoItems.TANK_INFO,
    category: SiteInfoItems.TANK_INFO,
  },
];

const showContent = (expandedItems, setExpandedItems, getContent) => {
  const onExpand = (event, item) => {
    if (event.syntheticEvent.type === "click") {
      const currentExpanded = expandedItems?.find(
        (expandedItem) => expandedItem.id === item.id
      );
      if (!currentExpanded) {
        setExpandedItems((prev) => {
          return [...prev, item];
        });
      } else {
        const items = expandedItems?.filter(
          (expandItem) => expandItem.id !== item.id
        );
        setExpandedItems(items);
      }
    }
  };
  return (
    <div className="deliveryContent">
      <div className="contentWrapper">
        <div style={{ overflowY: "auto", maxHeight: "70vh" }}>
          {SiteInfoConfig.map((item) => {
            const expanded = expandedItems?.find(
              (expandedItem) => expandedItem.id === item.id
            );
            const isExpanded = expanded ? true : false;
            return (
              <ExpansionPanel
                className={isExpanded ? "expanded" : ""}
                title={item.category}
                expanded={isExpanded}
                key={item.id}
                onAction={(event) => {
                  onExpand(event, item);
                }}
              >
                <Reveal>
                  {isExpanded && (
                    <ExpansionPanelContent>
                      <div className="content">
                        <div
                          role="button"
                          tabIndex={"0"}
                          onKeyDown={(e) => e.stopPropagation()}
                        >
                          {getContent(item.id)}
                        </div>
                      </div>
                    </ExpansionPanelContent>
                  )}
                </Reveal>
              </ExpansionPanel>
            );
          })}
          <div className="emptyItem"></div>
        </div>
      </div>
    </div>
  );
};

const SiteInfoScreen = (props) => {
  const globalSiteId = props?.globalSiteId;
  const countryCode = props?.countryCode;
  const [expandedItems, setExpandedItems] = useState([SiteInfoConfig?.[0]]);
  const [loading, setLoading] = useState(false);
  const [siteInfo, setSiteInfo] = useState(false);

  const fetchSiteInfo = async () => {
    setLoading(true);
    const response = await reportsService.fetchSiteInfo({
      countryCode,
      globalSiteId,
    });

    setSiteInfo(response);
    setLoading(false);
  };

  useEffect(() => {
    fetchSiteInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBasicInfo = () => {
    return (
      <div className="contentContainer">
        <div className="leftContainer">
          <div className="row">
            <div className="labelContainer ">{UIText.Site_Name}: </div>
            <div className="valueContainer">{siteInfo?.Name}</div>
          </div>
          <div className="row">
            <div className="labelContainer ">{UIText.ShipTo}: </div>
            <div className="valueContainer">{siteInfo?.GlobalSiteId}</div>
          </div>

          <div className="row">
            <div className="labelContainer ">{UIText.Site_Id}: </div>
            <div className="valueContainer">{siteInfo?.SiteId}</div>
          </div>
          <div className="row">
            <div className="labelContainer ">{UIText.OBN}: </div>
            <div className="valueContainer">{siteInfo?.OBN}</div>
          </div>
        </div>
        <div className="middleContainer">
          <div className="row">
            <div className="labelContainer ">{UIText.Country}: </div>
            <div className="valueContainer">{siteInfo?.CountryCode}</div>
          </div>
          <div className="row">
            <div className="labelContainer ">{UIText.Region}: </div>
            <div className="valueContainer">{siteInfo?.Region}</div>
          </div>
          <div className="row">
            <div className="labelContainer ">{UIText.Plant}: </div>
            <div className="valueContainer">{siteInfo?.PLANT}</div>
          </div>
          <div className="row">
            <div className="labelContainer ">{UIText.SLOC}: </div>
            <div className="valueContainer">{siteInfo?.SLOC}</div>
          </div>
        </div>
        <div className="rightContainer">
          {isDachCluster() && (
            <div className="row">
              <div className="labelContainer ">{UIText.Source}: </div>
              <div className="valueContainer">{siteInfo?.Source}</div>
            </div>
          )}
          <div className="row">
            <div className="labelContainer ">{UIText.Last_Updated_On}: </div>
            <div className="valueContainer">{siteInfo?.Timestamp}</div>
          </div>
          <div className="row">
            <div className="labelContainer ">{UIText.Status}: </div>
            <div
              className="valueContainer"
              style={{
                color:
                  siteInfo?.SiteStatus?.toLowerCase() === "active"
                    ? "green"
                    : "red",
              }}
            >
              {siteInfo?.SiteStatus}
            </div>
          </div>
          <div className="row">
            <div className="labelContainer">{UIText.Closed_On}: </div>
            <div className="valueContainer closed-status">
              {siteInfo?.SiteClosingDate}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getTankInfoDetails = () => {
    return (
      <div className="contentContainer">
        <div className="out-of-trend-analysis-site-info">
          <AppDataGrid
            style={{ maxHeight: "30vh" }}
            take={1000000000}
            total={siteInfo?.TankInfo?.length}
            rowHeight={NUMBERS.THIRTY}
            pageable={false}
            filterable={true}
            data={siteInfo?.TankInfo}
            columnsToShow={tankColsToShow(siteInfo?.TankInfo)}
          />
        </div>
      </div>
    );
  };

  const getContent = (id) => {
    switch (id) {
      case SiteInfoItems.BASIC_INFO:
        return getBasicInfo();

      case SiteInfoItems.TANK_INFO:
        return getTankInfoDetails();

      default:
        return <></>;
    }
  };

  const showHeader = () => {
    return (
      <div className="deliveryHeader">
        <div className="title">{`Site Information: ${globalSiteId}`}</div>
        <div className="close-btn-container">
          <Button
            className="close-btn"
            onClick={() => {
              props?.close();
            }}
          >
            {UIText.Close}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="deliveryContainer">
      <div className="deliveryForm">
        {showHeader()}
        {showContent(expandedItems, setExpandedItems, getContent)}
        {loading && (
          <span className="loaderContainer">
            <LoadingPanel message={UIText.Fetching_the_data} />
          </span>
        )}
      </div>
    </div>
  );
};

export { SiteInfoScreen };
