import { NUMBERS } from "../../pages/reports/components/common-utils";

class EditableDataGridHelperService {
  data = [];

  setEditableDataGridData(gridData) {
    this.data = gridData;
  }

  generateId = (data) =>
    data.reduce((acc, current) => Math.max(acc, current.id), 0) + NUMBERS.ONE;

  insertItem = (item) => {
    item.id = this.generateId(this.data);
    item.inEdit = false;
    this.data.unshift(item);
    return this.data;
  };

  getItems = () => {
    return this.data;
  };

  updateItem = (item) => {
    const index = this.data.findIndex((record) => record.id === item.id);
    this.data[index] = item;
    return this.data;
  };

  deleteItem = (item) => {
    const index = this.data.findIndex((record) => record.id === item.id);
    this.data.splice(index, NUMBERS.ONE);
    return this.data;
  };
}
const editableDataGridHelperService = new EditableDataGridHelperService();
export { editableDataGridHelperService };
