import "./eod-summary.scss";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { reportsService } from "../../../../../services/reports";
import { Button } from "@progress/kendo-react-all";
import { store } from "../../../../../state-management/store";
import {
  checkEODMandatoryValuesSelection,
  colsToShow,
  EODViewOptions,
  formatEODResponseData,
  getActualItem,
  getEditableField,
  getEODFetchAPIRequestPayload,
  getEODSubmitApiPayload,
  getFilteredProducts,
  getFilteredTanks,
  getSelectedShipTo,
  isBfxFieldsChanged,
  isBfxValid,
  isDachFieldsChanged,
  isDachValid,
  isItemChanged,
} from "./eod-summary-utils";
import { MultiSelectionDropDown } from "../../../../components/multi-select-dropdown/multi-select-dropdown";
import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";
import { getDaysBeforeDate } from "../../../../../services/utils";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import { SiteInfoScreen } from "../out-of-tolerance-trend-analysis/out-of-tolerance-site-info";
import { getFilterRegions } from "../../reportUtils";
import AlertPopup from "../../../../components/alert-popup/alert-popup";
import {
  DEFAULT_PAGE_SIZE,
  isBFXCluster,
  isDachCluster,
  isDefaultDataLoadEnabled,
  NUMBERS,
  triggerErrorNotification,
  triggerSuccessNotification,
} from "../common-utils";

import { DateRangePickerComponent } from "../../../../components/date-range-picker/date-range-picker";
import { TabComponent } from "../../../../components/tab-component/tab-component";
import { EndDateInput, StartDateInput } from "../common-grid-cells";
import { UIText } from "../label-constants";
import {
  onFilterSelectionChange,
  onOBNChange,
  onShipToValueChange,
  onSiteNameChange,
  setProductDetailsWhenShipToChange,
  setShipToDetailsWhenRegionChange,
  setTankValuesOnProductChange,
} from "../common-report-filters";

const DropDownSelection = {
  ShipTo: NUMBERS.ZERO,
  SiteName: NUMBERS.ONE,
  OBN: NUMBERS.TWO,
};

const EODSummaryScreen = () => {
  const inputRef = useRef(null);
  const [appliedFilters, setAppliedFilters] = useState(null);
  const [cursorPosition, setCursorPosition] = useState(null);
  const [firstLoad, setFirstLoad] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [resetIndex, setResetIndex] = useState(false);
  const [currentField, setCurrentField] = useState(null);
  const [showSiteInfoModal, setShowSiteInfoModal] = useState(false);
  const [selectedSite, setSelectedSite] = useState();
  const [masterData, setMasterData] = useState();

  const getDefaultDates = () => {
    return {
      start: isDachCluster()
        ? getDaysBeforeDate(NUMBERS.TWO)
        : getDaysBeforeDate(NUMBERS.ONE),
      end: isDachCluster()
        ? getDaysBeforeDate(NUMBERS.TWO)
        : getDaysBeforeDate(NUMBERS.ONE),
    };
  };

  const getDefaultFilters = () => {
    return {
      selectedShipTo: null,
      selectedRegions: [],
      regions: getFilterRegions(masterData, false),
      selectedSiteName: null,
      selectedOBN: [],
      allOBNs: [],
      selectedProducts: [],
      products: [],
      selectedTanks: [],
      tankGroup: [],
      selectedDates: getDefaultDates(),
      prevSelectedDates: {
        start: null,
        end: null,
      },
      allSiteIds: [],
      allSiteNames: [],
    };
  };

  const [filters, setFilters] = useState(getDefaultFilters());
  const [eodSummaryData, setEodSummaryData] = useState([]);
  const [showError, setShowError] = useState(false);
  const [changed, setChanged] = useState(false);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [changedItems, setChangedItems] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [exporting, setExporting] = useState(false);
  const exportGridRef = useRef(null);
  const [error, setError] = useState("");
  const [filterDataLoading, setFilterDataLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const defaultCountry = useSelector(
    (state) => state?.reports?.selectedCountry
  );
  const [currentCountryCode, setCurrentCountryCode] = useState(defaultCountry);
  const defaultCluster = useSelector(
    (state) => state?.reports?.selectedCluster
  );
  const [currentCluster, setCurrentCluster] = useState(defaultCluster);
  const [selectedTab, setSelectedTab] = useState(EODViewOptions.TANK);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [regionSelectedValues, setRegionSelectedValues] = useState(
    filters.selectedRegions
  );

  store.subscribe(() => {
    const { selectedCountry, selectedCluster } = store.getState().reports;
    setCurrentCountryCode(selectedCountry);
    setCurrentCluster(selectedCluster);
  });

  useEffect(() => {
    setFilters((_prev) => getDefaultFilters());
    setShipToSelectedValues([]);
    setRegionSelectedValues([]);
    fetchMasterData();
    setChanged(false);
    setDataUpdated(false);
    setFirstLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountryCode]);

  const [shipToSelectedValues, setShipToSelectedValues] = useState(
    filters.selectedShipTo
  );

  const [siteNameSelectedValues, setSiteNameSelectedValues] = useState(
    filters.selectedSiteName
  );

  const [obnSelectedValues, setObnSelectedValues] = useState(
    filters.selectedOBN
  );

  const [productSelectedValues, setProductSelectedValues] = useState(
    filters?.selectedProducts
  );
  const [tankSelectedValues, setTankSelectedValues] = useState(
    filters?.selectedTanks
  );

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedDates: {
        start: isDachCluster()
          ? getDaysBeforeDate(NUMBERS.TWO)
          : getDaysBeforeDate(NUMBERS.ONE),
        end: isDachCluster()
          ? getDaysBeforeDate(NUMBERS.TWO)
          : getDaysBeforeDate(NUMBERS.ONE),
      },
    }));
  }, [currentCluster]);

  useEffect(() => {
    const regions = getFilterRegions(masterData, false, currentCountryCode);
    const selectedRegions =
      regions?.length > NUMBERS.ZERO ? [regions?.[0]] : [];

    setFilters((prev) => {
      return {
        ...prev,
        selectedRegions: selectedRegions,
        regions: regions,
      };
    });
    setRegionSelectedValues(selectedRegions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterData]);

  useEffect(() => {
    setEodSummaryData([]);
    setChanged(false);
    setDataUpdated(false);
    setChangedItems([]);
    fetchEodSummaryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedRegions: regionSelectedValues,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionSelectedValues]);

  useEffect(() => {
    setShipToDetailsWhenRegionChange(
      filters,
      masterData,
      setFilters,
      setShipToSelectedValues,
      setSiteNameSelectedValues,
      setObnSelectedValues
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedRegions]);

  useEffect(() => {
    setProductDetailsWhenShipToChange(
      masterData,
      filters,
      setFilters,
      setProductSelectedValues,
      true
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.selectedShipTo]);

  useEffect(() => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedProducts: productSelectedValues,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSelectedValues]);

  useEffect(() => {
    setTankValuesOnProductChange(
      masterData,
      filters,
      setFilters,
      setTankSelectedValues
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedProducts]);

  useEffect(() => {
    if (
      !firstLoad &&
      filters.selectedTanks?.length > NUMBERS.ZERO &&
      isDefaultDataLoadEnabled()
    ) {
      fetchEodSummaryData();
      setFirstLoad(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedTanks]);

  useEffect(() => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedTanks: tankSelectedValues,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tankSelectedValues]);

  useEffect(() => {
    if (currentField === DropDownSelection.ShipTo) {
      onShipToValueChange(
        filters,
        setFilters,
        shipToSelectedValues,
        setObnSelectedValues,
        setSiteNameSelectedValues
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipToSelectedValues]);

  useEffect(() => {
    if (currentField === DropDownSelection.SiteName) {
      onSiteNameChange(
        filters,
        setFilters,
        siteNameSelectedValues,
        setShipToSelectedValues,
        setObnSelectedValues
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteNameSelectedValues]);

  useEffect(() => {
    if (currentField === DropDownSelection.OBN) {
      onOBNChange(
        filters,
        setFilters,
        obnSelectedValues,
        setShipToSelectedValues,
        setSiteNameSelectedValues
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obnSelectedValues]);

  const onOBNSelection = (event) => {
    setCurrentField(DropDownSelection.OBN);
    onFilterSelectionChange(
      event,
      setObnSelectedValues,
      filters?.allOBNs,
      true
    );
  };

  const onShipToSelection = (event) => {
    setCurrentField(DropDownSelection.ShipTo);
    onFilterSelectionChange(
      event,
      setShipToSelectedValues,
      filters?.allSiteIds,
      true
    );
  };

  const onSiteNameSelection = (event) => {
    setCurrentField(DropDownSelection.SiteName);
    onFilterSelectionChange(
      event,
      setSiteNameSelectedValues,
      filters?.allSiteNames,
      true
    );
  };

  const onProductSelection = (event) => {
    onFilterSelectionChange(
      event,
      setProductSelectedValues,
      filters?.products,
      true
    );
  };

  const onTankSelection = (event) => {
    onFilterSelectionChange(
      event,
      setTankSelectedValues,
      filters?.tankGroup,
      true
    );
  };

  const onDateSelection = (event) => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedDates: event.value,
      };
    });
  };

  const fetchMasterData = async () => {
    try {
      setEodSummaryData([]);
      setShowError(false);
      setFilterDataLoading(true);
      const response = await reportsService.fetchToleranceTrendMasterData({
        country: currentCountryCode,
      });
      setFilterDataLoading(false);
      if (response) {
        setMasterData(response);
      } else {
        triggerErrorNotification(UIText.Unable_to_fetch_the_data);
      }
    } catch (error1) {
      triggerErrorNotification(
        UIText.Server_error_Unable_to_submit_the_request
      );
      setError(UIText.Server_error_Unable_to_submit_the_request);
      setFilterDataLoading(false);
      setError("");
    }
  };

  const onRegionSelection = (event) => {
    onFilterSelectionChange(
      event,
      setRegionSelectedValues,
      filters?.regions,
      true
    );
  };

  const showHideLoaders = (show = false) => {
    setExporting(show);
  };
  const exportData = async () => {
    showHideLoaders(true);
    await cancelChanges();
    exportGridRef?.current?.exportDataInExcel &&
      (await exportGridRef.current.exportDataInExcel());

    setTimeout(showHideLoaders, NUMBERS.THOUSAND);
  };

  const getSelectedFilter = (refresh) => {
    return refresh ? appliedFilters : filters;
  };

  const getSummaryResponse = async (payload) => {
    let response = [];
    if (selectedTab === EODViewOptions.AUDIT) {
      response = await reportsService.fetchEodAuditData(payload);
    } else {
      const endPoint = isBFXCluster() ? "GetEODData" : "GetEODDataDACH";
      response = await reportsService.fetchEodSummaryData(payload, endPoint);
    }

    return response;
  };

  const fetchEodSummaryData = async (refresh = false) => {
    const filter = getSelectedFilter(refresh);
    const fromDate = filter.selectedDates.start;
    const toDate = filter.selectedDates.end;
    const selectedShipTo = getSelectedShipTo(filter);

    const selectedRegions = filter?.selectedRegions?.map((region) =>
      region?.id === "all" ? UIText.ALL : region?.id
    );
    const selectedTankIds = filter?.selectedTanks?.map((tank) =>
      tank?.id === "all" ? UIText.ALL : tank?.id
    );
    const selectedProducts = filter?.selectedProducts?.map((product) =>
      product?.id === "all" ? UIText.ALL : product?.text
    );
    const filteredProducts = getFilteredProducts(selectedProducts);
    const filteredTanks = getFilteredTanks(selectedTankIds);
    const dateSelected = fromDate && toDate;

    const valuesSelected = checkEODMandatoryValuesSelection(
      filter,
      filteredTanks,
      filteredProducts,
      dateSelected,
      selectedShipTo,
      selectedRegions,
      selectedTab
    );

    if (valuesSelected) {
      const payload = getEODFetchAPIRequestPayload(
        fromDate,
        toDate,
        selectedShipTo,
        filteredTanks,
        filteredProducts,
        selectedTab,
        currentCountryCode
      );
      setShowError(false);
      setSubmitLoading(true);
      if (!refresh) {
        setAppliedFilters(filter);
      }
      try {
        let response = await getSummaryResponse(payload);
        response.sort((a, b) => new Date(b.date) - new Date(a.date));
        response = formatEODResponseData(response);
        setSubmitLoading(false);
        setEodSummaryData(response);
        setOriginalData(response);
      } catch (error1) {
        triggerErrorNotification(
          UIText.Server_error_Unable_to_submit_the_request
        );
        setError(UIText.Server_error_Unable_to_submit_the_request);
        setSubmitLoading(false);
        setError("");
      }
    } else {
      setShowError(true);
    }
  };

  const showGridDataView = () => (
    <AppDataGrid
      resetIndex={resetIndex}
      setResetIndex={setResetIndex}
      take={DEFAULT_PAGE_SIZE}
      style={{ height: "58vh", margin: "0 0.5vw 0.5vw 0.5vw" }}
      pageable={true}
      filterable={true}
      data={eodSummaryData}
      columnsToShow={colsToShow(
        eodSummaryData,
        inputRef,
        handleDataChange,
        selectedItem,
        selectedTab,
        handleShipToLinkAction
      )}
      onItemChange={handleDataChange}
      sortable={true}
      ref={exportGridRef}
      exportFileName={UIText.EOD_Summary}
    />
  );

  const showTabContent = () => (
    <div>
      {showHeaderFilters()}
      {showHeaderButtons()}
      {showGridDataView()}
    </div>
  );

  const showTabComponent = () => {
    const items = [
      {
        label: UIText.Tank_View,
        key: EODViewOptions.TANK,
      },
      {
        label: UIText.Change_History,
        key: EODViewOptions.AUDIT,
      },
    ];
    return (
      <TabComponent
        items={items}
        activeIndex={selectedTab}
        onChange={(index) => {
          if (changed) {
            setIsPopupOpen(true);
          } else {
            setSelectedTab(index);
          }
        }}
      />
    );
  };

  const startDateInput = (propValues) => (
    <StartDateInput {...propValues} showError={showError} />
  );

  const endDateInput = (propValues) => (
    <EndDateInput {...propValues} showError={showError} />
  );

  const showHeaderFilters = () => {
    return (
      <div className="header-container">
        <div className="filters-container">
          {selectedTab === EODViewOptions.TANK && (
            <span className="filter-date">
              <DateRangePickerComponent
                defaultValue={filters.selectedDates}
                value={filters.selectedDates}
                startDateInput={startDateInput}
                endDateInput={endDateInput}
                onChange={onDateSelection}
                max={getDaysBeforeDate(NUMBERS.ONE)}
              />
            </span>
          )}
          {filters.regions?.length > NUMBERS.ONE && (
            <span className="filter">
              <label htmlFor="region">{UIText.Region}</label>
              <MultiSelectionDropDown
                data={filters.regions}
                values={regionSelectedValues}
                onChange={onRegionSelection}
                maxSelection={NUMBERS.ONE}
                filterable={false}
                showError={showError || !filterDataLoading}
                customStyle={{
                  width: isDachCluster() ? "9vw" : "7vw",
                  minHeight: "3vh",
                }}
              />
            </span>
          )}

          <span className="filter">
            <label htmlFor="shipTo">{UIText.ShipTo}</label>
            <MultiSelectionDropDown
              data={filters.allSiteIds}
              values={shipToSelectedValues}
              onChange={onShipToSelection}
              filterable={true}
              showError={showError || !filterDataLoading}
              customStyle={{ width: "7vw", minHeight: "3vh" }}
              maxSelection={NUMBERS.ONE}
            />
          </span>
          <span className="filter">
            <label htmlFor="siteName">{UIText.Site_Name}</label>
            <MultiSelectionDropDown
              data={filters.allSiteNames}
              values={siteNameSelectedValues}
              onChange={onSiteNameSelection}
              filterable={true}
              showError={showError || !filterDataLoading}
              customStyle={{
                width: selectedTab === EODViewOptions.TANK ? "13vw" : "15vw",
                minHeight: "3vh",
              }}
              maxSelection={NUMBERS.ONE}
            />
          </span>
          <span className="filter">
            <label htmlFor="obn">{UIText.OBN}</label>
            <MultiSelectionDropDown
              data={filters.allOBNs}
              values={obnSelectedValues}
              onChange={onOBNSelection}
              filterable={true}
              showError={showError || !filterDataLoading}
              customStyle={{ width: "6vw", minHeight: "3vh" }}
              maxSelection={NUMBERS.ONE}
            />
          </span>
          {selectedTab === EODViewOptions.TANK && (
            <span className="filter">
              <label htmlFor="materialNo">{UIText.Material_No}</label>
              <MultiSelectionDropDown
                data={filters.products}
                values={productSelectedValues}
                onChange={onProductSelection}
                filterable={false}
                placeholder={UIText.Select_dots}
                customStyle={{ width: "10vw", minHeight: "3vh" }}
              />
            </span>
          )}

          {selectedTab === EODViewOptions.TANK && (
            <span className="filter">
              <label htmlFor="tank">{UIText.Tank}</label>
              <MultiSelectionDropDown
                data={filters.tankGroup}
                values={tankSelectedValues}
                onChange={onTankSelection}
                filterable={false}
                placeholder={UIText.Select_dots}
                customStyle={{ width: "7vw", minHeight: "3vh" }}
              />
            </span>
          )}
        </div>
      </div>
    );
  };

  const showConfirmation = (responses) => {
    responses.forEach((item) => {
      const success =
        item?.statusCode >= NUMBERS.TWO_HUNDRED &&
        item?.statusCode < NUMBERS.THREE_HUNDRED;
      if (success) {
        triggerSuccessNotification(item?.value);
      } else {
        triggerErrorNotification(item?.value);
      }
    });
  };

  const saveChanges = async () => {
    setSubmitting(true);
    const payload = getEODSubmitApiPayload(changedItems, originalData);
    const endPoint = isBFXCluster() ? "UpdateEODData" : "UpdateEODDataDACH";
    const response = await reportsService.saveEodSummaryValues(
      payload,
      endPoint
    );
    if (
      response?.status >= NUMBERS.TWO_HUNDRED &&
      response?.status < NUMBERS.THREE_HUNDRED
    ) {
      setChanged(false);
      setDataUpdated(false);
      setChangedItems([]);
      showConfirmation(response?.data);
      fetchEodSummaryData();
    } else {
      triggerErrorNotification(UIText.Unable_to_submit_the_comments);
    }
    setSubmitting(false);
  };

  const cancelChanges = async () => {
    setEodSummaryData(originalData);
    setChanged(false);
    setDataUpdated(false);
    setChangedItems([]);
  };

  useEffect(() => {
    inputRef?.current?.focus();
    setTimeout(() => {
      inputRef?.current?.element?.setSelectionRange(
        cursorPosition,
        cursorPosition
      );
    }, NUMBERS.TEN);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eodSummaryData]);

  const handleDataChange = (props) => {
    const { dataItem, field, position } = props;
    const bfxCluster = isBFXCluster();
    setSelectedItem({ ...dataItem, editField: field });
    const changeItem = props?.dataItem;
    const actualItem = getActualItem(changeItem, originalData);

    const updatedItems = eodSummaryData?.map((item) => {
      const valuesSame =
        item.globalSiteId === dataItem.globalSiteId &&
        item.tank === dataItem.tank &&
        item.product === dataItem.product;
      if (
        item.rowKey === dataItem.rowKey &&
        item.date === dataItem.date &&
        valuesSame
      ) {
        const itemChanged = isItemChanged(actualItem, dataItem);

        if (itemChanged && !(dataItem?.remarks?.length > 0)) {
          dataItem.remarksError = true;
        } else {
          dataItem.remarksError = false;
        }

        return dataItem;
      }
      return item;
    });
    setEodSummaryData(updatedItems);

    const remarksUpdated = actualItem.remarks !== changeItem.remarks;
    let isChanged = false;
    const editableField = getEditableField(bfxCluster, field);

    if (editableField || field === "remarks") {
      const bfxValid = isBfxValid(actualItem, changeItem, remarksUpdated);
      const dachValid = isDachValid(actualItem, changeItem, remarksUpdated);
      const isUpdated = bfxCluster ? bfxValid : dachValid;

      setDataUpdated(isUpdated);

      const bfxChanged = isBfxFieldsChanged(actualItem, changeItem, dataItem);
      const dachChanged = isDachFieldsChanged(actualItem, changeItem, dataItem);
      isChanged = bfxCluster ? bfxChanged : dachChanged;

      setChanged(isChanged);
    }

    if (isChanged || remarksUpdated) {
      const sameItem = changedItems?.find((item) => {
        const valuesSame =
          item.globalSiteId === changeItem.globalSiteId &&
          item.product === changeItem.product &&
          item.tank === changeItem.tank;
        return (
          item.rowKey === changeItem.rowKey &&
          item.date === changeItem.date &&
          valuesSame
        );
      });
      if (sameItem) {
        const items = changedItems?.map((item) => {
          const valuesSame =
            item.globalSiteId === changeItem.globalSiteId &&
            item.product === changeItem.product &&
            item.tank === changeItem.tank;
          if (
            item.rowKey === changeItem.rowKey &&
            item.date === changeItem.date &&
            valuesSame
          ) {
            return changeItem;
          }
          return item;
        });
        setChangedItems(items);
      } else {
        setChangedItems([...changedItems, changeItem]);
      }
    } else {
      setChangedItems(
        changedItems?.filter((changedItem) => {
          const valuesSame =
            actualItem.globalSiteId === changedItem.globalSiteId &&
            actualItem.product === changedItem.product &&
            actualItem.tank === changedItem.tank;
          return !(
            actualItem.rowKey === changedItem.rowKey &&
            actualItem.date === changedItem.date &&
            valuesSame
          );
        })
      );
    }
    setCursorPosition(position);
  };

  const showHeaderButtons = () => {
    const saveColor = changed && "#0092a5";
    const cancelColor = dataUpdated && "#fbce07";
    const saveBorderWidth = changed && "0.15vw";
    const cancelBorderWidth = dataUpdated && "0.15vw";
    return (
      <div className="buttonsContainer">
        <div className="header-buttons">
          <span className="filter-button">
            <Button
              className="get-data-btn"
              onClick={() => {
                setChanged(false);
                setDataUpdated(false);
                setChangedItems([]);
                fetchEodSummaryData();
                setResetIndex(!resetIndex);
              }}
              disabled={filterDataLoading}
            >
              {filterDataLoading ? UIText.Fetching : UIText.Get_data}
            </Button>
          </span>
          <span className="filter-button">
            <Button
              className="refresh-btn"
              onClick={() => {
                fetchEodSummaryData(true);
              }}
              disabled={!appliedFilters}
            >
              {UIText.Refresh}
            </Button>
          </span>
          <span className="filter-button">
            <Button
              className="exportButton"
              onClick={exportData}
              disabled={exporting || !eodSummaryData?.length}
            >
              {exporting ? UIText.Exporting : UIText.Export}
            </Button>
          </span>
        </div>

        {selectedTab === EODViewOptions.TANK && (
          <div className="submitButtonContainer">
            <button
              title={UIText.Save}
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base toolbar-button"
              onClick={saveChanges}
              style={{ borderColor: saveColor, borderWidth: saveBorderWidth }}
              disabled={!changed}
            >
              {UIText.Save}
            </button>
            <button
              title={UIText.Cancel}
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base toolbar-button"
              onClick={cancelChanges}
              style={{
                borderColor: cancelColor,
                borderWidth: cancelBorderWidth,
              }}
              disabled={!dataUpdated}
            >
              {UIText.Cancel}
            </button>
          </div>
        )}
      </div>
    );
  };

  const handleShipToLinkAction = (siteId, country) => {
    setSelectedSite({ siteId: siteId, country: country });
    setShowSiteInfoModal(true);
  };

  const closeModal = () => {
    setShowSiteInfoModal(false);
  };

  return (
    <div className="eod-summary-screen-container data-grid">
      <div className="row ">
        {showTabComponent()}
        {error && <span>{error}</span>}
        <div className="data-grid-container">
          {showTabContent()}
          {isPopupOpen && (
            <AlertPopup
              isOpen={isPopupOpen}
              setIsPopupOpen={setIsPopupOpen}
              title={UIText.Information}
              message={UIText.You_have_unsaved_comments}
            />
          )}
          {showSiteInfoModal && (
            <SiteInfoScreen
              globalSiteId={selectedSite?.siteId}
              countryCode={currentCountryCode}
              close={closeModal}
            />
          )}
          {submitLoading && <LoadingPanel message={UIText.Fetching_the_data} />}
          {filterDataLoading && (
            <LoadingPanel message={UIText.Fetching_the_config_data} />
          )}
          {submitting && (
            <LoadingPanel message={UIText.Submitting_the_values} />
          )}
        </div>
      </div>
    </div>
  );
};

export { EODSummaryScreen };
