import "./report-list.scss";
import React, { useEffect, useState } from "react";
import { reportsService } from "../../../../../services/reports";
import { DatePicker, Button } from "@progress/kendo-react-all";
import { readOnlyUser } from "../../../../../services/utils";
import { NUMBERS, triggerErrorNotification } from "../common-utils";
import { UIText } from "../label-constants";
import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";

const IN_PROCESS_STATUS = "In Process";

const showReport = (
  reportsData,
  readOnly,
  reportLoading,
  setReportsData,
  handleSubmitAction,
  loading
) => {
  const handleStartDateChange = (e, report) => {
    const updateDate = new Date(e?.value);

    const updateReports = reportsData?.map((item) => {
      if (item?.reportCode === report?.reportCode) {
        return {
          ...item,
          restoreStartDate: updateDate,
        };
      }
      return item;
    });

    setReportsData(updateReports);
  };

  const handleEndDateChange = (e, report) => {
    const updateDate = new Date(e?.value);
    const updateReports = reportsData?.map((item) => {
      if (item?.reportCode === report?.reportCode) {
        return {
          ...item,
          restoreEndDate: updateDate,
        };
      }
      return item;
    });

    setReportsData(updateReports);
  };

  if (reportsData && reportsData.length > 0) {
    const max = new Date();
    return (
      <table className="report-table">
        <thead className="table-header">
          <tr className="header-row">
            <td style={{ minWidth: "20vw", width: "30vw" }}>
              {UIText.Report_Name}
            </td>
            <td style={{ minWidth: "6vw", width: "30vw" }}>
              {UIText.Allowed_Maximum_History_Data_Duration_Days}
            </td>
            <td style={{ minWidth: "6vw" }}>{UIText.Allowed_Duration_Days}</td>
            <td style={{ minWidth: "11vw" }}>{UIText.Start_Date}</td>
            <td style={{ minWidth: "11vw" }}>{UIText.End_Date}</td>
            <td style={{ minWidth: "10vw" }}>
              {UIText.Historical_Data_Status}
            </td>
            {!readOnly && <td style={{ minWidth: "6vw" }}></td>}
          </tr>
        </thead>
        <tbody className="table-body">
          {reportsData.map((report) => {
            return (
              <tr
                className="report-row"
                key={`${report?.reporName}-${report?.reportCode}`}
              >
                <td>{report?.reporName}</td>
                <td>{report?.maxHistoryDataLimitInDays}</td>
                <td>{report?.thresholdDays}</td>
                <td>
                  <DatePicker
                    disabled={
                      report?.status === IN_PROCESS_STATUS || reportLoading
                    }
                    placeholder="dd/MMM/yyyy"
                    format={"dd-MMM-yyyy"}
                    value={new Date(report?.restoreStartDate)}
                    max={max}
                    onChange={(e) => {
                      handleStartDateChange(e, report);
                    }}
                  />
                </td>
                <td>
                  <DatePicker
                    disabled={
                      report?.status === IN_PROCESS_STATUS || reportLoading
                    }
                    placeholder="dd/MMM/yyyy"
                    format={"dd-MMM-yyyy"}
                    max={max}
                    value={new Date(report?.restoreEndDate)}
                    onChange={(e) => {
                      handleEndDateChange(e, report);
                    }}
                  />
                </td>
                <td>{report?.status}</td>
                {!readOnly && (
                  <td>
                    <Button
                      className="update-btn"
                      disabled={
                        report?.status === IN_PROCESS_STATUS || reportLoading
                      }
                      onClick={() => handleSubmitAction(report)}
                    >
                      {UIText.Submit}
                    </Button>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
  if (!loading) {
    return (
      <div className="no-report-container">
        <div>{UIText.No_report_request_found}</div>
      </div>
    );
  }
  return <></>;
};

const ReportRequestScreen = () => {
  const [reportsData, setReportsData] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [reportLoading, setReportLoading] = useState(false);

  const [readOnly, setReadOnly] = useState(false);
  useEffect(() => {
    setReadOnly(readOnlyUser());
  }, []);

  const fetchReportData = async () => {
    try {
      const response = await reportsService.getRestoreData();
      setLoading(false);
      if (response) {
        setReportsData(response);
      } else {
        triggerErrorNotification(UIText.Unable_to_fetch_the_data);
      }
    } catch (error1) {
      triggerErrorNotification(
        UIText.Server_error_Unable_to_submit_the_request
      );
      setError(UIText.Server_error_Unable_to_submit_the_request);
      setLoading(false);
      setError("");
    }
  };

  useEffect(() => {
    fetchReportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitRequest = async (report) => {
    setReportLoading(true);
    try {
      const status = await reportsService.saveRestoreData(report);
      setReportLoading(false);
      if (status >= NUMBERS.TWO_HUNDRED && status < NUMBERS.THREE_HUNDRED) {
        const data = reportsData?.map((item) => {
          if (item?.reportCode === report?.reportCode) {
            return {
              ...item,
              status: IN_PROCESS_STATUS,
            };
          }
          return item;
        });
        setReportsData(data);
      } else {
        triggerErrorNotification(UIText.Unable_to_submit_the_request);
      }
    } catch (error1) {
      triggerErrorNotification(
        UIText.Server_error_Unable_to_submit_the_request
      );
      setReportLoading(false);
      setError(UIText.Server_error_Unable_to_submit_the_request);
      setError("");
    }
  };

  const handleSubmitAction = (report) => {
    const diffInMs =
      new Date(report?.restoreEndDate) - new Date(report?.restoreStartDate);
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    let message = "";
    if (diffInDays < 0) {
      message = UIText.End_Date_should_be_greater_than_Start_Date;
    } else if (diffInDays === 0) {
      message = UIText.End_Date_Start_Date_should_not_be_same;
    } else if (diffInDays > report?.maxHistoryDataLimitInDays) {
      message = UIText.Duration_should_not_be_greater_than_allowed_duration;
    }

    if (message?.length > 0) {
      triggerErrorNotification(message);
      return;
    }
    submitRequest(report);
  };

  return (
    <div className="container data-grid site-info-container">
      <div className="row ">
        {error && <span>{error}</span>}
        <div className="data-grid-container">
          {loading && (
            <LoadingPanel message={UIText.Fetching_the_data} />
          )}
          {showReport(
            reportsData,
            readOnly,
            reportLoading,
            setReportsData,
            handleSubmitAction,
            loading
          )}
          {reportLoading && (
            <LoadingPanel message={UIText.Submitting_the_values} />
          )}
        </div>
      </div>
    </div>
  );
};

export { ReportRequestScreen };
