import { useEffect, useState } from "react";
import { countryConfig } from "../../../../../services/country-config";
import { EditableDataGrid } from "../../../../components/editable-data-grid/editable-data-grid";
import { readOnlyUser } from "../../../../../services/utils";
import { UIText } from "../label-constants";
import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";
import {
  triggerErrorNotification,
  triggerSuccessNotification,
} from "../common-utils";

const CountryConfigScreen = () => {
  const [countryConfigData, setCountryConfigData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    setReadOnly(readOnlyUser());
  }, []);

  const getCountryConfig = async () => {
    const configData = await countryConfig.getCountryConfig();
    const data = JSON.parse(configData[0]["LeakDetection"]);
    setCountryConfigData([
      {
        Records: "Weekly Reconciliation Variance",
        Variance: data.WeeklyReconciliationVariance,
        unit: "Liter(s)/Day",
        key: "WeeklyReconciliationVariance",
      },
      {
        Records: "Monthly Reconciliation Variance",
        Variance: data.MonthlyReconciliationVariance,
        unit: "Liter(s)/Day",
        key: "MonthlyReconciliationVariance",
      },
      {
        Records: "High Water Level Threshold",
        Variance: data.HighWaterLevelThreshold,
        unit: "mm",
        key: "HighWaterLevelThreshold",
      },
      {
        Records: "Theft Loss Threshold (One time)",
        Variance: data.TheftLossThreshold,
        unit: "Liter(s)",
        key: "TheftLossThreshold",
      },
      {
        Records: "Theft Loss Period",
        Variance: data.TheftLossPeriod,
        unit: "Minutes",
        key: "TheftLossPeriod",
      },
      {
        Records: "Night Loss Threshold",
        Variance: data.NightLossThreshold,
        unit: "Liter(s)",
        key: "NightLossThreshold",
      },
      {
        Records: "Night Loss Period",
        Variance: data.NightLossPeriod,
        unit: "Minutes",
        key: "NightLossPeriod",
      },
      {
        Records: "Night Loss Occurrence Count",
        Variance: data.NightLossOccurrenceCount,
        unit: "(consecutive)",
        key: "NightLossOccurrenceCount",
      },
      {
        Records: "Tank Leak Rate Threshold",
        Variance: data.TankLeakRateThreshold,
        unit: "Liter(s)/Hr",
        key: "TankLeakRateThreshold",
      },
      {
        Records: "Tank Leak Occurrence Count",
        Variance: data.TankLeakOccurrenceCount,
        unit: "(consecutive)",
        key: "TankLeakOccurrenceCount",
      },
    ]);
    setLoading(false);
  };
  useEffect(() => {
    getCountryConfig();
  }, []);

  const customCell = (e) => {
    return (
      <td>
        <span>{e.props.children}</span>
      </td>
    );
  };

  const fieldsToShow = [
    { field: "Records", title: UIText.Records, filterable: false },
    {
      field: "Variance",
      title: UIText.Variance,
      filterable: false,
      editable: true,
      editor: "numeric",
    },
    { field: "unit", title: UIText.Unit, filterable: false },
  ];

  const handelUpdate = async (e) => {
    const fields = e.map((item) => ({
      [item.key]: item.Variance.toString(),
    }));
    const payload = {
      CountryCode: "DE",
      Fields: fields,
    };
    const res = await countryConfig.updateCountryConfig(payload);
    if (!res["Status"] || res["Status"] !== "Success") {
      triggerErrorNotification(UIText.Unable_to_update_the_records);
      return false;
    } else {
      triggerSuccessNotification(UIText.records_updated_successfully);
      return true;
    }
  };

  return (
    <div className="container data-grid">
      <div className="row ">
        <div className="data-grid-container">
          {loading && <LoadingPanel message={UIText.Fetching_the_data} />}
          <EditableDataGrid
            style={{ width: "100%", margin: "1% 0" }}
            onDataUpdate={handelUpdate}
            fieldsToShow={fieldsToShow}
            gridData={countryConfigData}
            customCell={customCell}
            nonEditable={readOnly}
          />
        </div>
      </div>
    </div>
  );
};

export { CountryConfigScreen };
