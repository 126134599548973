import "./product_list.scss";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { reportsService } from "../../../../../services/reports";
import { colsToShow } from "./product_list_utils";
import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";
import { getFormattedFullDate } from "../../../../../services/utils";
import { store } from "../../../../../state-management/store";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import { DEFAULT_PAGE_SIZE, triggerErrorNotification } from "../common-utils";
import { UIText } from "../label-constants";

const ProductListScreen = () => {
  const [resetIndex, setResetIndex] = useState(false);
  const [productsData, setProductsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const defaultCountry = useSelector(
    (state) => state?.reports?.selectedCountry
  );
  const [currentCountryCode, setCurrentCountryCode] = useState(defaultCountry);

  store.subscribe(() => {
    const { selectedCountry } = store.getState().reports;
    setCurrentCountryCode(selectedCountry);
  });

  useEffect(() => {
    fetchProductsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountryCode]);

  const fetchProductsData = async () => {
    setLoading(true);
    try {
      let response = await reportsService.fetchProductList(currentCountryCode);
      response?.sort((a, b) => a?.productCode - b?.productCode);
      response = response?.map((item) => {
        const timestamp =
          item?.timestamp &&
          getFormattedFullDate(new Date(item?.timestamp));
        return {
          ...item,
          timestamp,
        };
      });

      setProductsData(response);
    } catch (error1) {
      triggerErrorNotification(
        UIText.Server_error_Unable_to_submit_the_request
      );
    }
    setLoading(false);
  };

  return (
    <div className="message-tracker-screen-container data-grid">
      <div className="row ">
        <div className="data-grid-container">
          <AppDataGrid
            resetIndex={resetIndex}
            setResetIndex={setResetIndex}
            take={DEFAULT_PAGE_SIZE}
            style={{ height: "76.5vh" }}
            pageable={true}
            filterable={true}
            data={productsData}
            columnsToShow={colsToShow()}
            sortable={true}
            expandable={false}
            noRecordsMessage={UIText.No_data_found_filter_text}
          />
          {loading && <LoadingPanel message={UIText.Fetching_the_data} />}
        </div>
      </div>
    </div>
  );
};

export { ProductListScreen };
