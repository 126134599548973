import "./duplicate-deliveries.scss";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { reportsService } from "../../../../../services/reports";
import { Checkbox } from "@progress/kendo-react-inputs";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import { Button } from "@progress/kendo-react-all";
import { store } from "../../../../../state-management/store";
import {
  DEFAULT_PAGE_SIZE,
  NUMBERS,
  triggerErrorNotification,
  triggerSuccessNotification,
} from "../common-utils";
import { SiteInfoScreen } from "../out-of-tolerance-trend-analysis/out-of-tolerance-site-info";
import { getDeliveryColumns } from "./duplicate-deliveries-utils";
import { UIText } from "../label-constants";
import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";

const DuplicateDeliveriesScreen = () => {
  const [showSiteInfoModal, setShowSiteInfoModal] = useState(false);
  const [selectedSite, setSelectedSite] = useState();
  const [duplicateDeliveries, setDuplicateDeliveries] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const defaultCountry = useSelector(
    (state) => state?.reports?.selectedCountry
  );
  const [currentCountryCode, setCurrentCountryCode] = useState(defaultCountry);

  store.subscribe(() => {
    const { selectedCountry } = store.getState().reports;
    setCurrentCountryCode(selectedCountry);
  });

  useEffect(() => {
    fetchDuplicateDeliveries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountryCode]);

  const tankIdSort = (a, b) => {
    if (a?.TankId < b?.TankId) {
      return -1;
    }
    if (a?.TankId > b?.TankId) {
      return 1;
    }
    return 0;
  };

  const deliveryDateSort = (a, b) => {
    const date1 = a?.DeliveryDate;
    const date2 = b?.DeliveryDate;
    if (date1 < date2) {
      return 1;
    }
    if (date1 > date2) {
      return -1;
    }
    return 0;
  };

  const fetchDuplicateDeliveries = async () => {
    setDuplicateDeliveries([]);
    try {
      setLoading(true);
      const response = await reportsService.fetchDuplicateDeliveries(
        currentCountryCode
      );
      setLoading(false);
      if (
        response?.status >= NUMBERS.TWO_HUNDRED &&
        response?.status < NUMBERS.THREE_HUNDRED
      ) {
        const sortedDeliveries = response?.data?.map((item) => {
          item.Deliveries.sort(tankIdSort);
          return item;
        });
        sortedDeliveries?.sort(deliveryDateSort);
        setDuplicateDeliveries(sortedDeliveries);
      } else {
        triggerErrorNotification(UIText.Unable_to_fetch_the_data);
      }
    } catch (error1) {
      setLoading(false);
      setError("");
    }
  };

  const submitRequest = async (deliveries) => {
    setSubmitLoading(true);
    try {
      const response = await reportsService.submitDuplicateDeliveries(
        deliveries
      );
      setSubmitLoading(false);
      if (
        response?.status >= NUMBERS.TWO_HUNDRED &&
        response?.status < NUMBERS.THREE_HUNDRED
      ) {
        triggerSuccessNotification(UIText.Removed_successfully);
        fetchDuplicateDeliveries();
      } else {
        triggerErrorNotification(UIText.Unable_to_submit_the_request);
      }
    } catch (error1) {
      triggerErrorNotification(
        UIText.Server_error_Unable_to_submit_the_request
      );
      setSubmitLoading(false);
      setError(UIText.Server_error_Unable_to_submit_the_request);
      setError("");
    }
  };

  const ignoreRequest = async (deliveries) => {
    setSubmitLoading(true);
    try {
      const response = await reportsService.ignoreDuplicateDeliveries(
        deliveries
      );
      setSubmitLoading(false);
      if (
        response?.status >= NUMBERS.TWO_HUNDRED &&
        response?.status < NUMBERS.THREE_HUNDRED
      ) {
        triggerSuccessNotification(UIText.Processed_successfully);
        fetchDuplicateDeliveries();
      } else {
        triggerErrorNotification(UIText.Unable_to_submit_the_request);
      }
    } catch (error1) {
      triggerErrorNotification(
        UIText.Server_error_Unable_to_submit_the_request
      );
      setSubmitLoading(false);
      setError(UIText.Server_error_Unable_to_submit_the_request);
      setError("");
    }
  };

  const handleDeliverySelection = (selected, delivery, dataItem) => {
    const updatedData = duplicateDeliveries?.map((item) => {
      if (
        item?.ShipTo === dataItem?.ShipTo &&
        item?.ShipmentNumber === dataItem?.ShipmentNumber
      ) {
        const updatedDeliveries = item?.Deliveries?.map((deliveryItem) => {
          if (
            deliveryItem?.TankId === delivery?.TankId &&
            deliveryItem?.Volume === delivery?.Volume
          ) {
            deliveryItem.selected = selected;
          }
          return deliveryItem;
        });
        item.Deliveries = updatedDeliveries;
      }
      return item;
    });
    setDuplicateDeliveries(updatedData);
  };

  const deliveryCell = (props) => {
    const dataItem = props.dataItem;

    return (
      <td>
        {dataItem?.Deliveries?.map((delivery) => {
          const label = `${delivery?.TankId}(${delivery?.Material}) : ${delivery?.Volume} L`;
          return (
            <div className="delivery-cell-container" key={label}>
              <Checkbox
                label={label}
                checked={delivery?.selected}
                onChange={(event) => {
                  handleDeliverySelection(
                    event?.target?.value,
                    delivery,
                    dataItem
                  );
                }}
              />
            </div>
          );
        })}
      </td>
    );
  };

  const removeAction = (dataItem) => {
    const selectedItem = duplicateDeliveries?.find(
      (item) =>
        item?.ShipTo === dataItem?.ShipTo &&
        item?.ShipmentNumber === dataItem?.ShipmentNumber
    );
    const deliveries = selectedItem?.Deliveries?.filter(
      (delivery) => delivery?.selected
    );
    if (selectedItem && deliveries) {
      submitRequest([
        {
          ...selectedItem,
          Deliveries: deliveries,
        },
      ]);
    }
  };

  const ignoreAction = (dataItem) => {
    const selectedItem = duplicateDeliveries?.find(
      (item) =>
        item?.ShipTo === dataItem?.ShipTo &&
        item?.ShipmentNumber === dataItem?.ShipmentNumber
    );
    const deliveries = selectedItem?.Deliveries?.filter(
      (delivery) => delivery?.selected
    );
    if (selectedItem && deliveries) {
      ignoreRequest([
        {
          ...selectedItem,
          Deliveries: deliveries,
        },
      ]);
    }
  };

  const removeCell = (props) => {
    const dataItem = props.dataItem;
    const enabled = dataItem?.Deliveries?.find(
      (delivery) => delivery?.selected
    );
    return (
      <td>
        <Button
          className="update-btn"
          onClick={() => removeAction(dataItem)}
          disabled={!enabled}
        >
          {UIText.Remove}
        </Button>
        <Button className="ignore-btn" onClick={() => ignoreAction(dataItem)}>
          {UIText.No_Action_Needed}
        </Button>
      </td>
    );
  };

  const handleShipToLinkAction = (siteId, country) => {
    setSelectedSite({ siteId: siteId, country: country });
    setShowSiteInfoModal(true);
  };

  const closeModal = () => {
    setShowSiteInfoModal(false);
  };

  const showDuplicateDeliveries = () => {
    return (
      <div className="duplicate-deliveries-grid-container">
        <AppDataGrid
          style={{ height: "75.5vh" }}
          pageable={true}
          take={DEFAULT_PAGE_SIZE}
          filterable={true}
          expandable={false}
          sortable={true}
          data={duplicateDeliveries}
          columnsToShow={getDeliveryColumns(
            handleShipToLinkAction,
            deliveryCell,
            removeCell
          )}
          noRecordsMessage={UIText.No_duplicate_deliveries_found}
        />
      </div>
    );
  };

  return (
    <div className="container data-grid site-info-container">
      <div className="row ">
        {error && <span>{error}</span>}
        {loading && <LoadingPanel message={UIText.Fetching_the_data} />}

        <div className="data-grid-container">
          {showSiteInfoModal && (
            <SiteInfoScreen
              globalSiteId={selectedSite?.siteId}
              countryCode={currentCountryCode}
              close={closeModal}
            />
          )}
          {showDuplicateDeliveries()}
          {submitLoading && (
            <LoadingPanel message={UIText.Submitting_the_values} />
          )}
        </div>
      </div>
    </div>
  );
};

export { DuplicateDeliveriesScreen };
