import {
  DropDownList,
  DatePicker,
  Label,
  Button,
} from "@progress/kendo-react-all";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import { siteService } from "../../../../../services/sites";
import { manualDataService } from "../../../../../services/manual-data";
import { store } from "../../../../../state-management/store";
import { UIText } from "../label-constants";
import {
  NUMBERS,
  triggerErrorNotification,
  triggerInfoNotification,
  triggerSuccessNotification,
} from "../common-utils";

const DataGridContent = (props) => {
  const {
    gridData,
    handleDataChange,
    saveChanges,
    setGridData,
    originalGridData,
    selectionOk,
    selection,
    loading,
    colsToShow,
  } = props;

  return (
    <>
      <h5 className="manual-data-upload-table-header">
        {selectionOk
          ? `${UIText.Enter_Wet_Stock_Data_for_Site_ID}: ${selection.siteId} and Date: ${selection.formattedDate}`
          : UIText.Please_select_a_Site_ID_and_Date_manually}
      </h5>
      {loading && <h1>{UIText.Loading_please_wait}</h1>}
      {selectionOk && !loading && gridData && (
        <AppDataGrid
          onItemChange={handleDataChange}
          data={[...gridData]}
          columnsToShow={colsToShow}
        />
      )}
      <div className="manual-data-upload-table-buttons">
        <Button
          disabled={
            JSON.stringify(gridData) === JSON.stringify(originalGridData)
          }
          onClick={() => setGridData(originalGridData)}
          className="button manual-data-upload-table-buttons-reset"
        >
          {UIText.Reset}
        </Button>
        <Button
          disabled={
            JSON.stringify(gridData) === JSON.stringify(originalGridData)
          }
          onClick={saveChanges}
          className="button manual-data-upload-table-buttons-submit"
        >
          {UIText.Submit_and_Exit}
        </Button>
      </div>
    </>
  );
};

export const WetstockDataUpload = () => {
  const [selection, setSelection] = useState({
    siteId: null,
    date: null,
    formattedDate: null,
  });
  const defaultCountry = useSelector(
    (state) => state?.reports?.selectedCountry
  );
  const [currentCountryCode, setCurrentCountryCode] = useState(defaultCountry);

  const [gridData, setGridData] = useState([]);
  const [originalGridData, setOriginalGridData] = useState([]);

  const user = useSelector((state) => state?.user?.user);

  const [allSiteIds, setAllSiteIds] = useState([]);
  const [allSites, setAllSites] = useState([]);

  const [loading, setLoading] = useState(false);

  const colsToShow = [
    {
      title: UIText.Tank,
      field: "TankNo",
      editable: false,
    },
    {
      title: UIText.Fuel_Grade,
      field: "FuelGrade",
      editable: false,
    },
    {
      title: UIText.Opening_Stock,
      field: "OpeningStock",
      editable: false,
    },
    {
      title: UIText.Deliveries,
      field: "Deliveries",
      editable: true,
      editor: "numeric",
    },
    {
      title: UIText.Others,
      field: "Others",
      editable: true,
      editor: "numeric",
    },
    {
      title: UIText.Sales,
      field: "Sales",
      editable: true,
      editor: "numeric",
    },
    {
      title: UIText.Closing_Stock,
      field: "ClosingStock",
      editable: true,
      editor: "numeric",
    },
    {
      title: UIText.Variance,
      field: "Variance",
      editable: false,
    },
  ];

  store.subscribe(() => {
    const { selectedCountry } = store.getState().reports;
    setCurrentCountryCode(selectedCountry);
  });

  const getAllSiteIds = async () => {
    const sites = await siteService.fetchSiteIdListWithName(currentCountryCode);
    const temp = [...sites];
    setAllSiteIds(temp.map((site) => site.Id));
    setAllSites(sites);
  };

  useEffect(() => {
    getAllSiteIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountryCode]);

  const selectionOk = selection.formattedDate && selection.siteId;

  const handleSiteIdSelection = (e) => {
    setSelection((prevSelection) => {
      return {
        ...prevSelection,
        siteId: e.value,
      };
    });
  };

  const handleDateSelection = (e) => {
    const dateInstance = new Date(e.value);

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const month = (dateInstance.getMonth() + NUMBERS.ONE)
      .toString()
      .padStart(NUMBERS.TWO, "0");
    const date = dateInstance.getDate().toString().padStart(NUMBERS.TWO, "0");
    const year = dateInstance.getFullYear();

    const dateForPayload = `${year}-${month}-${date}`;
    setSelection((prevSelection) => {
      return {
        ...prevSelection,
        date: dateForPayload,
        formattedDate: `${date}-${months[dateInstance.getMonth()]}-${year}`,
      };
    });
  };

  const getSiteData = async () => {
    setLoading(true);
    const { siteId, date } = selection;
    const payload = {
      CountryCode: currentCountryCode,
      SiteId: siteId,
      Date: date,
    };
    const response = await manualDataService.fetchStockData(payload);
    if (response?.["Error"]) {
      triggerErrorNotification(`${response?.["Error"]}`);
      setLoading(false);
      return;
    }
    if (response?.["API Response"]) {
      triggerInfoNotification(`${response?.["API Response"]}`);
      setLoading(false);
      return;
    }
    const formattedData = response?.TankList?.map((item) => {
      item["Deliveries"] = 0;
      item["Others"] = 0;
      item["Sales"] = 0;
      item["ClosingStock"] = 0;
      item["Variance"] = 0;
      item["inEdit"] = true;
      return item;
    });
    setGridData(formattedData?.slice());
    setOriginalGridData(formattedData?.slice());
    setLoading(false);
  };

  const handleDataChange = (e) => {
    const newData = gridData.map((item) => {
      const temp = { ...item };
      if (temp.TankNo === e.dataItem.TankNo) {
        temp[e.field || ""] = e.value;
        temp.Variance =
          temp.ClosingStock - temp.OpeningStock - temp.Deliveries + temp.Sales;
      }
      return temp;
    });
    setGridData(newData);
  };

  const saveChanges = async () => {
    const gridDataForPayload = gridData.map((item) => {
      delete item.inEdit;
      return item;
    });
    const payload = {
      CountryCode: currentCountryCode,
      SiteId: selection.siteId,
      Date: selection.date,
      UserEmail: user?.UserEmail,
      Tanks: gridDataForPayload,
    };
    const response = await manualDataService.saveManualDataUploadChanges(
      payload
    );
    if (response?.["Status"] === "Success") {
      triggerSuccessNotification(UIText.Changes_Saved_Successfully);
      setGridData(originalGridData);
      return;
    }
  };

  return (
    <div className="container user-management-screen-container">
      <div className="row content-container">
        <div className="container data-grid">
          <div className="row ">
            <h4 className="title">{UIText.Manual_Data_Upload}</h4>
            <div className="data-grid-container manual-data-upload">
              <div className="col-xs-12 manual-data-upload-filters">
                <div className="col-xs-3 filter">
                  <Label>{UIText.Site_ID_Mandatory}</Label>
                  <DropDownList
                    data={allSiteIds}
                    onChange={handleSiteIdSelection}
                  />
                </div>
                <div className="col-xs-3 filter">
                  <Label>{UIText.Date_Mandatory}</Label>
                  <DatePicker onChange={handleDateSelection} />
                </div>
                <div className="col-xs-3 filter">
                  <Label>{UIText.Site_Name}</Label>
                  <p className="site-name">
                    {allSites.filter(
                      (site) => site.Id === selection.siteId
                    )[0]?.["Name"] || UIText.Please_select_a_site_id}
                  </p>
                </div>
                <div className="col-xs-3 filter">
                  <Button
                    disabled={!selectionOk || loading}
                    onClick={getSiteData}
                    className="button manual-data-upload-table-buttons-fetch-site-data"
                  >
                    {loading ? UIText.Loading : UIText.Fetch_site_data}
                  </Button>
                </div>
              </div>
              <div className="col-xs-12 manual-data-upload-table">
                <DataGridContent
                  gridData={gridData}
                  handleDataChange={handleDataChange}
                  saveChanges={saveChanges}
                  setGridData={setGridData}
                  originalGridData={originalGridData}
                  selectionOk={selectionOk}
                  selection={selection}
                  loading={loading}
                  colsToShow={colsToShow}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
