import calculateSize from "calculate-size";
import { CustomHeaderCell } from "../account-for-deliveries/account-for-deliveries-utils";
import { DECIMAL, NUMBERS } from "../common-utils";
import { Button } from "@progress/kendo-react-buttons";
import {
  ActionButtonCell,
  ActionType,
  MissingMandatoryCell,
  ShipToLinkCell,
} from "../common-grid-cells";
import { UIText } from "../label-constants";

export const EOMViewOptions = {
  TANK: NUMBERS.ZERO,
  AUDIT: NUMBERS.ONE,
};

export const isSame = (dataItem, selectedItem, field) => {
  const valuesSame =
    dataItem.product === selectedItem.product &&
    dataItem.tank === selectedItem.tank &&
    field === selectedItem?.editField;
  return (
    dataItem.date === selectedItem.date &&
    dataItem.globalSiteId === selectedItem.globalSiteId &&
    valuesSame
  );
};

export const calculateWidth = (field, data) => {
  let maxWidth = 0;
  data?.forEach((item) => {
    const fontSize = `${
      window.innerWidth * DECIMAL.POINT_ZERO_ONE_ZERO_FIVE
    }px`;
    // eslint-disable-next-line
    const size = calculateSize(item[field], {
      font: "Helvetica Neue",
      fontSize: fontSize,
    }); // pass the font properties based on the application
    if (size.width > maxWidth) {
      maxWidth = size.width;
    }
  });
  const minWidth = window.innerWidth * DECIMAL.POINT_ZERO_FIVE;
  return maxWidth < minWidth ? minWidth : maxWidth;
};

export const colsToShow = (
  handleShipToLinkAction,
  sendToGSAPAction,
  viewLogAction,
  showRegionColumn
) => {
  let columns = [
    {
      title: UIText.ShipTo,
      field: "globalSiteId",
      filterable: true,
      editable: false,
      sortable: true,
      cell: (props) => {
        return (
          <ShipToLinkCell {...props} linkAction={handleShipToLinkAction} />
        );
      },
    },
  ];
  if (showRegionColumn) {
    columns = [
      ...columns,
      {
        title: UIText.Region,
        field: "region",
        filterable: true,
        sortable: true,
      },
    ];
  }
  columns = [
    ...columns,
    {
      title: UIText.SLOC,
      field: "sloc",
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.OBN,
      field: "obn",
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.Plant,
      field: "plant",
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.Stocks,
      field: "totalStock",
      headerCell: CustomHeaderCell,
      filterable: true,
      sortable: true,
      cell: (props) => {
        return <MissingMandatoryCell {...props} />;
      },
    },
    {
      title: UIText.Actions,
      field: "",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * DECIMAL.POINT_ZERO_THREE,
      cell: (props) => {
        return (
          <ActionButtonCell
            {...props}
            shouldValidate={true}
            handleAction={sendToGSAPAction}
            title={UIText.Click_here_to_send_stocks_to_GSAP}
            actionType={ActionType.Send}
          />
        );
      },
    },
    {
      title: "",
      field: "",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * DECIMAL.POINT_ZERO_SIX,
      cell: (props) => {
        return (
          <ActionButtonCell
            {...props}
            shouldValidate={true}
            handleAction={viewLogAction}
            title={UIText.Click_here_to_view_logs}
            actionType={ActionType.Logs}
          />
        );
      },
    },
  ];
  return columns;
};

export const childColumnsToShow = () => {
  return [
    {
      title: UIText.Tank,
      field: "tankNumber",
      headerCell: CustomHeaderCell,
      filterable: false,
      editable: false,
      sortable: true,
    },
    {
      title: UIText.Tank_Group,
      field: "tankGroupId",
      headerCell: CustomHeaderCell,
      filterable: false,
      editable: false,
      sortable: true,
    },
    {
      title: UIText.Material_No,
      field: "materialNumber",
      filterable: false,
      editable: false,
      sortable: true,
      headerCell: CustomHeaderCell,
    },
    {
      title: UIText.Stocks,
      field: "currentStock",
      headerCell: CustomHeaderCell,
      filterable: false,
      sortable: true,
      cell: (props) => {
        return <MissingMandatoryCell {...props} />;
      },
    },
  ];
};

const showHeader = (props) => {
  const shipTo = props?.globalSiteId;
  return (
    <div className="deliveryHeader">
      <div className="title">{`${UIText.ShipTo}: ${shipTo}`}</div>
      <div className="close-btn-container">
        <Button
          className="close-btn"
          onClick={() => {
            props?.close();
          }}
        >
          {UIText.Close}
        </Button>
      </div>
    </div>
  );
};

const JSONCard = (props) => {
  const className = `card ${props?.cardClassName}`;
  return (
    <div>
      <div className={className}>
        <div className="card-header">{props?.title}</div>
        <div className="card-content">
          <pre>
            <code className="json-response">{props?.response}</code>
          </pre>
        </div>
      </div>
      {props?.children}
    </div>
  );
};

const renderCardJson = (content) => {
  return (
    <div className="card-container">
      <JSONCard title="Message" response={content?.Message} />
      <JSONCard
        title="Response"
        response={content?.ApiResponse}
        cardClassName="child-card"
      >
        {renderContentJson(content)}
      </JSONCard>
    </div>
  );
};

const JSONHeaderInfo = (props) => {
  return (
    <div key={props?.title} className="log-content">
      <span className="json-key">{props?.title}</span>
      <pre>
        <code className="json-response">{props?.response}</code>
      </pre>
    </div>
  );
};

const renderContentJson = (content) => {
  return (
    <div className="json-container">
      <JSONHeaderInfo title="MessageId" response={content?.MessageId} />
      <JSONHeaderInfo title="Timestamp" response={content?.Timestamp} />
    </div>
  );
};

export const LogDetails = (props) => {
  const content = props?.content;
  return (
    <div className="deliveryContainer">
      <div className="deliveryForm">
        {showHeader(props)}
        <div className="api-message-container">{renderCardJson(content)}</div>
      </div>
    </div>
  );
};
