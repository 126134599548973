import { store } from "../../../../../state-management/store";
import { CustomHeaderCell } from "../account-for-deliveries/account-for-deliveries-utils";
import {
  getDaysBeforeDate,
  getFormattedDate,
} from "../../../../../services/utils";
import {
  DECIMAL,
  getOOTASourceName,
  isBFXCluster,
  isDachCluster,
  NUMBERS,
} from "../common-utils";
import {
  getLastDates,
  getMaterials,
  getFilterRegions,
  getTanks,
  getYesOrNoValues,
  getSelectedRegions,
  getShipTos,
  getOBNs,
} from "../../reportUtils";
import {
  CommentHeaderCell,
  CommentsCell,
  DateHeaderCell,
  IconCell,
  MissingToleranceIconCell,
} from "./out-of-tolerance-trend-analysis-ui-components";
import { ShipToLinkCell } from "../common-grid-cells";
import { UIText } from "../label-constants";

export const DropDownSelection = {
  ShipTo: NUMBERS.ZERO,
  OBN: NUMBERS.ONE,
};
export const TrendViewMode = {
  MATERIAL: NUMBERS.ZERO,
  TANK: NUMBERS.ONE,
};

export const tankColsToShow = () => {
  const columns = [
    {
      title: UIText.Material_No,
      field: "materialNo",
    },
    {
      title: UIText.Product_Name,
      field: "additionalProductInfo",
    },
    {
      title: UIText.Product,
      field: "productCode",
    },
    {
      title: UIText.TG,
      field: "tankGroup",
    },
    {
      title: UIText.Tank,
      field: "tankName",
    },
  ];
  return columns;
};

const getShipToColumnWidth = (selectedMode) => {
  return selectedMode === TrendViewMode.MATERIAL
    ? window.innerWidth * DECIMAL.POINT_ZERO_FOUR
    : window.innerWidth * DECIMAL.POINT_ZERO_FIVE;
};

const getCountryColumnWidth = () => {
  return isBFXCluster()
    ? window.innerWidth * DECIMAL.POINT_ZERO_THREE
    : window.innerWidth * DECIMAL.POINT_ZERO_FOUR;
};

const getDateColumns = (trendAnalysisData, blankCount, columns) => {
  const data = trendAnalysisData?.[0];
  for (let index = 9; index >= 0; index--) {
    const item = data?.dates?.[index];
    const dateColumn = {
      title: item?.title || "||||||||",
      field: `day${index}`,
      sortable: false,
      headerCell: (props) => {
        return (
          <DateHeaderCell {...props} blankCount={blankCount} index={index} />
        );
      },
      cell: (props) => {
        return <IconCell {...props} index={index} />;
      },
    };
    columns.push(dateColumn);
  }
};

export const colsToShow = ({
  trendAnalysisData,
  commentsOptions,
  selectedMode,
  requestDate,
  blankCount,
  missingOutOfToleranceCount,
  handleShipToLinkAction,
  historicalViewEnabled,
  missingCommentsCount,
}) => {
  let columns = [
    {
      title: UIText.ShipTo,
      field: "shipTo",
      filterable: true,
      sortable: true,
      cell: (props) => {
        return (
          <ShipToLinkCell {...props} linkAction={handleShipToLinkAction} />
        );
      },
      className: selectedMode === TrendViewMode.TANK ? "commentsContainer" : "",
      width: getShipToColumnWidth(selectedMode),
    },
    {
      title: isBFXCluster() ? UIText.Country : UIText.Region,
      field: "region",
      headerCell: CustomHeaderCell,
      width: getCountryColumnWidth(),
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.SLOC,
      field: "sloc",
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.OBN,
      field: "obn",
      filterable: true,
      sortable: true,
    },
  ];

  columns =
    selectedMode === TrendViewMode.TANK
      ? [
          ...columns,
          {
            title: UIText.Tank_Group,
            field: "tankGroup",
            sortable: true,
          },
        ]
      : columns;

  columns = [
    ...columns,
    {
      title: UIText.Material_No,
      field: "materialNo",
      filterable: true,
      sortable: true,
      width:
        selectedMode === TrendViewMode.MATERIAL
          ? window.innerWidth * DECIMAL.POINT_ZERO_FOUR
          : window.innerWidth * DECIMAL.POINT_ZERO_FIVE,
    },
  ];

  getDateColumns(trendAnalysisData, blankCount, columns);

  columns = [
    ...columns,
    {
      title: UIText.Five_Days_Total,
      field: "fiveDaysTotal",
      className: "totalClass",
      sortable: true,
    },
    {
      title: UIText.Ten_Days_Total,
      field: "tenDaysTotal",
      className: "totalClass",
      sortable: true,
    },
  ];
  if (isBFXCluster()) {
    columns = [
      ...columns,
      {
        title: UIText.Three_zero_Days_Total,
        field: "thirtyDaysTotal",
        className: "totalClass",
      },
    ];
  }
  if (selectedMode === TrendViewMode.MATERIAL) {
    columns = [
      ...columns,
      {
        title: UIText.Comments,
        field: "comment",
        filterable: true,
        sortable: true,
        headerCell: (props) => {
          return (
            <CommentHeaderCell
              {...props}
              missingOutOfTolerance={missingOutOfToleranceCount}
              missingCommentsCount={missingCommentsCount}
            />
          );
        },
        cell: (props) => {
          return (
            <CommentsCell
              {...props}
              commentsOptions={commentsOptions}
              selectedMode={selectedMode}
              requestDate={requestDate}
              historicalViewEnabled={historicalViewEnabled}
            />
          );
        },
        width: window.innerWidth * DECIMAL.POINT_ONE,
      },
      {
        title: isDachCluster() ? UIText.One_day250 : UIText.One_day150,
        field: "oneDayGain",
        cell: MissingToleranceIconCell,
        filterable: true,
        sortable: true,
      },
      {
        title: isDachCluster()
          ? UIText.One_day_minus_250
          : UIText.One_day_minus_150,
        field: "oneDayLoss",
        cell: MissingToleranceIconCell,
        filterable: true,
        sortable: true,
      },
      {
        title: UIText.Five_Day_Cumul_250_minus_250,
        field: "isFiveDaysCumulative",
        headerCell: CustomHeaderCell,
        cell: MissingToleranceIconCell,
        filterable: true,
        sortable: true,
      },
      {
        title: UIText.Five_Day_Cumul_Percent,
        field: "isFiveDaysCumulativePercent",
        headerCell: CustomHeaderCell,
        cell: MissingToleranceIconCell,
        filterable: true,
        sortable: true,
      },
    ];
    if (isBFXCluster()) {
      columns = [
        ...columns,
        {
          title: UIText.Three_Zero_D_Cumul_750_minus_750,
          field: "isThirtyDaysCumulative",
          headerCell: CustomHeaderCell,
          cell: MissingToleranceIconCell,
          filterable: true,
          sortable: true,
        },
        {
          title: UIText.Three_Zero_D_Cumul_Percent,
          field: "isThirtyDaysCumulativePercent",
          headerCell: CustomHeaderCell,
          cell: MissingToleranceIconCell,
          filterable: true,
          sortable: true,
        },
      ];
    }
  }
  return columns;
};

export const getDefaultFilterOptions = (filterData) => {
  return {
    selectedDate: getLastDates(
      NUMBERS.ONE,
      isDachCluster() ? NUMBERS.TWO : NUMBERS.ONE
    ),
    selectedDateVal: null,
    selectedDates: {
      start: getDaysBeforeDate(isDachCluster() ? NUMBERS.TEN : NUMBERS.THIRTY),
      end: getDaysBeforeDate(NUMBERS.ONE),
    },
    dates: getLastDates(NUMBERS.FIVE, NUMBERS.ONE),
    selectedRegions: getSelectedRegions(filterData, false),
    regions: getFilterRegions(filterData),
    selectedShipTo: [],
    shipTos: [],
    selectedOBN: [],
    allOBNs: [],
    selectedTanks: [],
    tanks: getTanks(filterData),
    selectedMaterial: [],
    materials: getMaterials(filterData),
    selectedComments: [],
    comments: [],
    selectedDay250: [getYesOrNoValues()[0]],
    oneDayGain: getYesOrNoValues(),
    selectedOneDayMins: [getYesOrNoValues()[0]],
    oneDayLoss: getYesOrNoValues(),
    selectedFiveDCumulVar: [getYesOrNoValues()[0]],
    isFiveDaysCumulative: getYesOrNoValues(),
    selectedFiveDCumulTh: [getYesOrNoValues()[0]],
    isFiveDaysCumulativePercent: getYesOrNoValues(),
    selectedThirtyDaysCumul: [getYesOrNoValues()[0]],
    isThirtyDaysCumulative: getYesOrNoValues(),
    selectedThirtyDaysCumulPercentage: [getYesOrNoValues()[0]],
    isThirtyDaysCumulativePercent: getYesOrNoValues(),
  };
};

export const getOOTACountObj = () => {
  return {
    gainCount: NUMBERS.ZERO,
    lossCount: NUMBERS.ZERO,
    fiveCumVariance: NUMBERS.ZERO,
    fiveCumThruPut: NUMBERS.ZERO,
    thirtyCumVariance: NUMBERS.ZERO,
    thirtyCumThruPut: NUMBERS.ZERO,
  };
};
export const getOOTAFetchApiPayload = (
  filter,
  currentCountryCode,
  historicalViewEnabled,
  selectedTab
) => {
  const regions = filter.selectedRegions?.map((region) => region?.text);

  let payload = {
    countryCode: currentCountryCode,
    isHistoricalView: historicalViewEnabled,
    isMaterialView: selectedTab === TrendViewMode.MATERIAL,
    date: filter.selectedDate?.[0]?.text,
    regions: regions,
    shipTos: filter.selectedShipTo?.map((shipTo) => shipTo.text),
    tankIds:
      selectedTab === TrendViewMode.MATERIAL
        ? []
        : filter.selectedTanks?.map((tank) => tank.text),
    materialNumbers: filter.selectedMaterial?.map((material) => material.text),
    comment: filter.selectedComments?.map((comment) => comment.text),
    oneDayPlus: filter.selectedDay250?.[0]?.text,
    oneDayMinus: filter.selectedOneDayMins?.[0]?.text,
    fiveDaysCumul: filter.selectedFiveDCumulVar?.[0]?.text,
    fiveDaysCumulPercentage: filter.selectedFiveDCumulTh?.[0]?.text,
    thirtyDaysCumul: filter.selectedThirtyDaysCumul?.[0]?.text,
    thirtyDaysCumulPercentage:
      filter?.selectedThirtyDaysCumulPercentage?.[0]?.text,
    sourceName: getOOTASourceName(currentCountryCode),
  };

  if (historicalViewEnabled) {
    payload = {
      ...payload,
      date: getFormattedDate(filter?.selectedDateVal),
    };
  }
  return payload;
};

export const formatOOTAResponse = (
  response,
  currentCountryCode,
  setBlankCount,
  setMissingCommentsCount,
  setMissingOutOfToleranceCount
) => {
  response.sort((a, b) => a.materialNo - b.materialNo);
  response.sort((a, b) => a.sloc - b.sloc);
  response.sort((a, b) => a.shipTo - b.shipTo);

  const count = {};
  const missingCount = {};
  let updatedMissingCommentsCount = 0;
  const trendData = response?.map((dataItem) => {
    let disabled = false;
    if (!dataItem.comment) {
      updatedMissingCommentsCount += NUMBERS.ONE;
    }
    const datesArray = Object.fromEntries(
      dataItem?.dates.map((dateObj, index) => {
        let value = dateObj.value;
        if (index === NUMBERS.ZERO) {
          disabled = true;
        }
        if (!dateObj.value && dateObj.value !== NUMBERS.ZERO) {
          value = "";
          const current = count?.[`day${index}`] ? count?.[`day${index}`] : 0;
          count[`day${index}`] = current + NUMBERS.ONE;
        }
        if (dateObj?.missingOutOfTolerance) {
          const current = missingCount?.[`day${index}`]
            ? missingCount?.[`day${index}`]
            : 0;
          missingCount[`day${index}`] = current + NUMBERS.ONE;
        }
        value = value ? Math.round(value) : value;
        return [[`day${index}`], value];
      })
    );
    return {
      ...dataItem,
      ...datesArray,
      country: dataItem?.country || currentCountryCode,
      fiveDaysTotal: Math.round(dataItem?.fiveDaysTotal),
      tenDaysTotal: Math.round(dataItem?.tenDaysTotal),
      thirtyDaysTotal: Math.round(dataItem?.thirtyDaysTotal),
      disabled,
    };
  });
  setBlankCount(count);
  setMissingCommentsCount(updatedMissingCommentsCount);
  setMissingOutOfToleranceCount(missingCount);
  return trendData;
};

export const getOOTACountValues = (trendData) => {
  const counterItems = {
    gainCount: NUMBERS.ZERO,
    lossCount: NUMBERS.ZERO,
    fiveCumVariance: NUMBERS.ZERO,
    fiveCumThruPut: NUMBERS.ZERO,
    thirtyCumVariance: NUMBERS.ZERO,
    thirtyCumThruPut: NUMBERS.ZERO,
  };

  trendData?.forEach((item) => {
    if (item?.oneDayGain === "Y") {
      counterItems.gainCount += NUMBERS.ONE;
    }
    if (item?.oneDayLoss === "Y") {
      counterItems.lossCount += NUMBERS.ONE;
    }
    if (item?.isFiveDaysCumulative === "Y") {
      counterItems.fiveCumVariance += NUMBERS.ONE;
    }
    if (item?.isFiveDaysCumulativePercent === "Y") {
      counterItems.fiveCumThruPut += NUMBERS.ONE;
    }
    if (item?.isThirtyDaysCumulative === "Y") {
      counterItems.thirtyCumVariance += NUMBERS.ONE;
    }
    if (item?.isThirtyDaysCumulativePercent === "Y") {
      counterItems.thirtyCumThruPut += NUMBERS.ONE;
    }
  });
  return counterItems;
};

export const hasOOTAFilterEmptyValues = (filters) => {
  const selected1 =
    filters?.selectedDate?.length === NUMBERS.ZERO ||
    filters?.selectedRegions?.length === NUMBERS.ZERO ||
    filters?.selectedShipTo?.length === NUMBERS.ZERO;

  const selected2 =
    filters?.selectedMaterial?.length === NUMBERS.ZERO ||
    filters?.selectedComments?.length === NUMBERS.ZERO ||
    filters?.selectedDay250?.length === NUMBERS.ZERO;

  const selected3 =
    filters?.selectedOneDayMins?.length === NUMBERS.ZERO ||
    filters?.selectedFiveDCumulVar?.length === NUMBERS.ZERO ||
    filters?.selectedFiveDCumulTh?.length === NUMBERS.ZERO;

  const selected4 =
    filters?.selectedThirtyDaysCumul?.length === NUMBERS.ZERO ||
    filters?.selectedThirtyDaysCumulPercentage?.length === NUMBERS.ZERO;

  const emptySelection = selected1 || selected2 || selected3 || selected4;

  return emptySelection;
};

export const setOOTACommentOptions = (commentsOptions, setFilters) => {
  let comments = commentsOptions?.map((option) => ({
    id: option,
    text: option,
  }));
  comments = [{ id: "all", text: UIText.All }, ...comments];
  const selectedComment = comments?.[0];
  setFilters((prev) => {
    return {
      ...prev,
      comments: comments,
      selectedComments: selectedComment ? [selectedComment] : [],
    };
  });
};
export const setOOTADateAndRegions = (
  filterData,
  currentCountryCode,
  setFilters,
  setRegionSelectedValues
) => {
  const dates = getLastDates(NUMBERS.FIVE, NUMBERS.ONE);
  const selectedDate = getLastDates(
    NUMBERS.ONE,
    isDachCluster() ? NUMBERS.TWO : NUMBERS.ONE
  );
  const regions = getFilterRegions(filterData, true, currentCountryCode);
  const selectedRegions = regions?.length > NUMBERS.ZERO ? [regions?.[0]] : [];
  setFilters((prev) => {
    return {
      ...prev,
      regions,
      dates,
      selectedDate,
      selectedRegions,
    };
  });
  setRegionSelectedValues(selectedRegions);
};

export const setOOTAShipToAndOBN = (
  filters,
  filterData,
  setFilters,
  setShipToSelectedValues,
  setObnSelectedValues
) => {
  const shipTos =
    filters.selectedRegions?.length > NUMBERS.ZERO
      ? getShipTos(filterData, filters.selectedRegions)
      : [];
  const selectedShipTo =
    filters.selectedRegions?.length > NUMBERS.ZERO ? [shipTos?.[0]] : [];

  const obns =
    filters.selectedRegions?.length > NUMBERS.ZERO
      ? getOBNs(filterData, filters.selectedRegions, true)
      : [];

  const selectedOBN =
    filters.selectedRegions?.length > NUMBERS.ZERO &&
    obns?.length > NUMBERS.ZERO
      ? [obns?.[0]]
      : [];

  setFilters((prev) => {
    return {
      ...prev,
      shipTos,
      selectedShipTo,
      allOBNs: obns,
      selectedOBN,
    };
  });

  setShipToSelectedValues(selectedShipTo);
  setObnSelectedValues(selectedOBN);
};

export const setOOTAMaterialAndTank = (
  filters,
  filterData,
  setFilters,
  setMaterialSelectedValues,
  setTankSelectedValues
) => {
  const tanks =
    filters.selectedShipTo?.length > NUMBERS.ZERO
      ? getTanks(filterData, filters)
      : [];
  const selectedTanks = tanks?.length > NUMBERS.ZERO ? [tanks?.[0]] : [];
  const materials =
    filters.selectedShipTo?.length > NUMBERS.ZERO
      ? getMaterials(filterData, filters)
      : [];
  const selectedMaterial =
    materials?.length > NUMBERS.ZERO ? [materials[0]] : [];

  setFilters((prev) => {
    return {
      ...prev,
      materials,
      selectedMaterial,
      tanks,
      selectedTanks,
    };
  });
  setMaterialSelectedValues(selectedMaterial);
  setTankSelectedValues(selectedTanks);
};

export const handleOOTAGridDataChange = (
  changeItem,
  trendAnalysisData,
  originalData,
  changedItems,
  setTrendAnalysisData,
  setChangedItems
) => {
  const updatedItems = trendAnalysisData?.map((item, index) => {
    return index === changeItem.id ? changeItem : item;
  });
  setTrendAnalysisData(updatedItems);

  const actualItem = originalData?.find(
    (item) =>
      item.shipTo === changeItem.shipTo &&
      item.tank === changeItem.tank &&
      item.materialNo === changeItem.materialNo
  );

  if (actualItem.comment !== changeItem.comment) {
    const currentItems = changedItems?.filter(
      (item) =>
        !(
          item.shipTo === changeItem.shipTo &&
          item.tank === changeItem.tank &&
          item.materialNo === changeItem.materialNo
        )
    );
    setChangedItems([...currentItems, changeItem]);
  } else {
    const currentItems = changedItems?.filter(
      (item) =>
        !(
          item.shipTo === changeItem.shipTo &&
          item.tank === changeItem.tank &&
          item.materialNo === changeItem.materialNo
        )
    );
    setChangedItems(currentItems);
  }
};

export const getOOTAGridHeight = (selectedTab, historicalViewEnabled) => {
  if (selectedTab === TrendViewMode.MATERIAL) {
    return historicalViewEnabled ? "53vh" : "50vh";
  }
  return historicalViewEnabled ? "60vh" : "56vh";
};

export const getOOTATabItems = () => {
  return [
    {
      label: UIText.Material_View,
      key: TrendViewMode.MATERIAL,
    },
    {
      label: UIText.Tank_Group_View,
      key: TrendViewMode.TANK,
    },
  ];
};

export const getOOTASubmitPayload = (
  changedItems,
  currentCountryCode,
  filters
) => {
  return changedItems?.map((item) => ({
    CountryCode: currentCountryCode,
    Comment: item.comment,
    SiteId: item.shipTo,
    MaterialNo: item.materialNo,
    RequestDate: filters.selectedDate?.[0]?.date,
    UserEmail: store.getState()?.user?.user?.UserEmail,
  }));
};

export const handleOOTARowRender = (event, propValues) => {
  const rowData = propValues.dataItem;
  const backgroundColor = rowData?.selected ? "#eee" : "#fff";
  event.props.style.backgroundColor = backgroundColor;
  return event;
};

export const handleOBNValueChange = (
  filters,
  obnSelectedValues,
  setFilters,
  setShipToSelectedValues
) => {
  const siteData = filters?.allOBNs?.find(
    (siteInfo) => siteInfo?.id === obnSelectedValues?.[0]?.id
  );
  const site = filters?.shipTos?.find(
    (siteInfo) => siteInfo?.globalSiteId === siteData?.globalSiteId
  );
  const shipTo = site && siteData ? [site] : [];

  setFilters((prev) => {
    return {
      ...prev,
      selectedShipTo: shipTo,
      selectedOBN: obnSelectedValues || [],
    };
  });
  setShipToSelectedValues(shipTo);
};
export const handleOOTAShipToChange = (
  filters,
  shipToSelectedValues,
  setFilters,
  setObnSelectedValues,
  setMaterialSelectedValues
) => {
  const siteData = filters?.shipTos?.find(
    (siteInfo) => siteInfo?.id === shipToSelectedValues?.[0]?.id
  );
  const obn = filters?.allOBNs?.find(
    (siteInfo) => siteInfo?.obn === siteData?.obn
  );
  const obns = obn && siteData ? [obn] : [];

  setFilters((prev) => {
    return {
      ...prev,
      selectedShipTo: shipToSelectedValues || [],
      selectedOBN: obns,
    };
  });
  setObnSelectedValues(obns);
  setMaterialSelectedValues([]);
};

export const handleReportConfigChange = (reportConfig, setFilters) => {
  const startDate = new Date(reportConfig?.restoreStartDate);
  const endDate = new Date(reportConfig?.restoreEndDate);

  if (startDate && endDate) {
    setFilters((prev) => {
      return {
        ...prev,
        selectedDates: {
          start: startDate,
          end: endDate,
        },
        selectedDateVal: endDate,
      };
    });
  }
};
