import React, { useEffect, useState } from "react";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import { userService } from "../../../../../services/user";
import { DropdownFilterCell } from "../../../../components/dropdown-filter/dropdown-filter";
import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";
import { DEFAULT_PAGE_SIZE } from "../common-utils";
import "./user-management.scss";
import { UIText } from "../label-constants";

const UserAuditListScreen = () => {
  const [auditList, setAuditList] = useState([]);
  const [loading, setLoading] = useState(true);

  async function fetchUserAuditList() {
    const response = await userService.fetchAuditList();
    const data = response?.map((item) => {
      return {
        ...item,
        AccessControl: item?.AccessControl?.join(","),
      };
    });
    setAuditList(data ? data : []);
    setLoading(false);
  }

  useEffect(() => {
    fetchUserAuditList();
  }, []);

  const userRoles = Array.from(
    new Set(
      auditList.map((data) =>
        data.RoleName ? data.RoleName.toUpperCase() : ""
      )
    )
  );

  const userRoleFilterCell = (props) => {
    return (
      <DropdownFilterCell
        {...props}
        data={userRoles}
        defaultItem={UIText.Select_Role}
      />
    );
  };

  const colsToShow = [
    {
      title: UIText.First_Name,
      field: "UserFirstName",
      filterable: true,
      sortable: true,
      filter: "text",
    },
    {
      title: UIText.Last_Name,
      field: "UserLastName",
      filterable: true,
      sortable: true,
      filter: "text",
    },
    {
      title: UIText.Unique_User_ID,
      field: "UniqueUserId",
      sortable: true,
    },
    {
      title: UIText.User_Role,
      field: "RoleName",
      filterable: true,
      sortable: true,
      filterCell: userRoleFilterCell,
    },
    {
      title: UIText.Status,
      field: "IsActive",
      sortable: true,
    },
    {
      title: UIText.Access,
      field: "AccessControl",
      sortable: true,
    },
  ];

  return (
    <div className="container data-grid">
      <div className="row ">
        {loading && <LoadingPanel message={UIText.Fetching_the_data} />}
        <div className="data-grid-container">
          <AppDataGrid
            exportable={true}
            style={{ height: "71.5vh" }}
            take={DEFAULT_PAGE_SIZE}
            pageable={true}
            filterable={true}
            sortable={true}
            data={auditList}
            columnsToShow={colsToShow}
            exportFileName={UIText.User_Audit_List}
          />
        </div>
      </div>
    </div>
  );
};
export { UserAuditListScreen };
