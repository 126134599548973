import { NUMBERS } from "./components/common-utils";

export const getValueMap = (value, idGetter) => {
  const map = {};
  if (value?.length) {
    value.forEach((item) => {
      map[idGetter(item)] = true;
    });
  }
  return map;
};

export const getAppVersion = () => {
  const { REACT_APP_VERSION } = process.env;
  return REACT_APP_VERSION;
};

export const getAllSelectedOptions = (event, values) => {
  let selectedValues = values;
  const allOptionSelected = event.items?.filter((item) => {
    return item?.id?.toString()?.toLowerCase() === "all" && !item?.checkField;
  });
  if (event?.operation === "toggle" && allOptionSelected?.length > 0) {
    selectedValues = [];
  }
  if (event?.operation === "delete") {
    selectedValues = [];
  }
  return selectedValues;
};

export const getYears = (number) => {
  const years = [
    {
      id: "all",
      text: "All",
    },
  ];
  for (let index = 0; index < number; index++) {
    const year = `${new Date().getFullYear() - index}`;
    years.push({
      id: index,
      text: year,
    });
  }
  return years;
};

export const getThresholdYears = (number) => {
  const years = [];
  for (let index = 0; index < number; index++) {
    const year = `${new Date().getFullYear() - index}`;
    years.push({
      id: index,
      text: year,
    });
  }
  return years;
};

export const getYearsFrom = (fromYear) => {
  const years = [];
  const currentYear = new Date().getFullYear();
  let year = currentYear;
  while (year >= fromYear) {
    years.push({
      id: year,
      text: year,
    });
    year = year - NUMBERS.ONE;
  }
  return years;
};

const padWithZero = (number) => {
  return number < NUMBERS.TEN ? `0${number}` : number;
};

export const getYesterdayDate = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - NUMBERS.ONE);

  return yesterday;
};

export const getLastDates = (number, from = 0) => {
  const dates = [];
  for (let index = 0; index < number; index++) {
    const pos = index + from;
    const currentDate = new Date();
    const yesterdayDate = new Date(currentDate);
    yesterdayDate.setDate(currentDate.getDate() - pos);
    const month = padWithZero(yesterdayDate.getMonth() + NUMBERS.ONE);
    const day = padWithZero(yesterdayDate.getDate());
    const year = yesterdayDate.getFullYear();
    const date = `${month}/${day}/${year}`;

    if (pos === NUMBERS.TWO) {
      dates.push({
        id: pos,
        text: "Today-2",
        date: date,
        actualDate: yesterdayDate,
      });
    } else if (pos === NUMBERS.ONE) {
      dates.push({
        id: pos,
        text: "Today-1",
        date: date,
        actualDate: yesterdayDate,
      });
    } else {
      dates.push({
        id: pos,
        text: date,
        date: date,
        actualDate: yesterdayDate,
      });
    }
  }
  return dates;
};

export const getYesOrNoValues = () => {
  return [
    {
      id: "all",
      text: "All",
    },
    {
      id: "y",
      text: "Y",
    },
    {
      id: "n",
      text: "N",
    },
  ];
};

const getAllRegionShipTos = (filterData) => {
  let shipTos = [];
  filterData?.forEach((regionData) => {
    const currentShipTos = regionData?.sites?.map((site) => ({
      ...site,
      id: site?.globalSiteId,
      text: site?.globalSiteId,
      globalSiteId: site?.globalSiteId,
      localSiteId: site?.siteId,
      siteName: site?.name,
      region: regionData?.region,
      countryCode: site?.countryCode,
    }));
    shipTos = [...shipTos, ...currentShipTos];
  });
  return shipTos;
};

const getAllRegionSiteNames = (filterData) => {
  let shipTos = [];
  filterData?.forEach((regionData) => {
    const currentShipTos = regionData?.sites?.map((site) => ({
      id: site?.name,
      text: site?.name,
      globalSiteId: site?.globalSiteId,
      localSiteId: site?.localSiteId,
      siteName: site?.name,
      countryCode: site?.countryCode,
      siteStatus: site?.siteStatus,
      obn: site?.obn,
    }));
    shipTos = [...shipTos, ...currentShipTos];
  });
  return shipTos;
};

const getAllRegionOBNs = (filterData) => {
  let obns = [];
  filterData?.forEach((regionData) => {
    const currentOBNs = regionData?.sites?.map((site) => ({
      id: site?.obn,
      text: site?.obn,
      globalSiteId: site?.globalSiteId,
      localSiteId: site?.localSiteId,
      siteName: site?.name,
      countryCode: site?.countryCode,
      siteStatus: site?.siteStatus,
      obn: site?.obn,
    }));
    obns = [...obns, ...currentOBNs];
  });
  return obns;
};

export const addAllItem = (data, country) => {
  const items = data ? data : [];
  if (items?.length === NUMBERS.ONE) {
    return items;
  }
  let listItem = [
    {
      id: "all",
      text: "All",
      countryCode: country,
    },
    ...items,
  ];

  listItem = listItem.filter(
    (obj, index, self) => index === self.findIndex((item) => item.id === obj.id)
  );
  return listItem;
};

export const getSiteList = (filterData = [], regions = null) => {
  if (!regions || filterData?.length === 0) {
    return [];
  }

  let shipTos = [];
  if (regions?.[0]?.id === "all") {
    filterData?.forEach((regionData) => {
      const currentShipTos = regionData?.sites?.map((site) => site);
      shipTos = [...shipTos, ...currentShipTos];
    });
  } else {
    regions?.forEach((region) => {
      const regionData = filterData?.find(
        (item) => item?.region === region?.id
      );
      const regionsShipTos = regionData?.sites?.map((site) => site);
      shipTos = [...shipTos, ...regionsShipTos];
    });
  }
  return shipTos;
};

export const getShipTos = (
  filterData = [],
  regions = null,
  addAllOption = true
) => {
  if (!regions || filterData?.length === 0) {
    return [];
  }

  let shipTos = [];
  if (regions?.[0]?.id === "all") {
    shipTos = getAllRegionShipTos(filterData);
  } else {
    regions?.forEach((region) => {
      const regionData = filterData?.find(
        (item) => item?.region === region?.id
      );
      const regionsShipTos = regionData?.sites?.map((site) => ({
        ...site,
        id: site?.globalSiteId,
        text: site?.globalSiteId,
        globalSiteId: site?.globalSiteId,
        localSiteId: site?.siteId,
        siteName: site?.name,
        obn: site?.obn,
        plant: site?.plant,
        sloc: site?.sloc,
        isRKSite: site?.isRKSite,
        region: regionData?.region,
        countryCode: site?.countryCode,
      }));
      if (regionsShipTos) {
        shipTos = [...shipTos, ...regionsShipTos];
      }
    });
  }
  return addAllOption ? addAllItem(shipTos) : shipTos;
};

export const getSiteNames = (
  filterData = [],
  regions = null,
  addAllOption = true
) => {
  if (!regions || filterData?.length === 0) {
    return [];
  }

  let siteNames = [];
  if (regions?.[0].id === "all") {
    siteNames = getAllRegionSiteNames(filterData);
  } else {
    regions?.forEach((region) => {
      const regionData = filterData?.find(
        (item) => item?.region === region?.id
      );
      const regionsShipTos = regionData?.sites?.map((site) => ({
        id: site?.name,
        text: site?.name,
        globalSiteId: site?.globalSiteId,
        localSiteId: site?.localSiteId,
        siteName: site?.name,
        countryCode: site?.countryCode,
        siteStatus: site?.siteStatus,
      }));
      if (regionsShipTos instanceof Array) {
        siteNames = [...siteNames, ...regionsShipTos];
      }
    });
  }
  return addAllOption ? addAllItem(siteNames) : siteNames;
};

export const getOBNs = (
  filterData = [],
  regions = null,
  addAllOption = true
) => {
  if (!regions || filterData?.length === 0) {
    return [];
  }

  let obns = [];
  if (regions?.[0].id === "all") {
    obns = getAllRegionOBNs(filterData);
  } else {
    regions?.forEach((region) => {
      const regionData = filterData?.find(
        (item) => item?.region === region?.id
      );
      const regionsShipTos = regionData?.sites?.map((site) => ({
        id: site?.obn,
        text: site?.obn,
        globalSiteId: site?.globalSiteId,
        localSiteId: site?.localSiteId,
        siteName: site?.name,
        countryCode: site?.countryCode,
        siteStatus: site?.siteStatus,
        obn: site?.obn,
      }));
      if (regionsShipTos instanceof Array) {
        obns = [...obns, ...regionsShipTos];
      }
    });
  }
  return addAllOption ? addAllItem(obns) : obns;
};

export const getProducts = (filterDataItem, filters, addAllOption = true) => {
  const filterData = filterDataItem ? filterDataItem : [];
  if (
    !filters?.selectedSiteId ||
    !filters?.selectedRegions ||
    filterData?.length === 0
  ) {
    return [];
  }

  const allProducts = [
    {
      id: "all",
      text: "All",
    },
  ];
  if (filters.selectedSiteId === "all") {
    return allProducts;
  } else {
    const selectedSiteId = filters?.selectedSiteId?.id
      ? filters?.selectedSiteId?.id
      : filters?.selectedSiteId;
    const shipTos = getSiteList(filterData, filters?.selectedRegions);
    const siteData = shipTos?.find(
      (site) => site?.globalSiteId === selectedSiteId
    );
    const products = siteData?.materials?.map((product) => ({
      id: product?.materialNumber,
      text: product?.productName,
      idText: `${product?.materialNumber} | ${product?.productName}`,
      materialNumber: product?.materialNumber,
      productName: product?.productName,
      productCode: product?.productCode,
    }));
    return addAllOption ? addAllItem(products) : products;
  }
};
export const getTankListForMaterial = (filterDataItem, filters) => {
  const filterData = filterDataItem ? filterDataItem : [];
  if (
    !filters?.selectedShipTo ||
    !filters?.selectedRegions ||
    filterData?.length === 0
  ) {
    return [];
  }

  const shipTos = getSiteList(filterData, filters?.selectedRegions);
  const isAllShipTos = filters?.selectedShipTo?.[0]?.id === "all";
  let selectedShipTos = [];
  if (isAllShipTos) {
    selectedShipTos = shipTos;
  } else {
    filters?.selectedShipTo?.forEach((site) => {
      const siteData = shipTos?.find((item) => item?.globalSiteId === site?.id);
      selectedShipTos = [...selectedShipTos, siteData];
    });
  }
  let tankList = [];
  selectedShipTos?.forEach((siteData) => {
    filters?.selectedMaterial?.forEach((product) => {
      const filteredTanks = siteData?.tanks?.filter(
        (tank) => tank.productCode === product.productCode
      );
      const tanks = filteredTanks?.map((tank) => ({
        id: tank?.tankId,
        text: tank?.tankId,
        materialNumber: tank?.materialNumber,
        tankGroupId: tank?.tankGroupId,
        productCode: tank?.productCode,
      }));
      if (tanks) {
        tankList = [...tankList, ...tanks];
        tankList = removeDuplicates(tankList, "id");
      }
    });
  });
  return tankList;
};

const getTanksForProduct = (siteData, product) => {
  if (product?.id === "all") {
    return siteData?.tanks;
  } else {
    return siteData?.tanks?.filter(
      (tank) => tank.productCode === product.productCode
    );
  }
};

export const getTankGroupListForMaterial = (
  filterDataItem,
  filters,
  addAllOption
) => {
  const filterData = filterDataItem ? filterDataItem : [];
  if (
    !filters?.selectedShipTo ||
    !filters?.selectedRegions ||
    filterData?.length === 0
  ) {
    return [];
  }

  const shipTos = getSiteList(filterData, filters?.selectedRegions);
  const isAllShipTos = filters?.selectedShipTo?.[0]?.id === "all";
  let selectedShipTos = [];
  if (isAllShipTos) {
    selectedShipTos = shipTos;
  } else {
    filters?.selectedShipTo?.forEach((site) => {
      const siteData = shipTos?.find((item) => item?.globalSiteId === site?.id);
      selectedShipTos = [...selectedShipTos, siteData];
    });
  }
  let tankGroupList = [];
  selectedShipTos?.forEach((siteData) => {
    filters?.selectedProducts?.forEach((product) => {
      const filteredTanks = getTanksForProduct(siteData, product);
      const tanks = filteredTanks?.map((tank) => ({
        id: tank?.tankGroupId,
        text: tank?.tankGroupId,
        materialNumber: tank?.materialNumber,
        tankId: tank?.tankId,
        productCode: tank?.productCode,
      }));
      if (tanks) {
        tankGroupList = [...tankGroupList, ...tanks];
        tankGroupList = removeDuplicates(tankGroupList, "id");
      }
    });
  });
  return tankGroupList?.length > 0 && addAllOption
    ? addAllItem(tankGroupList)
    : tankGroupList;
};

export const getTankList = (filterDataItem, filters) => {
  const filterData = filterDataItem ? filterDataItem : [];
  if (
    !filters?.selectedShipTo ||
    !filters?.selectedRegions ||
    filterData?.length === 0
  ) {
    return [];
  }

  const shipTos = getSiteList(filterData, filters?.selectedRegions);
  const isAllShipTos = filters?.selectedShipTo?.[0]?.id === "all";
  let selectedShipTos = [];
  if (isAllShipTos) {
    selectedShipTos = shipTos;
  } else {
    filters?.selectedShipTo?.forEach((site) => {
      const siteData = shipTos?.find((item) => item?.globalSiteId === site?.id);
      selectedShipTos = [...selectedShipTos, siteData];
    });
  }
  let tankList = [];
  selectedShipTos?.forEach((siteData) => {
    filters?.selectedProducts?.forEach((product) => {
      if (product.id === "all") {
        const tanks = siteData?.tanks?.map((tank) => ({
          id: tank?.tankId,
          text: tank?.tankId,
          materialNumber: tank?.materialNumber,
          tankGroupId: tank?.tankGroupId,
          productCode: tank?.productCode,
        }));
        if (tanks) {
          tankList = [...tankList, ...tanks];
          tankList = removeDuplicates(tankList, "id");
        }
      } else {
        const filteredTanks = siteData?.tanks?.filter(
          (tank) => tank.productCode === product.productCode
        );
        const tanks = filteredTanks?.map((tank) => ({
          id: tank?.tankId,
          text: tank?.tankId,
          materialNumber: tank?.materialNumber,
          tankGroupId: tank?.tankGroupId,
          productCode: tank?.productCode,
        }));
        if (tanks) {
          tankList = [...tankList, ...tanks];
          tankList = removeDuplicates(tankList, "id");
        }
      }
    });
  });
  return tankList?.length > 0 ? addAllItem(tankList) : tankList;
};

export const getTankGroupWithTankList = (
  filterDataItem,
  filters,
  addAllOption = true
) => {
  const filterData = filterDataItem ? filterDataItem : [];
  if (
    !filters?.selectedShipTo ||
    !filters?.selectedRegions ||
    filterData?.length === 0
  ) {
    return [];
  }

  const allTanks = [
    {
      id: "all",
      text: "All",
    },
  ];
  if (filters.selectedShipTo === "all") {
    return allTanks;
  } else {
    const shipTos = getSiteList(filterData, filters?.selectedRegions);
    let tankList = [];
    filters?.selectedShipTo?.forEach((selectedSite) => {
      const siteData = shipTos?.find(
        (site) => site?.globalSiteId === selectedSite?.id
      );

      filters?.selectedProducts?.forEach((product) => {
        if (product.id === "all") {
          const tanks = siteData?.tanks?.map((tank) => ({
            id: tank?.tankGroupId,
            text: tank?.tankGroupId,
            materialNumber: tank?.materialNumber,
            tankId: tank?.tankId,
            productCode: tank?.productCode,
          }));
          if (tanks) {
            tankList = [...tankList, ...tanks];
          }
        } else {
          const filteredTanks = siteData?.tanks?.filter(
            (tank) => tank.productCode === product.productCode
          );
          const tanks = filteredTanks?.map((tank) => ({
            id: tank?.tankGroupId,
            text: tank?.tankGroupId,
            materialNumber: tank?.materialNumber,
            tankId: tank?.tankId,
            productCode: tank?.productCode,
          }));
          if (tanks) {
            tankList = [...tankList, ...tanks];
          }
        }
      });
    });

    return addAllOption && tankList?.length > 0
      ? addAllItem(tankList)
      : tankList;
  }
};

export const getTankGroupList = (
  filterDataItem,
  filters,
  addAllOption = true
) => {
  const filterData = filterDataItem ? filterDataItem : [];
  if (
    !filters?.selectedSiteId ||
    !filters?.selectedRegions ||
    filterData?.length === 0
  ) {
    return [];
  }

  const allTanks = [
    {
      id: "all",
      text: "All",
    },
  ];
  if (filters.selectedSiteId === "all") {
    return allTanks;
  } else {
    const shipTos = getSiteList(filterData, filters?.selectedRegions);
    const selectedSiteId = filters?.selectedSiteId?.id
      ? filters?.selectedSiteId?.id
      : filters?.selectedSiteId;
    const siteData = shipTos?.find(
      (site) => site?.globalSiteId === selectedSiteId
    );
    let tankList = [];

    filters?.selectedProducts?.forEach((product) => {
      if (product.id === "all") {
        const tanks = siteData?.tanks?.map((tank) => ({
          id: tank?.tankGroupId,
          text: tank?.tankGroupId,
          materialNumber: tank?.materialNumber,
          tankId: tank?.tankId,
          productCode: tank?.productCode,
        }));
        if (tanks) {
          tankList = [...tankList, ...tanks];
        }
      } else {
        const filteredTanks = siteData?.tanks?.filter(
          (tank) => tank.productCode === product.productCode
        );
        const tanks = filteredTanks?.map((tank) => ({
          id: tank?.tankGroupId,
          text: tank?.tankGroupId,
          materialNumber: tank?.materialNumber,
          tankId: tank?.tankId,
          productCode: tank?.productCode,
        }));
        if (tanks) {
          tankList = [...tankList, ...tanks];
        }
      }
    });

    if (filters?.selectedProduct) {
      const filteredTanks = siteData?.tanks?.filter(
        (tank) => tank.productCode === filters?.selectedProduct.productCode
      );
      const tanks = filteredTanks?.map((tank) => ({
        id: tank?.tankGroupId,
        text: tank?.tankGroupId,
        materialNumber: tank?.materialNumber,
        tankId: tank?.tankId,
        productCode: tank?.productCode,
      }));
      if (tanks) {
        tankList = [...tankList, ...tanks];
      }
    }
    return addAllOption ? addAllItem(tankList) : tankList;
  }
};

export const getMaterialsOfSite = (siteData) => {
  let materialsData = [];
  siteData?.materials?.forEach((material) => {
    if (material?.materialNumber?.length > 0) {
      const materialObj = {
        id: material?.materialNumber,
        text: `${material?.materialNumber} | ${material?.productName}`,
        productCode: material?.productCode,
      };
      materialsData = [...materialsData, materialObj];
    }
  });
  return materialsData;
};

const getTankGroupsOfSite = (siteData) => {
  const tankData = siteData?.tanks?.map((tank) => ({
    id: tank?.tankGroupId || tank?.tankId,
    text: tank?.tankGroupId || tank?.tankId,
    productCode: tank?.productCode,
  }));
  return tankData;
};

export const getTanks = (filterData, filters = false) => {
  if (!filters || filterData?.length === 0) {
    return [];
  }
  if (filters.selectedShipTo?.[0].id === "all") {
    return [
      {
        id: "all",
        text: "All",
      },
    ];
  }
  let regions = [];
  let tankData = [];
  if (filters?.selectedRegions?.[0]?.id === "all") {
    regions = filterData;
  } else {
    filters?.selectedRegions?.forEach((region) => {
      const regionData = filterData?.find(
        (item) => item?.region === region?.id
      );
      regions = [...regions, regionData];
    });
  }
  regions?.forEach((region) => {
    filters.selectedShipTo?.forEach((shipTo) => {
      const siteItem = region?.sites?.find(
        (site) => site.globalSiteId === shipTo?.id
      );
      const tanks = getTankGroupsOfSite(siteItem);
      if (Array.isArray(tanks)) {
        tankData = [...tankData, ...tanks];
      }
    });
  });
  return addAllItem(tankData);
};

const removeDuplicates = (array, key) => {
  const uniqueMap = {};
  return array.reduce((result, item) => {
    if (!uniqueMap[item[key]]) {
      uniqueMap[item[key]] = true;
      result.push(item);
    }
    return result;
  }, []);
};

export const getMaterials = (
  filterData,
  filters = false,
  addAllOption = true
) => {
  if (!filters || !filterData || filterData?.length === 0) {
    return [];
  }
  const materialsData = onGetMaterials(filters, filterData);
  materialsData.sort((a, b) => a.text - b.text);
  return materialsData?.length > 0 && addAllOption
    ? addAllItem(materialsData)
    : materialsData;
};

export const getRegions = (masterData) => {
  const regions = masterData?.map((region) => ({
    id: region?.region,
    text: region?.region,
    countryCode: region?.countryCode,
  }));
  return regions || [];
};

export const getRegionOptions = (filterData = []) => {
  const regions = filterData?.map((item) => ({
    id: item?.toUpperCase(),
    text: item?.toUpperCase(),
  }));
  return addAllItem(regions);
};
export const getSelectedRegions = (filterDataItem, isAddAll, country) => {
  const filterData = filterDataItem ? filterDataItem : [];
  const addAll = isAddAll ? isAddAll : true;
  const regions = getFilterRegions(filterData, addAll, country);
  return regions?.length > 0 ? [regions?.[0]] : [];
};

export const getFilterRegions = (filterDataItem, isAddAll, country) => {
  const filterData = filterDataItem ? filterDataItem : [];
  const addAll = isAddAll ? isAddAll : true;
  const regions = filterData?.map((item) => ({
    id: item?.region?.toUpperCase(),
    text: item?.region?.toUpperCase(),
    countryCode: item?.countryCode,
  }));
  return addAll && regions?.length > NUMBERS.ONE
    ? addAllItem(regions, country)
    : regions;
};

export const getMonthsData = (
  selectedYear,
  filters,
  allowCurrentMonth = true
) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const months = filters?.months?.map((month) => {
    return selectedYear === currentYear
      ? {
          ...month,
          disabled: allowCurrentMonth
            ? month.id > currentMonth + NUMBERS.ONE
            : month.id > currentMonth,
        }
      : {
          ...month,
          disabled: false,
        };
  });

  return months;
};

export const getMonths = () => {
  return [
    {
      id: NUMBERS.ONE,
      text: "Jan",
    },
    {
      id: NUMBERS.TWO,
      text: "Feb",
    },
    {
      id: NUMBERS.THREE,
      text: "Mar",
    },
    {
      id: NUMBERS.FOUR,
      text: "Apr",
    },
    {
      id: NUMBERS.FIVE,
      text: "May",
    },
    {
      id: NUMBERS.SIX,
      text: "Jun",
    },
    {
      id: NUMBERS.SEVEN,
      text: "Jul",
    },
    {
      id: NUMBERS.EIGHT,
      text: "Aug",
    },
    {
      id: NUMBERS.NINE,
      text: "Sep",
    },
    {
      id: NUMBERS.TEN,
      text: "Oct",
    },
    {
      id: NUMBERS.ELEVEN,
      text: "Nov",
    },
    {
      id: NUMBERS.TWELVE,
      text: "Dec",
    },
  ];
};

export const SCREEN_IDS = {
  USER_MANAGEMENT: "user-mgmt",
  USER_AUDIT_LIST: "user-mgmt-audit-logs",
  USER_LOGIN_HISTORY: "user-mgmt-login-history",
  USER_ONBOARDING: "user-mgmt-manage",
  EDIT_USERS: "user-mgmt-edit",
  REVIEW_GSAP_DUPLICATES: "user-mgmt-duplicate-deliveries",
  PRE_POSTING_ACTIVITIES: "misc-pre-posting-activities",
  ISSUE_LOG_REPORT: "issue-log-report",
  SITE_INFORMATION: "site-info-manage",
  COUNTRY_CONFIGURATION: "country-config",
  VARIANCE_CONFIGURATION: "country-config-variance",
  WETSTOCK_DATA_UPLOAD: "misc-wetstock-data-upload",
  HISTORY_REQUEST_PAGE: "historical-report-manage",
  TANK_THRESHOLD_CONFIGURATION: "leak-detection-tank-threshold-config",
  TANK_THRESHOLD_RESULTS: "leak-detection-tank-threshold-result",
  EOD_SUMMARY: "eod-data-corrections-summary",
  EOM_SUMMARY: "monthly-stock-levels",
  OUT_OF_TOLERANCE_SUMMARY: "cwr-daily-oot-summary",
  PUMP_REGISTER_REPORT: "pump-register",
  PUMP_REGISTER: "pump-register-summary",
  MONTHLY_EXCEPTION: "pump-register-montly-exception",
  OUT_OF_TOLERANCE_TREND_ANALYSIS: "cwr-daily-oot-trend-analysis",
  ACCOUNTING_FOR_DELIVERIES: "cwr-daily-acc-for-deliveries",
  ACCOUNTING_FOR_DELIVERIES_NEW: "cwr-daily-acc-for-deliveries",
  FIFTEEN_MINS_RECONCILIATION_REPORT: "leak-detection-recon-15-mins-report",
  DAILY_RECONCILIATION_REPORT: "leak-detection-recon-daily-recon-report",
  LEAK_DETECTION: "leak-detection",
  RECONCILIATION_REPORTS: "leak-detection-recon",
  GAIN_LOSS_OVERVIEW: "cwr-daily-gain-loss-overview",
  SITE_SUMMARY: "site-info-summary",
  HISTORY: "historical-report",
  HISTORY_GAIN_LOSS: "historical-report-gain-loss",
  OUT_OF_TOLERANCE_COMMENTS: "cwr-daily-oot-comments",
  HISTORY_OUT_OF_TOLERANCE_COMMENTS: "historical-report-oot-comments",
  SITE_INFORMATION_MODULE: "site-info",
  CWR_DAILY: "cwr-daily",
  CWR_DAILY_OOT: "cwr-daily-oot",
  MESSAGE_TRACKER_DELIVERIES: "msg-tracker-1",
  MISC: "misc",
  FAILED_DELIVERIES: "failed-deliveries",
  MANAGE_PRODUCTS: "manage-products",
};
function onGetMaterials(filters, filterData) {
  let materialsData = [];
  let regions = [];
  if (filters?.selectedRegions?.[0]?.id === "all") {
    regions = filterData;
  } else {
    filters?.selectedRegions?.forEach((region) => {
      const regionData = filterData?.find(
        (item) => item?.region === region?.id
      );
      regions = [...regions, regionData];
    });
  }
  regions?.forEach((region) => {
    if (filters?.selectedShipTo?.[0]?.id === "all") {
      region?.sites?.forEach((siteItem) => {
        const materials = getMaterialsOfSite(siteItem);
        if (Array.isArray(materials)) {
          materialsData = [...materialsData, ...materials];
          materialsData = removeDuplicates(materialsData, "id");
        }
      });
    } else {
      filters?.selectedShipTo?.forEach((shipTo) => {
        const siteItem = region?.sites?.find(
          (site) =>
            site.globalSiteId === shipTo?.id || site.globalSiteId === shipTo
        );
        const materials = getMaterialsOfSite(siteItem);
        if (Array.isArray(materials)) {
          materialsData = [...materialsData, ...materials];
          materialsData = removeDuplicates(materialsData, "id");
        }
      });
    }
  });
  return materialsData;
}
