import { useEffect, useState } from "react";
import {
  getFormattedDate,
  getFormattedDateAndTime,
} from "../../../../../services/utils";
import { store } from "../../../../../state-management/store";
import { reportsService } from "../../../../../services/reports";
import { TextInlineEditCell } from "../../../../components/text-edit-cell/text-inline-edit-cell";
import { detailColsToShow } from "./account-for-deliveries-utils";
import { InlineEditGrid } from "../../../../components/data-grid/inline-data-grid";
import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";
import { UIText } from "../label-constants";
import {
  NUMBERS,
  triggerErrorNotification,
  triggerSuccessNotification,
} from "../common-utils";

const AFDDetailsController = (props) => {
  const dataItem = props?.dataItem;
  const [tankDetails, setTankDetails] = useState([]);
  const [deliveries, setDeliveries] = useState([]);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    const updatedDeliveries = dataItem?.deliveries?.map((item) => {
      const startTemperature =
        item?.startTemperature === NUMBERS.ZERO || item?.startTemperature
          ? `${item?.startTemperature}°C`
          : "";
      const endTemperature =
        item?.endTemperature === NUMBERS.ZERO || item?.endTemperature
          ? `${item?.endTemperature}°C`
          : "";
      return {
        ...item,
        startTemperature: startTemperature,
        endTemperature: endTemperature,
        inEdit: false,
        title: UIText.Edit,
        subTitle: " ",
        saveEnabled:
          dataItem?.outBoundDeliveryId?.toString()?.length > NUMBERS.ZERO &&
          dataItem?.confirmedDelivery?.toString()?.length > NUMBERS.ZERO,
      };
    });

    setDeliveries(updatedDeliveries);
    setTankDetails(updatedDeliveries);
  }, [dataItem]);

  const handleDeliveryEdit = (_, childItem) => {
    const pumpList = tankDetails?.map((delivery) => {
      if (delivery.rowKey === childItem.rowKey) {
        return {
          ...delivery,
          inEdit: true,
          title: UIText.Save,
          subTitle: UIText.Cancel,
        };
      } else {
        return {
          ...delivery,
          inEdit: false,
          title: UIText.Edit,
          subTitle: "",
        };
      }
    });
    setTankDetails(pumpList);
  };

  const handleDeliveryCancel = (_, childItem) => {
    const tankDeliveries = tankDetails?.map((delivery) => {
      if (delivery.rowKey === childItem.rowKey) {
        const actualDelivery = deliveries?.find(
          (item) => item.rowKey === delivery.rowKey
        );
        return actualDelivery;
      }
      return delivery;
    });
    setTankDetails(tankDeliveries);
  };

  const getDateTime = (documentDate, dateVal) => {
    const date = new Date(dateVal);
    const defaultValue = new Date();
    defaultValue.setHours(
      NUMBERS.ZERO,
      NUMBERS.ZERO,
      NUMBERS.ZERO,
      NUMBERS.ZERO
    );
    const deliveryStartDate = documentDate
      ? new Date(documentDate)
      : defaultValue;
    deliveryStartDate.setHours(date ? date?.getHours() : NUMBERS.ZERO);
    deliveryStartDate.setMinutes(date ? date?.getMinutes() : NUMBERS.ZERO);
    const dateTime = getFormattedDateAndTime(deliveryStartDate);
    return dateTime;
  };

  const handleDeliverySave = async (rowItem, childItem) => {
    //Start Time
    const startDate = childItem?.deliveryStartDate;
    const deliveryStartTime =
      startDate instanceof Date || startDate?.length > NUMBERS.ZERO
        ? getDateTime(rowItem?.documentDate, childItem?.deliveryStartDate)
        : null;

    //End Time..
    const endDate = childItem?.deliveryStartDate;
    const deliveryEndTime =
      endDate instanceof Date || endDate?.length > NUMBERS.ZERO
        ? getDateTime(rowItem?.documentDate, childItem?.deliveryEndDate)
        : null;

    const payload = {
      countryCode: rowItem?.countryCode,
      rowKey: childItem.rowKey,
      shipTo: rowItem?.shipTo,
      materialNumber: rowItem.materialNumber,
      tankId: childItem?.tank,
      outBoundDeliveryId: childItem?.outBoundDeliveryId,
      documentDate: rowItem?.documentDate,
      reportDate: getFormattedDate(new Date()),
      calculatedDelivery: childItem?.calculatedDelivery,
      confirmedDelivery: childItem?.confirmedDelivery,
      difference: childItem?.difference,
      deliveryStartTime: deliveryStartTime,
      deliveryEndTime: deliveryEndTime,
      startTemperature:
        childItem?.startTemperature?.length > NUMBERS.ZERO
          ? parseFloat(childItem?.startTemperature)
          : null,
      endTemperature:
        childItem?.endTemperature?.length > NUMBERS.ZERO
          ? parseFloat(childItem?.endTemperature)
          : null,
      userName: store.getState().user?.user?.UserEmail,
    };
    setUpdating(true);
    const response = await reportsService.addAFDDelivery(payload);
    if (response?.data?.isSuccess) {
      props?.fetchAccountForDeliveriesReport();
      triggerSuccessNotification(
        response?.message ||
          response?.data?.message ||
          UIText.Delivery_updated_successfully
      );
    } else {
      const tankDeliveries = tankDetails?.map((delivery) => {
        if (delivery.rowKey === childItem.rowKey) {
          const actualDelivery = deliveries?.find(
            (item) => item.rowKey === delivery.rowKey
          );
          return actualDelivery;
        }
        return delivery;
      });
      setTankDetails(tankDeliveries);
      triggerErrorNotification(
        response?.message ||
          response?.data?.message ||
          UIText.Delivery_update_failed
      );
    }
    setUpdating(false);
  };
  const textValidator = (value, message) => {
    if (value === null || value?.length === NUMBERS.ZERO) {
      return message;
    }
    return "";
  };
  const handleItemChange = (e) => {
    const childItem = e.dataItem;
    const pumpList = tankDetails?.map((delivery) => {
      if (delivery.rowKey === childItem.rowKey) {
        const confirmedDeliveryValid = textValidator(
          e.field === "confirmedDelivery"
            ? e.value
            : delivery?.confirmedDelivery,
          UIText.Confirmed_Delivery_is_required
        );
        delivery["confirmedDeliveryValid"] = confirmedDeliveryValid;

        const outBoundDeliveryIdValid = textValidator(
          e.field === "outBoundDeliveryId"
            ? e.value
            : delivery?.outBoundDeliveryId,
          UIText.Outbound_Delivery_is_required
        );

        delivery["outBoundDeliveryIdValid"] = outBoundDeliveryIdValid;

        const saveEnabled = !(
          delivery?.confirmedDeliveryValid?.length > NUMBERS.ZERO ||
          delivery?.outBoundDeliveryIdValid?.length > NUMBERS.ZERO
        );

        return {
          ...delivery,
          saveEnabled: saveEnabled,
          [e.field]: e.value,
        };
      }
      return delivery;
    });
    setTankDetails(pumpList);
  };

  return (
    <div className="container-fluid">
      <div className="openingHoursContainer">
        <InlineEditGrid
          style={{ maxHeight: "50vh", borderColor: "#b8e2ea" }}
          take={10000000}
          pageable={false}
          filterable={true}
          expandable={false}
          sortable={true}
          data={tankDetails}
          columnsToShow={detailColsToShow({
            rowItem: dataItem,
            onEdit: handleDeliveryEdit,
            onSave: handleDeliverySave,
            onCancel: handleDeliveryCancel,
          })}
          onItemChange={handleItemChange}
          cells={{
            edit: {
              text: TextInlineEditCell,
            },
          }}
        />
      </div>
      {updating && <LoadingPanel message={UIText.Updating_the_values} />}
    </div>
  );
};

export default AFDDetailsController;
