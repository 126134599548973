import React from "react";
import { ProductListScreen } from "./product_list/product_list_screen";

const ProductListContainer = () => {
  return (
    <>
      <div className="container user-management-screen-container eod-container">
        <div className="row content-container">
          <ProductListScreen />
        </div>
      </div>
    </>
  );
};

export { ProductListContainer};
