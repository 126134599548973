import { DropDownList, Input, Label } from "@progress/kendo-react-all";
import { RadioGroup } from "@progress/kendo-react-inputs";
import React, { useState } from "react";
import { useEffect } from "react";
import { siteService } from "../../../../../services/sites";
import { userService } from "../../../../../services/user";
import { MultiSelectTreeDropdown } from "../../../../components/multi-select-tree/main";
import {
  NUMBERS,
  USER_ROLES,
  getAllClusterCountries,
  triggerErrorNotification,
  triggerSuccessNotification,
} from "../common-utils";
import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";
import { UIText } from "../label-constants";

/**
 * This function returns a message when there is no site ids.
 */
const NoSiteIds = (props) => {
  const { values, siteIdsList } = props;
  return (
    <div className="k-nodata">
      <h4>
        {!values.country && UIText.Please_select_a_country}
        {values.country &&
          (siteIdsList.length
            ? UIText.NO_DATA_FOUND
            : `Fetching ${values.country} site ids...`)}
      </h4>
    </div>
  );
};

const UserOnboardingScreen = () => {
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [step, setStep] = useState(NUMBERS.ONE);
  const [userRoles, setUserRoles] = useState([]);
  const [siteIdsList, setSiteIdsList] = useState([]);
  const [countries, setCountries] = useState([]);
  const [values, setValues] = useState({
    userType: "",
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    uniqueUserId: "",
    userRole: "",
    siteAccess: [],
  });
  const [errors, setErrors] = useState({
    firstName: null,
    lastNameError: null,
    email: false,
    country: false,
    uniqueUserId: false,
    userRole: false,
    siteAccess: false,
  });

  const emailRegex = new RegExp(/\S+@\S+\.\S+/);
  const userTypes = [
    { label: UIText.Shell_User, value: "shellUser" },
    { label: UIText.Non_Shell_User, value: "nonShellUser" },
  ];

  useState(() => {
    const items = getAllClusterCountries();
    const options = items?.map((country) => ({
      title: country,
      value: country,
    }));
    setCountries(options);
  }, []);

  useEffect(() => {
    const userType = values.userType;
    if (userType === "shellUser") {
      userService.fetchUserRoles("shellUser").then((res) => {
        if (res.length) {
          setUserRoles(res);
        }
      });
    }
    if (userType === "nonShellUser") {
      userService.fetchUserRoles("nonShellUser").then((res) => {
        if (res.length) {
          setUserRoles(res);
        }
      });
    }
  }, [values.userType]);

  const onCountryChange = ({ value: option }) => {
    siteService.fetchSiteIdList(option?.value).then((res) => {
      if (res.length) {
        const idList = res.map((siteId) => ({ text: siteId, id: siteId }));
        setSiteIdsList([
          {
            text: option?.value,
            id: NUMBERS.ONE,
            items: idList,
          },
        ]);
      }
    });
    setValues({ ...values, country: option, siteAccess: [] });
    setErrors({ ...errors, country: !option });
  };

  const resetForm = () => {
    setValues({
      userType: "",
      firstName: "",
      lastName: "",
      email: "",
      country: "",
      uniqueUserId: "",
      userRole: "",
      siteAccess: [],
    });
    setErrors({
      firstName: false,
      lastNameError: false,
      email: false,
      country: false,
      uniqueUserId: false,
      userRole: false,
      siteAccess: false,
    });
    setStep(NUMBERS.ONE);
  };

  const handleNextStep = () => {
    const { country, email, firstName, lastName } = values;
    const values1 = step === NUMBERS.ONE && firstName && lastName;
    const values2 = email && !email.includes("@shell.com") && country;
    if (values1 && values2) {
      const nonShellUser = {
        firstName,
        lastName,
        email,
        country,
      };
      setLoading(true);
      setLoadingMessage(UIText.Registering_user_to_console);
      userService.preRegisterNonShellUser(nonShellUser).then((uuid) => {
        if (uuid) {
          setValues({ ...values, uniqueUserId: uuid });
          setStep(step + NUMBERS.ONE);
        }
        setLoading(false);
        setLoadingMessage("");
      });
    }
    if (email.includes("@shell.com")) {
      setErrors({ ...errors, email: true });
    }
  };

  const isValuesSelected = (
    firstName,
    lastName,
    email,
    country,
    userRole,
    uniqueUserId
  ) => {
    const values1 = firstName && lastName && email;
    const values2 = emailRegex.test(email) && country && userRole;
    return values1 && values2 && uniqueUserId;
  };

  const isGlobalAndSomUser = (userRoleId) => {
    return (
      userRoleId === USER_ROLES.GLOBAL || userRoleId === USER_ROLES.SOM_ADMIN
    );
  };

  const showErrors = () => {
    const {
      country,
      email,
      firstName,
      lastName,
      userRole,
      uniqueUserId,
      siteAccess,
    } = values;
    const errorFields = {};
    setErrors({ ...errors, ...errorFields });

    if (!firstName) {
      errorFields.firstName = true;
    }
    if (!lastName) {
      errorFields.lastNameError = true;
    }
    if (!email || !emailRegex.test(email)) {
      errorFields.email = true;
    }
    if (!country) {
      errorFields.country = true;
    }
    if (!userRole) {
      errorFields.userRole = true;
    }
    if (!uniqueUserId) {
      errorFields.uniqueUserId = true;
    }
    if (!siteAccess.length) {
      errorFields.siteAccess = true;
    }
    setErrors({ ...errors, ...errorFields });
  };

  const onBoardGlobalUser = async (accessControl, userRoleId) => {
    const { country, email, firstName, lastName, userRole, uniqueUserId } =
      values;
    setLoading(true);
    setLoadingMessage(UIText.Onboarding_user);
    accessControl = [UIText.ALL];
    const payload = {
      UserEmail: email,
      UserFirstName: firstName,
      UserLastName: lastName,
      UniqueUserId: uniqueUserId?.toUpperCase(),
      InternalUserId: uniqueUserId,
      UserRole: userRole,
      RoleId: userRoleId,
      UserType: "Wetstock",
      IsActive: "N",
      Country: country?.value,
      AccessControl: accessControl,
    };

    try {
      const response = await userService.onboardNewUser(payload);
      if (response?.Error) {
        setLoading(false);
        triggerErrorNotification(response?.Error);
      } else {
        triggerSuccessNotification(UIText.User_on_boarded_successfully);
        setLoadingMessage("");
        setLoading(false);
        resetForm();
        setStep(NUMBERS.ONE);
      }
    } catch (error) {
      triggerErrorNotification(UIText.User_onboarding_failed);
      setLoadingMessage("");
      setLoading(false);
      resetForm();
      setStep(NUMBERS.ONE);
    }
    return accessControl;
  };

  const onBoardOtherUser = (accessControl, userRoleId) => {
    const {
      country,
      email,
      firstName,
      lastName,
      userRole,
      uniqueUserId,
      siteAccess,
    } = values;
    const errorFields = {};
    if (!siteAccess.length) {
      errorFields.siteAccess = true;
      setErrors({ ...errors, ...errorFields });
    } else {
      setLoading(true);
      setLoadingMessage(UIText.Onboarding_user);
      accessControl = country === "DE" ? ["DE"] : ["BE", "NL", "FR", "LU"];
      const payload = {
        UserEmail: email,
        UserFirstName: firstName,
        UserLastName: lastName,
        UniqueUserId: uniqueUserId?.toUpperCase(),
        InternalUserId: uniqueUserId,
        UserRole: userRole,
        RoleId: userRoleId,
        UserType: "Wetstock",
        IsActive: "N",
        Country: country?.value,
        AccessControl: accessControl,
      };
      userService.onboardNewUser(payload).then((res) => {
        if (res?.Error) {
          setLoading(false);
          triggerErrorNotification(res?.Error);
        } else {
          triggerSuccessNotification(UIText.User_on_boarded_successfully);
          setLoadingMessage("");
          setLoading(false);
          resetForm();
          setStep(NUMBERS.ONE);
        }
      });
    }
    return accessControl;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      country,
      email,
      firstName,
      lastName,
      userRole,
      uniqueUserId,
      siteAccess,
    } = values;

    const valueSelected = isValuesSelected(
      firstName,
      lastName,
      email,
      country,
      userRole,
      uniqueUserId
    );
    if (valueSelected) {
      const userRoleId = userRoles.find((role) => role.RoleName === userRole)[
        "RoleId"
      ];
      const accessControl = siteAccess.map((site) => site.text);
      if (isGlobalAndSomUser(userRoleId)) {
        onBoardGlobalUser(accessControl, userRoleId);
      } else {
        onBoardOtherUser(accessControl, userRoleId);
      }
    } else {
      showErrors();
    }
  };

  const showUserTypeRadioButton = () => {
    return (
      <div className="col-xs-12 onboarding-form-user-type">
        <Label>{UIText.Please_select_User_Type_you_want_to_onboard}</Label>
        <RadioGroup
          name="userType"
          label={UIText.User_Type_Mandatory}
          layout="horizontal"
          data={userTypes}
          onChange={(e) => setValues({ ...values, userType: e.value })}
          value={values.userType}
          disabled={step !== NUMBERS.ONE}
        />
      </div>
    );
  };

  const showFirstNameField = () => {
    return (
      <div className="col-xs-6">
        <Input
          name="firstName"
          label={UIText.First_Name_Mandatory}
          maxLength={30}
          onChange={(e) => {
            setValues({ ...values, firstName: e.value });
            setErrors({
              ...errors,
              firstName: !e.value && UIText.This_field_cant_be_empty,
            });
          }}
          value={values.firstName}
          valid={!errors.firstName}
          disabled={step === NUMBERS.TWO}
        />
        {values.firstName.length > NUMBERS.TWENTY_NINE ? (
          <div className="error-message">
            {UIText.First_Name_Should_Be_Less_Than_30}
          </div>
        ) : (
          errors.firstName && (
            <div className="error-message">{errors.firstName}</div>
          )
        )}
      </div>
    );
  };

  const showLastNameField = () => {
    return (
      <div className="col-xs-6">
        <Input
          name="lastName"
          label={UIText.Last_Name_Mandatory}
          maxLength={30}
          onChange={(e) => {
            setValues({ ...values, lastName: e.value });
            setErrors({ ...errors, lastNameError: !e.value });
          }}
          value={values.lastName}
          valid={!errors.lastNameError}
          disabled={step === NUMBERS.TWO}
        />
        {values.lastName.length > NUMBERS.TWENTY_NINE ? (
          <div className="error-message">
            {UIText.Last_Name_Should_Be_Less_Than_30}
          </div>
        ) : (
          errors.lastNameError && (
            <div className="error-message">{errors.lastNameError}</div>
          )
        )}
      </div>
    );
  };

  const showEmailField = () => {
    return (
      <div className="col-xs-6">
        <Input
          name="email"
          label={UIText.Email_Mandatory}
          maxLength={30}
          onChange={(e) => {
            setValues({ ...values, email: e.value });
            setErrors({
              ...errors,
              email: !e.value || !emailRegex.test(e.value),
            });
          }}
          value={values.email}
          valid={!errors.email}
          disabled={step === NUMBERS.TWO}
        />
        {errors.email && (
          <div className="error-message">{UIText.Please_enter_valid_email}</div>
        )}
      </div>
    );
  };

  const showCountryField = () => {
    return (
      <div className="col-xs-6">
        <DropDownList
          name="country"
          label="Country (Mandatory)"
          className="user-onboarding-dropdown"
          textField="value"
          dataItemKey="title"
          onChange={onCountryChange}
          data={countries}
          value={values.country}
          valid={!errors.country}
          disabled={step === NUMBERS.TWO}
        />
        {errors.country && (
          <div className="error-message">{UIText.This_field_cant_be_empty}</div>
        )}
      </div>
    );
  };

  const showUserIdField = () => {
    return (
      <div className="col-xs-6">
        <Input
          name="uniqueUserId"
          label={UIText.Unique_User_ID_Mandatory}
          maxLength={50}
          onChange={(e) => {
            setValues({
              ...values,
              uniqueUserId: e.value,
            });
            setErrors({
              ...errors,
              uniqueUserId: !e.value,
            });
          }}
          value={values.uniqueUserId}
          valid={!errors.uniqueUserId}
          disabled={step === NUMBERS.TWO}
        />
        {values.uniqueUserId.length > 49 ? (
          <div className="error-message">
            {UIText.Unique_Id_Should_Be_Less_Than_50}
          </div>
        ) : (
          errors.uniqueUserId && (
            <div className="error-message">
              {UIText.This_field_cant_be_empty}
            </div>
          )
        )}
      </div>
    );
  };

  const showUserRoleField = () => {
    return (
      <div className="col-xs-6">
        <DropDownList
          name="userRole"
          label={UIText.User_Role_Mandatory}
          className="user-onboarding-dropdown"
          onChange={(e) => {
            setValues({
              ...values,
              userRole: e.value.text,
              roleId: e.value.id,
            });
            setErrors({ ...errors, userRole: !e.value });
          }}
          data={userRoles.map((role) => ({
            text: role.RoleName,
            id: role.RoleId,
          }))}
          value={{
            text: values.userRole,
            id: values.roleId,
          }}
          dataItemKey="id"
          textField="text"
          valid={!errors.userRole}
        />
        {errors.userRole && (
          <div className="error-message">{UIText.This_field_cant_be_empty}</div>
        )}
      </div>
    );
  };

  const renderNoDataContent = (props) => {
    return <NoSiteIds {...props} values={values} siteIdsList={siteIdsList} />;
  };

  const showSiteAccessField = () => {
    return (
      <div className="col-xs-6">
        {values.roleId === USER_ROLES.GLOBAL ||
        values.roleId === USER_ROLES.SOM_ADMIN ? (
          <h5 className="site-access-note">
            <b>{UIText.Note}: </b>
            {`${values.userRole} have all site access.`}
          </h5>
        ) : (
          <>
            <MultiSelectTreeDropdown
              name="siteAccess"
              label={UIText.Site_Access_Mandatory}
              data={siteIdsList}
              value={values.siteAccess}
              noData={(props) => renderNoDataContent(props)}
              style={{ borderRadius: 0 }}
              onChange={(value) => {
                setValues({ ...values, siteAccess: value });
                setErrors({
                  ...errors,
                  siteAccess: !value.length,
                });
              }}
              disabled={
                values.roleId === USER_ROLES.GLOBAL ||
                values.roleId === USER_ROLES.SOM_ADMIN
              }
              validityStyles={errors.siteAccess}
            />
            {errors.siteAccess && (
              <div className="error-message">
                {UIText.Please_select_at_least_one_site}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <div className="container data-grid">
      <div className="row ">
        <div className="data-grid-container" style={{ height: "68vh" }}>
          <form className="k-form row onboarding-form" onSubmit={handleSubmit}>
            <fieldset>
              {showUserTypeRadioButton()}
              {values.userType && (
                <div className="">
                  <div className="col-xs-12 onboarding-form-input-group">
                    {showFirstNameField()}
                    {showLastNameField()}
                  </div>
                  <div className="col-xs-12 onboarding-form-input-group">
                    {showEmailField()}
                    {showCountryField()}
                  </div>
                  {(values.userType === "shellUser" || step === 2) && (
                    <>
                      <div className="col-xs-12 onboarding-form-input-group">
                        {showUserIdField()}
                        {showUserRoleField()}
                      </div>
                      {showSiteAccessField()}
                    </>
                  )}
                </div>
              )}
            </fieldset>
            <div className="k-form-buttons col-xl-12 col-lg-12 col-md-12 col-xs-12 col-xs-12">
              <button
                onClick={resetForm}
                type={"reset"}
                className="user-management-screen-btn-reset "
              >
                {UIText.Reset}
              </button>

              {values.userType === "shellUser" ||
              (values.userType === "nonShellUser" && step === NUMBERS.TWO) ? (
                <button
                  type="submit"
                  className="user-management-screen-btn-submit "
                >
                  {UIText.Submit}
                </button>
              ) : (
                <input
                  type="button"
                  onClick={handleNextStep}
                  className="user-management-screen-btn-submit "
                  value="Next"
                  disabled={
                    !values.firstName ||
                    !values.lastName ||
                    !values.email ||
                    !values.country
                  }
                />
              )}
            </div>
          </form>
        </div>
        {loading && <LoadingPanel message={loadingMessage} />}
      </div>
    </div>
  );
};
export { UserOnboardingScreen };
