import { Input } from "@progress/kendo-react-all";
import { getFormattedDate } from "../../../../../services/utils";
import { DECIMAL, NUMBERS } from "../common-utils";
import { MissingIconCell } from "../common-grid-cells";
import { UIText } from "../label-constants";

export const PumpIndexViewOptions = {
  MATERIAL: NUMBERS.ZERO,
  TANK: NUMBERS.ONE,
};

const CustomHeaderCell = (props) => {
  const columnTitle = props?.columnMenuWrapperProps?.column?.title;
  const classNames =
    props?.field === "dateOfPumpIndex"
      ? "k-customHeader k-customHeaderLine"
      : "k-customHeader";
  return (
    <th {...props}>
      <div className={classNames} title={props?.label}>
        {columnTitle}
        {props.children}
      </div>
    </th>
  );
};

const valueExist = (dataItem) => {
  const values1 =
    dataItem?.pumpReadings.length ||
    dataItem?.deliveries.length ||
    dataItem?.overallPumpSales;

  const values2 =
    dataItem?.posSalesQuantity ||
    dataItem?.posSalesVariancePercentage ||
    dataItem?.posSalesVarianceQuantity;

  const hasValue = values1 || values2;
  return !(hasValue === null || hasValue === undefined);
};

const TextInputCell = (props) => {
  const { dataItem, field, selectedItem, onChange, inputRef, isMaterial } =
    props;
  const sameItem = selectedItem ? isSame(dataItem, selectedItem, field) : false;

  if (props.dataItem?.locked) {
    const styles = {};
    let classNames = props.className;
    styles.bottom = NUMBERS.ZERO;
    classNames += " k-grid-row-sticky";
    return (
      <td
        style={{
          textAlign: "center",
          ...styles,
        }}
        className={classNames}
        onClick={() => props.onClick(props.dataItem)}
      ></td>
    );
  }
  if (valueExist(props.dataItem)) {
    if (!isMaterial) {
      return <td>{dataItem?.[field]}</td>;
    }
    return (
      <td className="editableCell">
        <Input
          ref={sameItem ? inputRef : null}
          value={dataItem?.[field]}
          onChange={(event) => {
            const position = event.target.element?.selectionStart;
            const inputValue = event.target.value;
            const newDataItem = { ...dataItem, [field]: inputValue };
            onChange({
              dataItem: newDataItem,
              field: field,
              syntheticEvent: event,
              position: position,
            });
          }}
        />
      </td>
    );
  }
  return <td className="noDataField">{UIText.No_data_received}</td>;
};

export const isSame = (dataItem, selectedItem, field) => {
  return (
    dataItem.dateOfPumpIndex === selectedItem.dateOfPumpIndex &&
    dataItem.outBound === selectedItem.outBound &&
    field === selectedItem?.editField
  );
};

const getStaticPumpColumns = () => {
  const staticPumps = [];
  for (let index = 0; index < 2; index++) {
    const name = index === 0 ? `Pump x` : `Pump y`;
    const element = {
      title: name,
      headerCell: (props) => {
        return <CustomHeaderCell {...props} label={name} />;
      },
      columns: [
        {
          title: UIText.Meter_Reading,
          field: "pump1MeterReading",
          headerCell: (props) => {
            return <CustomHeaderCell {...props} label={UIText.Meter_Reading} />;
          },
          width: NUMBERS.EIGHTY,
        },
        {
          title: UIText.Quantity,
          field: "pump1Qty",
          headerCell: (props) => {
            return (
              <CustomHeaderCell
                {...props}
                label={UIText.Quantity_in_litres_at_15_C}
              />
            );
          },
          label: UIText.testing,
          width: NUMBERS.EIGHTY,
        },
      ],
    };
    staticPumps.push(element);
  }
  return staticPumps;
};

export const getFooterItem = (response) => {
  const footerItem = {
    dateOfPumpIndex: UIText.TOTAL,
    dateOfFuel: "",
    outBound: "",
    comments: "",
    locked: true,
    deliveryQty: NUMBERS.ZERO,
    existingStock: NUMBERS.ZERO,
    overallSales: NUMBERS.ZERO,
    salesQtyPos: NUMBERS.ZERO,
    varianceQty: NUMBERS.ZERO,
    variancePercent: "",
    footerItem: true,
  };
  const pumpData = {};

  let openingStock = null;

  response?.forEach((item) => {
    item?.pumpReadings?.forEach((pump) => {
      const key = `pump${pump.pumpNumber}qty`;
      const qty = pump?.salesQuantity;
      const current = parseInt(pumpData[key]);
      pumpData[key] = current ? current + qty : qty;
    });
    const deliveryQuantity = parseFloat(item?.deliveryQty);
    if (deliveryQuantity) {
      footerItem.deliveryQty =
        Math.round(footerItem?.deliveryQty) + deliveryQuantity;
    }
    const existingStockData = parseFloat(item?.existingStock);
    if (existingStockData) {
      footerItem.existingStock = existingStockData;
    }

    const overallSalesData = parseFloat(item?.overallSales);
    if (overallSalesData) {
      footerItem.overallSales =
        Math.round(footerItem?.overallSales) + overallSalesData;
    }

    const salesQtyPosData = parseFloat(item?.salesQtyPos);
    if (salesQtyPosData) {
      footerItem.salesQtyPos =
        Math.round(footerItem?.salesQtyPos) + salesQtyPosData;
    }

    if (!openingStock && footerItem.existingStock) {
      openingStock = footerItem.existingStock;
    }
  });

  let varianceQty =
    openingStock -
    footerItem.existingStock +
    footerItem.overallSales -
    footerItem.deliveryQty;

  if (openingStock && footerItem.existingStock !== openingStock) {
    varianceQty = Math.round(varianceQty);
  } else if (openingStock) {
    varianceQty = NUMBERS.ZERO;
  } else {
    varianceQty = "";
  }

  const percentage = Math.round(varianceQty / footerItem.overallSales);
  const variancePercent = isNaN(percentage) ? "" : percentage;

  let deliveryQty = Math.round(parseFloat(footerItem.deliveryQty));
  deliveryQty = isNaN(deliveryQty) ? "" : deliveryQty;

  let existingStock = Math.round(parseFloat(footerItem.existingStock));
  existingStock = isNaN(existingStock) ? "" : existingStock;

  let overallSales = Math.round(parseFloat(footerItem.overallSales));
  overallSales = isNaN(overallSales) ? "" : overallSales;

  let salesQtyPos = Math.round(parseFloat(footerItem.salesQtyPos));
  salesQtyPos = isNaN(salesQtyPos) ? "" : salesQtyPos;

  return {
    ...footerItem,
    deliveryQty: deliveryQty,
    existingStock: existingStock,
    overallSales: overallSales,
    salesQtyPos: salesQtyPos,
    varianceQty: varianceQty,
    variancePercent:
      variancePercent !== Infinity && variancePercent !== -Infinity
        ? variancePercent
        : "",
    ...pumpData,
  };
};

export const getDeliveryDate = (deliveries) => {
  const dates = deliveries?.map((delivery) =>
    getFormattedDate(new Date(delivery?.deliveryDateTime))
  );
  const deliveryDate = [...new Set(dates)]?.join("\n ");
  return deliveryDate;
};

export const getDeliveryIds = (deliveries) => {
  const deliveryIds = deliveries?.map((delivery) => delivery?.deliveryId);
  const deliveryId = [...new Set(deliveryIds)]?.join("\n ");
  return deliveryId;
};

export const getDeliveryQty = (deliveries) => {
  const quantityValues = deliveries?.map((delivery) => delivery?.quantity);

  if (quantityValues?.length > 0) {
    const totalQty = quantityValues?.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

    return totalQty.toFixed(0);
  }
  return "";
};

const TestSalesCell = (props) => {
  const { dataItem, field } = props;
  const value = dataItem?.[field];
  return (
    <td style={{ paddingTop: "0.8vh" }}>
      {value?.toLowerCase() === "yes" ? (
        <span className="HasTestSalesValue k-grid-row-sticky">{value}</span>
      ) : (
        value
      )}
    </td>
  );
};

const HighlightedCell = (props) => {
  const { dataItem, field } = props;
  let value = dataItem?.[field];
  let difference = NUMBERS.ZERO;
  if (
    field === "overallSales" ||
    field === "salesQtyPos" ||
    field === "salesDifference"
  ) {
    difference = isNaN(dataItem?.salesDifference)
      ? 0
      : dataItem?.salesDifference;
  }
  if (field === "salesDifference") {
    value = isNaN(value) ? "" : value;
  }
  return (
    <td style={{ paddingTop: "0.8vh" }}>
      {difference !== NUMBERS.ZERO && !dataItem?.footerItem ? (
        <span className="highlightedField">{value}</span>
      ) : (
        value
      )}
    </td>
  );
};

export const colsToShow = (
  trendAnalysisData,
  selectedMode,
  ref,
  onChange,
  selectedItem,
  screenWidth
) => {
  const firstRecord = trendAnalysisData?.[0];
  const values1 =
    firstRecord?.overallSales?.toString()?.length > 0 ||
    firstRecord?.salesQtyPos?.toString()?.length > 0 ||
    firstRecord?.overallPosTestSalesQuantity?.toString()?.length > 0;

  const values2 =
    firstRecord?.existingStock?.toString()?.length > 0 ||
    firstRecord?.varianceQty?.toString()?.length > 0 ||
    firstRecord?.variancePercent?.toString()?.length > 0;

  const haveRecords = values1 || values2;
  const validRow = trendAnalysisData?.find(
    (item) => item?.pumpReadings?.length > 0
  );
  const totalPumps = validRow?.pumpReadings?.length;
  const totalColumns =
    selectedMode === PumpIndexViewOptions.TANK
      ? NUMBERS.SIX + totalPumps * NUMBERS.TWO
      : NUMBERS.TEN + totalPumps * NUMBERS.TWO;
  const ratio = DECIMAL.ONE_POINT_ONE_TWO / totalColumns;
  const totalWidth =
    totalColumns * (screenWidth * ratio) - screenWidth * DECIMAL.POINT_TWO;
  const minResizableWidth = NUMBERS.EIGHTY;
  let itemWidth = parseInt(totalWidth / totalColumns);
  itemWidth = itemWidth < minResizableWidth ? minResizableWidth : itemWidth;
  let columns = [
    {
      title: UIText.Date,
      field: "dateOfPumpIndex",
      headerCell: (props) => {
        return (
          <CustomHeaderCell
            {...props}
            label={UIText.Date_of_Pump_Index_Reading}
          />
        );
      },
      width: window.innerWidth * DECIMAL.POINT_ZERO_SIX,
      locked: true,
      minResizableWidth:
        itemWidth > minResizableWidth ? itemWidth : minResizableWidth,
    },
    {
      title: UIText.Deliveries,
      locked: true,
      headerCell: (props) => {
        return <CustomHeaderCell {...props} label={UIText.Deliveries} />;
      },
      columns: [
        {
          title: UIText.Dates,
          field: "dateOfFuel",
          headerCell: (props) => {
            return (
              <CustomHeaderCell
                {...props}
                label={UIText.Date_Time_of_Fuel_Delivery}
              />
            );
          },
          locked: true,
          width: itemWidth * DECIMAL.ONE_POINT_TWO,
          minResizableWidth:
            itemWidth > minResizableWidth ? itemWidth : minResizableWidth,
        },
        {
          title: UIText.Delivery_IDs,
          field: "outBound",
          headerCell: (props) => {
            return (
              <CustomHeaderCell
                {...props}
                label={UIText.OutBound_Delivery_Ids}
              />
            );
          },
          locked: true,
          width: itemWidth * DECIMAL.ONE_POINT_TWO,
          minResizableWidth:
            itemWidth > minResizableWidth ? itemWidth : minResizableWidth,
        },
        {
          title: UIText.Quantity,
          field: "deliveryQty",
          headerCell: (props) => {
            return (
              <CustomHeaderCell
                {...props}
                label={UIText.Fuel_Quantity_Delivered_in_litres_at_15_C}
              />
            );
          },
          locked: true,
          width: itemWidth,
          minResizableWidth:
            itemWidth > minResizableWidth ? itemWidth : minResizableWidth,
        },
      ],
    },
  ];

  const pumpColumns = haveRecords
    ? validRow?.pumpReadings?.map((pump) => {
        return {
          title: `${UIText.Pump} ${pump?.pumpNumber}`,
          headerCell: (props) => {
            return (
              <CustomHeaderCell
                {...props}
                label={`${UIText.Pump} ${pump?.pumpNumber}`}
              />
            );
          },
          columns: [
            {
              title: UIText.Reading,
              field: `pump${pump.pumpNumber}meterReading`,
              headerCell: (props) => {
                return (
                  <CustomHeaderCell {...props} label={UIText.Meter_Reading} />
                );
              },
              cell: (props) => {
                return <MissingIconCell {...props} />;
              },
              width: itemWidth,
            },
            {
              title: "Quantity",
              field: `pump${pump.pumpNumber}qty`,
              cell: (props) => {
                return <MissingIconCell {...props} />;
              },
              headerCell: (props) => {
                return (
                  <CustomHeaderCell
                    {...props}
                    label={UIText.Quantity_in_litres_at_15_C}
                  />
                );
              },
              width: itemWidth,
            },
          ],
        };
      })
    : getStaticPumpColumns();

  if (Array.isArray(pumpColumns)) {
    columns = [...columns, ...pumpColumns];
  }

  if (selectedMode === PumpIndexViewOptions.MATERIAL) {
    columns = [
      ...columns,
      {
        title: UIText.Pump_Sales,
        field: "overallSales",
        cell: HighlightedCell,
        headerCell: (props) => {
          return (
            <CustomHeaderCell {...props} label={UIText.Overall_Pump_Sales} />
          );
        },
        width: itemWidth,
      },
      {
        title: UIText.POS_Sales,
        field: "salesQtyPos",
        cell: HighlightedCell,
        headerCell: (props) => {
          return (
            <CustomHeaderCell
              {...props}
              label={UIText.Sales_Quantity_from_POS}
            />
          );
        },
        width: itemWidth,
      },
      {
        title: UIText.Sales_Difference,
        field: "salesDifference",
        cell: HighlightedCell,
        headerCell: (props) => {
          return (
            <CustomHeaderCell {...props} label={UIText.Sales_Difference} />
          );
        },
        width: itemWidth,
      },
      {
        title: UIText.Has_Test_Sales,
        field: "hasTestSales",
        cell: TestSalesCell,
        headerCell: (props) => {
          return <CustomHeaderCell {...props} label={UIText.Has_Test_Sales} />;
        },
        width: itemWidth,
      },
      {
        title: UIText.Test_Sales,
        field: "overallPosTestSalesQuantity",
        headerCell: (props) => {
          return <CustomHeaderCell {...props} label={UIText.Test_Sales} />;
        },
        width: itemWidth,
      },
    ];
  }

  columns = [
    ...columns,
    {
      title: UIText.Stock,
      field: "existingStock",
      headerCell: (props) => {
        return <CustomHeaderCell {...props} label={UIText.Existing_Stock} />;
      },
      width: itemWidth,
    },
  ];

  if (selectedMode === PumpIndexViewOptions.MATERIAL) {
    columns = [
      ...columns,
      {
        title: UIText.Variance_Pump_Sales,
        headerCell: (props) => {
          return (
            <CustomHeaderCell
              {...props}
              label={
                UIText.Current_Date_Stock_Prev_Date_Stock_Overall_Pump_Sales_Deliveries
              }
            />
          );
        },
        columns: [
          {
            title: UIText.Quantity,
            field: "varianceQty",
            headerCell: (props) => {
              return (
                <CustomHeaderCell
                  {...props}
                  label={UIText.Quantity_in_litres_at_15_C}
                />
              );
            },
            width: itemWidth,
          },
          {
            title: UIText.Variance_Percent,
            field: "variancePercent",
            headerCell: (props) => {
              return (
                <CustomHeaderCell {...props} label={UIText.Variance_Percent} />
              );
            },
            width: itemWidth,
          },
        ],
      },
    ];
  }

  columns = [
    ...columns,
    {
      title: UIText.Comments,
      field: "comments",
      headerCell: (props) => {
        return <CustomHeaderCell {...props} label={UIText.Comments} />;
      },
      width: screenWidth * DECIMAL.POINT_TWO,
      cell: (props) => {
        return (
          <TextInputCell
            {...props}
            inputRef={ref}
            onChange={onChange}
            selectedItem={selectedItem}
            isMaterial={selectedMode === PumpIndexViewOptions.MATERIAL}
          />
        );
      },
    },
  ];

  return columns;
};

export const exceptionColsToShow = () => {
  const columns = [
    {
      title: UIText.ShipTo,
      field: "globalSiteId",
      headerCell: (props) => {
        return <CustomHeaderCell {...props} label={UIText.ShipTo} />;
      },
    },
    {
      title: UIText.Material_Name,
      field: "materialName",
      headerCell: (props) => {
        return <CustomHeaderCell {...props} label={UIText.Material_Name} />;
      },
    },
    {
      title: UIText.Delivered_Quantity,
      field: "totalDeliveries",
      headerCell: (props) => {
        return (
          <CustomHeaderCell
            {...props}
            label={UIText.Delivered_Quantity_in_litres_at_15_C}
          />
        );
      },
    },
    {
      title: UIText.Overall_Pump_Sales_Quantity,
      field: "totalOverallPumpSales",
      headerCell: (props) => {
        return (
          <CustomHeaderCell
            {...props}
            label={UIText.Overall_Pump_Sales_Quantity_in_litres_at_15_C}
          />
        );
      },
    },
    {
      title: UIText.Existing_Stock_Quantity,
      field: "endOfMonthStock",
      headerCell: (props) => {
        return (
          <CustomHeaderCell
            {...props}
            label={UIText.Existing_Stock_Quantity_in_litres_at_15_C}
          />
        );
      },
    },
    {
      title: UIText.Monthly_Variance_Gain_Loss,
      headerCell: (props) => {
        return (
          <CustomHeaderCell
            {...props}
            label={UIText.Monthly_Variance_Gain_Loss}
          />
        );
      },
      columns: [
        {
          title: UIText.Quantity,
          field: "varianceQuantity",
          headerCell: (props) => {
            return (
              <CustomHeaderCell
                {...props}
                label={UIText.Quantity_in_litres_at_15_C}
              />
            );
          },
        },
        {
          title: UIText.Variance_Percent,
          field: "variancePercentage",
          headerCell: (props) => {
            return (
              <CustomHeaderCell {...props} label={UIText.Variance_Percent} />
            );
          },
        },
      ],
    },
  ];

  return columns;
};
const getRoundedValue = (value) => {
  const decimalValue = parseFloat(value);
  const intValue = isNaN(decimalValue) ? null : Math.round(decimalValue);
  return intValue;
};

export async function fetchAndFormatPumpIndexReportResponse(
  payload,
  selectedTab,
  reportsService
) {
  let response = null;
  if (selectedTab === PumpIndexViewOptions?.TANK) {
    response = await reportsService.fetchPumpIndexTankData(payload);
  } else {
    response = await reportsService.fetchPumpIndexData(payload);
  }
  response.sort(
    (a, b) => new Date(a.pumpReadingDateTime) - new Date(b.pumpReadingDateTime)
  );
  response = response?.map((item) => {
    const dateOfPumpIndex = getFormattedDate(new Date(item.pumpReadingDate));
    const dateOfFuel = getDeliveryDate(item?.deliveries);
    const outBound = getDeliveryIds(item?.deliveries);
    const deliveryQty = getDeliveryQty(item?.deliveries);
    const salesQtyPos = getRoundedValue(item?.overallPosSalesQuantity) || "";
    const existingStock = getRoundedValue(item?.overallExistingStock) || "";
    const varianceQty = getRoundedValue(item?.pumpSalesVarianceQuantity) || "";
    const variancePercent =
      getRoundedValue(item?.pumpSalesVariancePercentage) || "";
    const overallSales = getRoundedValue(item?.overallPumpSalesQuantity) || "";
    const salesDifference = parseFloat(overallSales) - parseFloat(salesQtyPos);

    const pumpData = {};
    const pumpReadings = item?.pumpReadings.sort(
      (a, b) => new Date(a.pumpNumber) - new Date(b.pumpNumber)
    );
    pumpReadings?.forEach((pump) => {
      pumpData[`pump${pump.pumpNumber}meterReading`] =
        getRoundedValue(pump?.endMeterReading) || "";
      pumpData[`pump${pump.pumpNumber}qty`] =
        getRoundedValue(pump?.salesQuantity) || "";
    });
    let hasTestSales = "";
    if (item?.hasTestSales !== null) {
      if (item?.hasTestSales === true) {
        hasTestSales = "Yes";
      } else {
        hasTestSales = "No";
      }
    }

    return {
      ...item,
      dateOfPumpIndex,
      dateOfFuel,
      outBound,
      deliveryQty,
      salesQtyPos,
      existingStock,
      varianceQty,
      variancePercent,
      overallSales,
      hasTestSales: hasTestSales,
      comments: item?.comments ? item?.comments : "",
      salesDifference,
      ...pumpData,
    };
  });
  if (response?.length > NUMBERS.ZERO) {
    const lockedItem = getFooterItem(response);
    if (selectedTab === PumpIndexViewOptions.MATERIAL) {
      response = [...response, lockedItem];
    }
  }
  return response;
}

export const PDFHeaderTemplate = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "10px",
        left: "10px",
      }}
    >
      Page {props.pageNum} of {props.totalPages}
    </div>
  );
};

export const isPumpFilterValuesSelected = (
  month,
  year,
  filter,
  currentCountryCode,
  selectedTab
) => {
  const selected = month && year && currentCountryCode;

  let valuesSelected =
    selected && filter?.selectedSiteId && filter?.selectedProduct;

  if (selectedTab === PumpIndexViewOptions?.TANK) {
    valuesSelected = valuesSelected && filter?.selectedTankGroup;
  }
  return valuesSelected;
};

export const getFetchApiRequestPayload = (month, year, filter, selectedTab) => {
  let payload = {
    Month: month?.id,
    Year: year?.text,
    GlobalSiteId: filter?.selectedSiteId?.text,
    MaterialCode: filter?.selectedProduct?.id,
  };
  if (selectedTab === PumpIndexViewOptions?.TANK) {
    payload = {
      ...payload,
      TankGroup: filter?.selectedTankGroup?.id,
    };
  }
  return payload;
};
