import { httpService } from "../services/http";
import { NUMBERS } from "../ui/pages/reports/components/common-utils";
import { API, getFormattedDate } from "./utils";

class IssueService {
  async getAllIssues(payload) {
    const fromDate = payload?.selectedDates?.start;
    const toDate = payload?.selectedDates?.end;
    const startDate = getFormattedDate(fromDate)
    const endDate = getFormattedDate(toDate)
    let URL = `${API.issue}/${payload.country}/details?startDate=${startDate}&endDate=${endDate}`;
    if (payload?.selectedRegions?.[0]?.text?.length > 0) {
      URL += `&region=${payload?.selectedRegions?.[0]?.text}`;
    } else {
      URL += `&region=`;
    }
    if (payload?.actionParty?.text?.length > 0) {
      URL += `&actionParty=${payload?.actionParty?.text}`;
    }
    if (payload?.status?.text?.length) {
      URL += `&status=${payload?.status?.text}`;
    }
    if (payload?.selectedShipTo?.[0]?.text?.length > 0) {
      URL += `&globalSiteId=${payload?.selectedShipTo?.[0]?.text}`;
    }
    URL += `&pageNo=${payload?.pageNo}&pageSize=${NUMBERS.FIFTY}`;
    try {
      const response = await httpService.get(URL);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async getAllSiteIds(country) {
    const URL = `${API.issueSitesInfo}/${country}`;
    try {
      const response = await httpService.get(URL);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async createOrUpdateIssue(payload, countryCode) {
    const URL = `${API.issue}/${countryCode}`;
    try {
      return await httpService.post(URL, payload);
    } catch (error) {
      return null;
    }
  }

  async downloadIssueExcel(payload) {
    const URL = `${API.issue}/${payload.country}/download?region=${
      payload?.region?.text
    }&status=${payload?.status?.text}&startDate=${payload?.startDate}&endDate=${
      payload?.endDate
    }&actionParty=${payload?.actionParty?.text}&globalSiteId=${
      payload.globalSiteId || ""
    }`;
    await httpService.downloadFile(URL, "issue-log-report.xlsx");
  }
}

const issueService = new IssueService();
export { issueService };
