import { loggerService } from "../services/logger";

/**
 *  Checks if all the environment variables are set.
 */
const {
  REACT_APP_ENVIRONMENT,
  REACT_APP_POWER_BI_APP_ID,
  REACT_APP_POWER_BI_CTID,
  REACT_APP_POWER_BI_CONFIG,
  REACT_APP_PING_CLIENT_ID,
  REACT_APP_PING_BASE_URL,
  REACT_APP_CIPM_CLIENT_ID,
  REACT_APP_CIPM_BASE_URL,
  REACT_APP_CIPM_CLIENT_SECRET,
  REACT_APP_REDIRECT_URL,
  REACR_APP_EMAIL_CODE,
} = process.env;

/**
 * If any of the env vars are not set then crash the app deliberately.
 */
if (
  (!REACT_APP_ENVIRONMENT,
  !REACT_APP_POWER_BI_APP_ID,
  !REACT_APP_POWER_BI_CTID,
  !REACT_APP_POWER_BI_CONFIG,
  !REACT_APP_PING_CLIENT_ID,
  !REACT_APP_PING_BASE_URL,
  !REACT_APP_CIPM_CLIENT_ID,
  !REACT_APP_CIPM_BASE_URL,
  !REACT_APP_CIPM_CLIENT_SECRET,
  !REACT_APP_REDIRECT_URL)
) {
  loggerService.dev("Env vars are not defined!");
  throw new Error("Env variables are not set!");
}

loggerService.dev("Preparing the global config object.");

/**
 * appConfig is the global configuration object. This object will be used in rest of the parts of application.
 * appConfig contains all the application related configuration which decides how the application behaves.
 * It's like a setting file for the application.
 */
const emailURL = `https://rdievhcwrreportemailservice.azurewebsites.net/api/SendMECEmail?code=${REACR_APP_EMAIL_CODE}&CountryCode=DE`;
const messageTrackerURL =
  "https://rdievhmessagetracker.azurewebsites.net/api/msg-tracker";

const appConfig = {
  isDev: REACT_APP_ENVIRONMENT.toLowerCase() === "test",
  env: REACT_APP_ENVIRONMENT.toLowerCase(),
  powerBI: {
    APP_ID: REACT_APP_POWER_BI_APP_ID,
    CT_ID: REACT_APP_POWER_BI_CTID,
    CONFIG: REACT_APP_POWER_BI_CONFIG,
  },
  auth: {
    ping: {
      clientId: REACT_APP_PING_CLIENT_ID,
      authorizationGrantType: "authorization_code",
      refreshGrantType: "refresh_token",
      responseType: "code",
      codeChallengeMethod: "S256",
      baseUrl: REACT_APP_PING_BASE_URL,
      redirectUrl: REACT_APP_REDIRECT_URL,
    },
    cipm: {
      clientId: REACT_APP_CIPM_CLIENT_ID,
      responseType: "code",
      redirectUrl: REACT_APP_REDIRECT_URL,
      codeChallengeMethod: "S256",
      state: "46twr7w4tr",
      scope: "openid",
      ui_locales: "en-US",
      baseUrl: REACT_APP_CIPM_BASE_URL,
      clientSecret: REACT_APP_CIPM_CLIENT_SECRET,
    },
  },
  api: {
    dev: {
      email: emailURL,
      userManagement: "https://rdievhusermgmtapi.azurewebsites.net/api",
      masterData:
        "https://rdievhmasterdataapidev.azurewebsites.net/api/MasterData",
      powerBiToken:
        "https://rdievhtokenapidev.azurewebsites.net/api/powerbitoken/token",
      manualData:
        "https://rdievhmanualdataapi.azurewebsites.net/api/ManualData",
      cwrComment:
        "https://rdievhcwrcommentapidev.azurewebsites.net/api/cwrcomment",
      chartData:
        "https://rdievhcwrcommentapidev.azurewebsites.net/api/CWRComment",
      cwrCommentAFD:
        "https://rdievhcwrcommentapidev.azurewebsites.net/api/cwrcomment/afd",
      prePosting:
        "https://rdievhcwrcommentapidev.azurewebsites.net/api/CWRComment/prepostdata",
      issue: "https://rdievhcwrissuelogapidev.azurewebsites.net/issue",
      issueDetails:
        "https://rdievhcwrissuelogapidev.azurewebsites.net/issue/details",
      issueSitesInfo: "https://rdievhcwrissuelogapidev.azurewebsites.net/site",
      reportRequest: "https://rdievhrestoreapidev.azurewebsites.net",
      siteInfo: "https://rdievhsiteinfodev.azurewebsites.net/siteInfo",
      sitedetails: "https://rdievhsiteinfodev.azurewebsites.net/sitedetails",
      updateRemarks:
        "https://rdievhsiteinfodev.azurewebsites.net/UpdateRemarks",
      downloadSiteData: "https://rdievhsiteinfodev.azurewebsites.net/download",
      UploadExcel:
        "https://rdievhcwrcommentapidev.azurewebsites.net/api/CWRComment/UploadExcel",
      duplicateDeliveries:
        "https://rdievhgsapdeliveryissueapidev.azurewebsites.net/api/DeliveryIssue",
      threshold:
        "https://rdievhtankspecificthresholdapidev.azurewebsites.net/api",
      oota: "https://rdievhcwrdailyreconapidev.azurewebsites.net/api",
      pumpIndex: "https://rdievhpumpregisterdev.azurewebsites.net/api",
      eod: "https://rdievheodapitest.azurewebsites.net/api",
      afd: "https://rdievhutilitiestest.azurewebsites.net/api/utils",
      ottSummary: "https://rdievhoutoftolerancetest.azurewebsites.net/api/oota",
      afdDeliveries:
        "https://rdievhaccountingfordeliveriestest.azurewebsites.net/api",
      siteInfoNew: "https://rdievhutilitiestest.azurewebsites.net/api/sites",
      siteStatuses:
        "https://rdievhutilitiestest.azurewebsites.net/api/site-statuses",
      siteSources:
        "https://rdievhutilitiestest.azurewebsites.net/api/site-sources",
      gainLoss:
        "https://rdievhwetstockreconciliationtest.azurewebsites.net/api/wet-stock/recon/reports",
      messageTracker: messageTrackerURL,
      clusterDetails:
        "https://rdievhsiteinfodev.azurewebsites.net/clusterDetails",
      failedDelivery:
        "https://rdievhdeliveriesoutboundwsm.azurewebsites.net/api/deliveries",
      monthlyStockLevel:
        "https://rdievhmonthlystocklevelstest.azurewebsites.net/api/monthly-stock-levels",
      products: "https://rdievhutilitiestest.azurewebsites.net/api/products",
    },
    test: {
      email: emailURL,
      userManagement: "https://rdievhusermgmtapitest.azurewebsites.net/api",
      masterData:
        "https://rdievhmasterdataapitest.azurewebsites.net/api/MasterData",
      powerBiToken:
        "https://rdievhtokenapitest.azurewebsites.net/api/powerbitoken/token",
      manualData:
        "https://rdievhmanualdataapitest.azurewebsites.net/api/ManualData",
      cwrComment:
        "https://rdievhcwrcommentapitest.azurewebsites.net/api/cwrcomment",
      chartData:
        "https://rdievhcwrcommentapitest.azurewebsites.net/api/CWRComment",
      cwrCommentAFD:
        "https://rdievhcwrcommentapitest.azurewebsites.net/api/cwrcomment/afd",
      prePosting:
        "https://rdievhcwrcommentapitest.azurewebsites.net/api/CWRComment/prepostdata",
      issue: "https://rdievhcwrissuelogapitest.azurewebsites.net/issue",
      issueDetails:
        "https://rdievhcwrissuelogapitest.azurewebsites.net/issue/details",
      issueSitesInfo: "https://rdievhcwrissuelogapitest.azurewebsites.net/site",
      reportRequest: "https://rdievhrestoreapitest.azurewebsites.net",
      siteInfo: "https://rdievhsiteinfotest.azurewebsites.net/siteInfo",
      sitedetails: "https://rdievhsiteinfotest.azurewebsites.net/sitedetails",
      updateRemarks:
        "https://rdievhsiteinfotest.azurewebsites.net/UpdateRemarks",
      downloadSiteData: "https://rdievhsiteinfotest.azurewebsites.net/download",
      UploadExcel:
        "https://rdievhcwrcommentapitest.azurewebsites.net/api/CWRComment/UploadExcel",
      duplicateDeliveries:
        "https://rdievhgsapdeliveryissueapitest.azurewebsites.net/api/DeliveryIssue",
      threshold:
        "https://rdievhtankspecificthresholdapitest.azurewebsites.net/api",
      oota: "https://rdievhcwrdailyreconapitest.azurewebsites.net/api",
      pumpIndex: "https://rdievhpumpregistertest.azurewebsites.net/api",
      eod: "https://rdievheodapitest.azurewebsites.net/api",
      afd: "https://rdievhutilitiestest.azurewebsites.net/api/utils",
      ottSummary: "https://rdievhoutoftolerancetest.azurewebsites.net/api/oota",
      afdDeliveries:
        "https://rdievhaccountingfordeliveriestest.azurewebsites.net/api",
      siteInfoNew: "https://rdievhutilitiestest.azurewebsites.net/api/sites",
      siteStatuses:
        "https://rdievhutilitiestest.azurewebsites.net/api/site-statuses",
      siteSources:
        "https://rdievhutilitiestest.azurewebsites.net/api/site-sources",
      gainLoss:
        "https://rdievhwetstockreconciliationtest.azurewebsites.net/api/wet-stock/recon/reports",
      messageTracker: messageTrackerURL,
      clusterDetails:
        "https://rdievhsiteinfotest.azurewebsites.net/clusterDetails",
      failedDelivery:
        "https://rdievhdeliveriesoutboundtest.azurewebsites.net/api/deliveries",
      monthlyStockLevel:
        "https://rdievhmonthlystocklevelstest.azurewebsites.net/api/monthly-stock-levels",
      products: "https://rdievhutilitiestest.azurewebsites.net/api/products",
    },
    prod: {
      email: emailURL,
      userManagement: "https://rdievhusermgmtapiwsm.azurewebsites.net/api",
      masterData:
        "https://rdievhmasterdataapiwsm.azurewebsites.net/api/MasterData",
      powerBiToken:
        "https://rdievhtokenapiwsm.azurewebsites.net/api/powerbitoken/token",
      manualData:
        "https://rdievhmanualdataapiwsm.azurewebsites.net/api/ManualData",
      cwrComment:
        "https://rdievhcwrcommentapiwsm.azurewebsites.net/api/cwrcomment",
      chartData:
        "https://rdievhcwrcommentapiwsm.azurewebsites.net/api/CWRComment",
      cwrCommentAFD:
        "https://rdievhcwrcommentapiwsm.azurewebsites.net/api/cwrcomment/afd",
      prePosting:
        "https://rdievhcwrcommentapiwsm.azurewebsites.net/api/CWRComment/prepostdata",
      issue: "https://rdievhcwrissuelogapiwsm.azurewebsites.net/issue",
      issueDetails:
        "https://rdievhcwrissuelogapiwsm.azurewebsites.net/issue/details",
      issueSitesInfo: "https://rdievhcwrissuelogapiwsm.azurewebsites.net/site",
      reportRequest: "https://rdievhrestoreapiwsm.azurewebsites.net",
      siteInfo: "https://rdievhsiteinfowsm.azurewebsites.net/siteInfo",
      sitedetails: "https://rdievhsiteinfowsm.azurewebsites.net/sitedetails",
      updateRemarks:
        "https://rdievhsiteinfowsm.azurewebsites.net/UpdateRemarks",
      downloadSiteData: "https://rdievhsiteinfowsm.azurewebsites.net/download",
      UploadExcel:
        "https://rdievhcwrcommentapiwsm.azurewebsites.net/api/CWRComment/UploadExcel",
      duplicateDeliveries:
        "https://rdievhgsapdeliveryissueapiwsm.azurewebsites.net/api/DeliveryIssue",
      threshold:
        "https://rdievhtankspecificthresholdapiwsm.azurewebsites.net/api",
      oota: "https://rdievhcwrdailyreconciliationapiwsm.azurewebsites.net/api",
      pumpIndex: "https://rdievhpumpregisterwsm.azurewebsites.net/api",
      eod: "https://rdievheodapiwsm.azurewebsites.net/api",
      afd: "https://rdievhutilitieswsm.azurewebsites.net/api/utils",
      ottSummary: "https://rdievhoutoftolerancewsm.azurewebsites.net/api/oota",
      afdDeliveries:
        "https://rdievhaccountingfordeliverieswsm.azurewebsites.net/api",
      siteInfoNew: "https://rdievhutilitieswsm.azurewebsites.net/api/sites",
      siteStatuses:
        "https://rdievhutilitieswsm.azurewebsites.net/api/site-statuses",
      siteSources:
        "https://rdievhutilitieswsm.azurewebsites.net/api/site-sources",
      gainLoss:
        "https://rdievhwetstockreconciliationwsm.azurewebsites.net/api/wet-stock/recon/reports",
      messageTracker: messageTrackerURL,
      clusterDetails:
        "https://rdievhsiteinfowsm.azurewebsites.net/clusterDetails",
      failedDelivery:
        "https://rdievhdeliveriesoutboundwsm.azurewebsites.net/api/deliveries",
      monthlyStockLevel:
        "https://rdievhmonthlystocklevelswsm.azurewebsites.net/api/monthly-stock-levels",
      products: "https://rdievhutilitieswsm.azurewebsites.net/api/products",
    },
  },
};

loggerService.dev("Env variables initialized!");
export { appConfig };
