import React, { useEffect, useRef, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import "./site-information.scss";
import { DropDownList } from "@progress/kendo-react-all";
import { TimePicker } from "@progress/kendo-react-dateinputs";
import {
  getFormattedDate,
  getFormattedDateAndTime,
} from "../../../../../services/utils";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { TextArea } from "@progress/kendo-react-inputs";
import { SearchableDropdown } from "../../../../components/searchable-dropdown/searchable-dropdown";
import { siteService } from "../../../../../services/sites";
import ConfirmPopup from "../../../../components/confirmation-popup/confirmation-popup";
import { LabelField } from "./site-information-utils";
import {
  NUMBERS,
  triggerErrorNotification,
  triggerSuccessNotification,
} from "../common-utils";
import { UIText } from "../label-constants";
import { DateField, TextInputField } from "../common-grid-cells";
import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";

const DropDownField = (props) => {
  const selectedField = `${props?.selectedField}`;
  const selectedValue = props?.fields?.[selectedField];
  const data = props?.fields?.[`${props?.field}`]?.map((item) => item);
  const filterable = props?.filterable;
  return (
    <div className="deliveryRow">
      <span className="deliveryLbl">
        {props?.label}
        {props?.mandatory && <span style={{ color: "red" }}>*</span>}
      </span>

      <span className="deliveryVal">
        {filterable ? (
          <SearchableDropdown
            data={data}
            textField="text"
            dataItemKey="id"
            filterable={true}
            disabled={props?.readOnly}
            onSelect={(event) => {
              props?.setSelectedItem(selectedField);
              props?.setFields((prev) => {
                return {
                  ...prev,
                  [selectedField]: event?.value?.text,
                };
              });
            }}
            value={{ text: selectedValue }}
            style={{
              width: "15vw",
              marginRight: "1vw",
              fontSize: "10px",
            }}
          />
        ) : (
          <DropDownList
            data={data}
            disabled={props?.readOnly}
            onChange={(event) => {
              props?.setSelectedItem(selectedField);
              props?.setFields((prev) => {
                return {
                  ...prev,
                  [selectedField]: event?.value,
                };
              });
            }}
            value={selectedValue}
            style={{
              width: "15vw",
              marginRight: "1vw",
              fontSize: "10px",
            }}
          />
        )}
      </span>
    </div>
  );
};

const onDateChange = (props) => {
  const { event, selectedField } = props;
  const date = new Date(event?.value);
  props?.setFields((prev) => {
    props?.setSelectedItem(selectedField);
    return {
      ...prev,
      [selectedField]: date,
    };
  });
};

const onTextInputChange = (props) => {
  const { event, selectedField } = props;
  const inputValue = event.target.value;
  const position = event.target.element?.selectionStart;
  props?.setCursorPosition(position);
  props?.setSelectedItem(selectedField);
  props?.setFields((prev) => {
    return {
      ...prev,
      [selectedField]: inputValue,
    };
  });
};

const TimeField = (props) => {
  const defaultValue = new Date();
  defaultValue.setHours(0, 0, 0, 0);
  const selectedField = `${props?.selectedField}`;
  const selectedValue = props?.fields?.operatingHours?.[selectedField]?.[0];
  const openTime = selectedValue?.Open;
  const openDate = new Date();
  const [openHours, openMinutes] = openTime?.split(":")?.map(Number) || [0, 0];

  // Set the hours and minutes
  openDate.setHours(openHours);
  openDate.setMinutes(openMinutes);

  const closeTime = selectedValue?.Close;
  const closeDate = new Date();
  const [closeHours, closeMinutes] = closeTime?.split(":")?.map(Number) || [
    0, 0,
  ];

  // Set the hours and minutes
  closeDate.setHours(closeHours);
  closeDate.setMinutes(closeMinutes);
  let error = openHours > closeHours;
  if (!error) {
    error = openHours === closeHours && openMinutes > closeMinutes;
  }

  return (
    <div className="openingHrsRow">
      <span className="dateLbl">{props?.label}</span>
      <span className="dateVal">
        <span className="timePicker">
          <TimePicker
            format="HH:mm"
            placeholder="HH:mm"
            defaultValue={defaultValue}
            disabled={props?.readOnly}
            value={openDate}
            onChange={(e) => {
              if (e.nativeEvent.type === "click") {
                props?.onTimeChange({
                  ...e,
                  type: "Open",
                  defaultValue: defaultValue,
                  selectedField: selectedField,
                  selectedValue: selectedValue,
                });
              } else if (!e.target.value) {
                props?.onTimeChange({
                  ...e,
                  type: "ignore",
                  defaultValue: defaultValue,
                  selectedField: selectedField,
                  selectedValue: selectedValue,
                });
              }
            }}
          />
        </span>{" "}
        -
        <span className="timePicker">
          <TimePicker
            format="HH:mm"
            placeholder="HH:mm"
            defaultValue={defaultValue}
            disabled={props?.readOnly}
            value={closeDate}
            onChange={(e) => {
              if (e.nativeEvent.type === "click") {
                props?.onTimeChange({
                  ...e,
                  type: "Close",
                  defaultValue: defaultValue,
                  selectedField: selectedField,
                  selectedValue: selectedValue,
                });
              } else if (!e.target.value) {
                props?.onTimeChange({
                  ...e,
                  type: "ignore",
                  defaultValue: defaultValue,
                  selectedField: selectedField,
                  selectedValue: selectedValue,
                });
              }
            }}
          />
        </span>
        {error && (
          <Tooltip openDelay={100} position="left" anchorElement="target">
            <img
              className="errorIcon"
              title="Opening hours should be less than the Closing hours."
              src={require("../../../../../assets/error-cross.png")}
              alt="Warning"
            />
          </Tooltip>
        )}
      </span>
    </div>
  );
};

const TextAreaField = (props) => {
  const selectedField = `${props?.selectedField}`;
  const selectedValue = props?.fields?.[selectedField];
  const sameItem = props?.selectedItem === selectedField;
  const onChange = (e) => {
    const position = e.target.element.current.selectionStart;
    props?.setCursorPosition(position);
    props?.setSelectedItem(selectedField);
    props?.setFields((prev) => {
      return {
        ...prev,
        [selectedField]: e.value,
      };
    });
  };

  return (
    <TextArea
      ref={sameItem ? props?.inputRef : null}
      className="remarksTextArea"
      disabled={props?.readOnly}
      onChange={onChange}
      maxLength={250}
      placeholder="Add remarks..."
      value={selectedValue}
    />
  );
};

const SiteBasicDetails = (props) => {
  const siteItem = props?.selectedItem;
  const readOnly = props?.readOnly;
  const siteStatuses = props?.siteStatuses;
  const regionList = props?.regionList;
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [currentOffSet, setCurrentOffSet] = useState();
  const [showError, setShowError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const inputRef = useRef(null);
  const [cursorPosition, setCursorPosition] = useState(null);
  const [fields, setFields] = useState({
    regions: regionList,
    selectedRegion: siteItem?.Region,
    selectedPlant: siteItem?.Plant,
    selectedCity: siteItem?.City,
    selectedSloc: siteItem?.Sloc,
    status: siteStatuses,
    selectedStatus: siteItem?.SiteStatus,
    openingDate: new Date(),
    closingDate: null,
    operatingHours: siteItem?.OperatingHours,
    remarks: siteItem?.Remarks,
    MdmConfigReceivedOn:
      siteItem?.MdmConfigReceivedOn &&
      getFormattedDateAndTime(new Date(siteItem?.MdmConfigReceivedOn)),
    SiteConfigReceivedOn:
      siteItem?.SiteConfigReceivedOn &&
      getFormattedDateAndTime(new Date(siteItem?.SiteConfigReceivedOn)),
  });

  useEffect(() => {
    inputRef?.current?.focus();
    setTimeout(() => {
      if (inputRef?.current?.element?.setSelectionRange) {
        inputRef?.current?.element?.setSelectionRange(
          cursorPosition,
          cursorPosition
        );
      } else {
        inputRef?.current?.element?.current?.setSelectionRange(
          cursorPosition,
          cursorPosition
        );
      }
    }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  useEffect(() => {}, [fields?.selectedShipTo]);

  const onTimeChange = (propValues) => {
    if (propValues?.type === "ignore") {
      setFields((prev) => {
        return {
          ...prev,
        };
      });
    } else {
      const date = propValues.target.value
        ? new Date(propValues?.target.value)
        : propValues?.defaultValue;
      setFields((prev) => {
        setSelectedItem(propValues?.selectedField);
        const hours = date?.getHours()?.toString()?.padStart(2, "0");
        const minutes = date?.getMinutes()?.toString()?.padStart(2, "0");
        const time = `${hours}:${minutes}`;
        return {
          ...prev,
          operatingHours: {
            ...fields?.operatingHours,
            [propValues.selectedField]: [
              { ...propValues?.selectedValue, [propValues?.type]: time },
            ],
          },
        };
      });
    }
  };

  const showHeader = () => {
    return (
      <div className="deliveryHeader">
        <div className="title">{`Site Details - ${siteItem?.GlobalSiteId}`}</div>
      </div>
    );
  };

  const showContent = () => {
    return (
      <div className="deliveryContent">
        <div className="leftContainer">
          <LabelField label={UIText.Site_Name} value={siteItem?.Name} />
          <LabelField label={UIText.ShipTo} value={siteItem?.GlobalSiteId} />
          <LabelField label={UIText.Site_Id} value={siteItem?.SiteId} />
          <LabelField label={UIText.OBN} value={siteItem?.OBN} />
          <LabelField label={UIText.Country} value={siteItem?.PartitionKey} />
          <LabelField
            label={UIText.Last_Site_POS_Updates_Received_On}
            value={fields?.SiteConfigReceivedOn}
          />
          <LabelField
            label={UIText.Last_MDM_Updates_Received_On}
            value={fields?.MdmConfigReceivedOn}
          />

          <TextInputField
            label={UIText.City}
            selectedField="selectedCity"
            fields={fields}
            setCursorPosition={setCursorPosition}
            setSelectedItem={setSelectedItem}
            setFields={setFields}
            selectedItem={selectedItem}
            showError={showError}
            inputRef={inputRef}
            readOnly={readOnly}
            onChange={onTextInputChange}
          />
          <TextInputField
            label={UIText.Plant}
            selectedField="selectedPlant"
            mandatory
            fields={fields}
            setCursorPosition={setCursorPosition}
            setSelectedItem={setSelectedItem}
            setFields={setFields}
            selectedItem={selectedItem}
            showError={showError}
            inputRef={inputRef}
            readOnly={readOnly}
            onChange={onTextInputChange}
          />
          <TextInputField
            label={UIText.SLOC}
            selectedField="selectedSloc"
            mandatory
            fields={fields}
            setCursorPosition={setCursorPosition}
            setSelectedItem={setSelectedItem}
            setFields={setFields}
            selectedItem={selectedItem}
            showError={showError}
            inputRef={inputRef}
            readOnly={readOnly}
            onChange={onTextInputChange}
          />
          <DropDownField
            label={UIText.Region}
            field="regions"
            selectedField="selectedRegion"
            filterable
            mandatory
            fields={fields}
            readOnly={readOnly}
            setSelectedItem={setSelectedItem}
            setFields={setFields}
          />
          <DropDownField
            label={UIText.Status}
            field="status"
            selectedField="selectedStatus"
            mandatory
            fields={fields}
            readOnly={readOnly}
            setSelectedItem={setSelectedItem}
            setFields={setFields}
          />
          
        </div>
        <div className="rightContainer">
        <DateField
            label={UIText.Opening_Date}
            selectedField="openingDate"
            mandatory
            fields={fields}
            setFields={setFields}
            setSelectedItem={setSelectedItem}
            readOnly={readOnly}
            placeholder={""}
            onDateChange={onDateChange}
          />
          {fields?.selectedStatus?.toLowerCase()?.includes("closed") && (
            <DateField
              label={UIText.Closing_Date}
              selectedField="closingDate"
              mandatory
              fields={fields}
              setFields={setFields}
              setSelectedItem={setSelectedItem}
              readOnly={readOnly}
              placeholder={""}
              min={fields?.openingDate}
              onDateChange={onDateChange}
            />
          )}
          <div className="openingHourTitle">{UIText.Opening_Hours}:</div>
          {fields?.operatingHours ? (
            <div className="openingHourContainer">
              <TimeField
                label={UIText.Sunday}
                selectedField="Sun"
                fields={fields}
                readOnly={readOnly}
                onTimeChange={onTimeChange}
              />
              <TimeField label={UIText.Monday} selectedField="Mon" />
              <TimeField label={UIText.Tuesday} selectedField="Tue" />
              <TimeField label={UIText.Wednesday} selectedField="Wed" />
              <TimeField label={UIText.Thursday} selectedField="Thu" />
              <TimeField label={UIText.Friday} selectedField="Fri" />
              <TimeField label={UIText.Saturday} selectedField="Sat" />
            </div>
          ) : (
            <div className="openingHourNA">{UIText.Not_Available}</div>
          )}
          <div className="remarksContainer">
            <TextAreaField
              fields={fields}
              selectedItem={selectedItem}
              setCursorPosition={setCursorPosition}
              setSelectedItem={setSelectedItem}
              setFields={setFields}
              inputRef={inputRef}
              readOnly={readOnly}
            />
          </div>
        </div>
      </div>
    );
  };

  const isValid = () => {
    const allValid =
      fields?.selectedPlant?.length > 0 && fields?.selectedSloc?.length > 0;

    setShowError(!allValid);
    return allValid;
  };

  const showSubmitButton = () => {
    return (
      <div className="submit-btn-container">
        {!readOnly && (
          <Button
            className="submit-btn"
            disabled={readOnly}
            onClick={(event) => {
              if (isValid()) {
                const x = event.clientX;
                const y = event.clientY;
                const offset = {
                  left: x,
                  top: y,
                };
                setCurrentOffSet(offset);
                setShowConfirmationPopup(true);
              }
            }}
          >
            {submitting ? UIText.Submitting : UIText.Save}
          </Button>
        )}

        <Button
          className="close-btn"
          onClick={() => {
            props?.cancel();
          }}
        >
          {UIText.Close}
        </Button>
      </div>
    );
  };

  const createPayload = () => {
    const closingDate = fields?.closingDate
      ? getFormattedDate(fields?.closingDate)
      : "";
    const openingDate = fields?.openingDate
      ? getFormattedDate(fields?.openingDate)
      : "";
    const payload = {
      CountryCode: siteItem?.Country,
      GlobalSiteId: siteItem?.GlobalSiteId,
      IsRKSite: siteItem?.IsRKSite,
      Name: siteItem?.Name,
      PLANT: fields?.selectedPlant,
      PartitionKey: siteItem?.PartitionKey,
      Region: fields?.selectedRegion,
      SLOC: fields?.selectedSloc,
      SiteId: siteItem?.SiteId,
      SiteStatus: fields?.selectedStatus,
      OperatingHours: fields?.operatingHours,
      City: fields?.selectedCity,
      Remarks: fields?.remarks,
      SiteClosingDate: closingDate,
      SiteOpeningDate: openingDate,
      TankInfo: siteItem?.TankInfo,
    };

    return payload;
  };

  const saveChanges = async () => {
    setShowConfirmationPopup(false);
    setSubmitting(true);
    const payload = createPayload();
    const response = await siteService.updateSiteInfo(
      siteItem?.Country,
      siteItem?.GlobalSiteId,
      payload
    );
    if (
      response?.status >= NUMBERS.TWO_HUNDRED &&
      response?.status < NUMBERS.THREE_HUNDRED
    ) {
      triggerSuccessNotification(response?.data?.message || response?.data);
      props?.save(payload);
    } else {
      triggerErrorNotification(UIText.Failed_to_save_site_information);
    }
    setSubmitting(false);
  };

  return (
    <div className="popupContainer">
      <div className="deliveryForm">
        {showHeader()}
        {showContent()}
        {showSubmitButton()}
        {showConfirmationPopup && (
          <ConfirmPopup
            offset={currentOffSet}
            position="top"
            isOpen={showConfirmationPopup}
            title={UIText.Are_you_sure_you_want_to_update_the_site_information}
            message={UIText.This_change_would_impact_the_existing_report_data}
            saveAction={() => {
              if (isValid()) {
                saveChanges();
              }
            }}
            cancelAction={(e) => {
              if (e.target.textContent !== "YES") {
                setShowConfirmationPopup(false);
              }
            }}
          />
        )}

        {submitting && <LoadingPanel message={UIText.Submitting_the_values} />}
      </div>
    </div>
  );
};

export { SiteBasicDetails };
