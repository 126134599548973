import "./issue-add.scss";
import { Button, DatePicker, Input } from "@progress/kendo-react-all";
import { SearchableDropdown } from "../../../../../components/searchable-dropdown/searchable-dropdown";
import { TextArea } from "@progress/kendo-react-inputs";
import { useState, useEffect } from "react";

import { issueService } from "../../../../../../services/issue";
import { store } from "../../../../../../state-management/store";
import { handleErrors } from "../IssueUtils";
import { useSelector } from "react-redux";
import { getFormattedFullDate } from "../../../../../../services/utils";
import { LoadingPanel } from "../../../../../components/loading-panel/loading-panel";
import {
  allOption,
  IssueRootCause,
  NUMBERS,
  triggerErrorNotification,
  triggerSuccessNotification,
} from "../../common-utils";
import { UIText } from "../../label-constants";
import { getMaterialsOfSite, getShipTos } from "../../../reportUtils";

const AddIssueScreen = (props) => {
  const [payload, setPayload] = useState(null);
  const [addingIssue, setAddingIssue] = useState(false);
  const defaultCountry = useSelector(
    (state) => state?.reports?.selectedCountry
  );
  const [currentCountryCode, setCurrentCountryCode] = useState(defaultCountry);

  store.subscribe(() => {
    const { selectedCountry } = store.getState().reports;
    setCurrentCountryCode(selectedCountry);
  });

  const updatePayload = (e) => {
    setPayload(e);
  };

  const addNewIssue = async () => {
    const {
      action,
      actionParty,
      status,
      loggedDate,
      raisedDate,
      closedDate,
      shipTo,
      plant,
      sloc,
      selectedShipTo,
      selectedMaterials,
      batch,
      variance,
      natureOfScope,
      description,
      plantCode,
      siteDate,
      gsap,
    } = payload;
    let newIssue = {
      PartitionKey: currentCountryCode,
      actionParty: actionParty,
      action: action,
      status: status,
      transactionDate: loggedDate?.toISOString(),
      raisedDate: raisedDate?.toISOString(),
      shipTo: shipTo,
      plant: plant,
      sloc: sloc,
      globalSiteId: selectedShipTo?.[0]?.id,
      siteName: selectedShipTo?.[0]?.text,
      materialCode: selectedMaterials?.[0]?.id,
      materialName: selectedMaterials?.[0]?.id,
      batch: batch,
      variance: parseFloat(variance),
      natureOfOpenIssue: natureOfScope,
      issueDescription: description,
      plantCode: plantCode,
      siteDate: siteDate,
      gsap: gsap,
    };

    if (status === "Closed") {
      newIssue = {
        ...newIssue,
        closedDate: closedDate?.toISOString(),
      };
    }

    if (!newIssue?.globalSiteId) {
      triggerErrorNotification(UIText.Ship_To_is_mandatory_field);

      return;
    }

    if (!newIssue?.materialCode) {
      triggerErrorNotification(UIText.MaterialCode_is_mandatory_field);

      return;
    }
    if (variance?.length <= 0) {
      triggerErrorNotification(UIText.Variance_is_mandatory_field);

      return;
    }

    if (
      !newIssue?.transactionDate ||
      newIssue?.transactionDate?.length <= NUMBERS.ZERO
    ) {
      triggerErrorNotification(UIText.Transaction_Date_is_mandatory_field);
      return;
    }
    setAddingIssue(true);
    try {
      const response = await issueService.createOrUpdateIssue(
        newIssue,
        currentCountryCode
      );
      if (
        response?.data ||
        (response?.status >= NUMBERS.TWO_HUNDRED &&
          response?.status < NUMBERS.THREE_HUNDRED)
      ) {
        triggerSuccessNotification(UIText.Issue_added_successfully);

        props?.onSave();
      } else {
        handleErrors(response);
      }
    } catch (error1) {
      triggerErrorNotification(
        UIText.Server_error_Unable_to_submit_the_request
      );
    }
    setAddingIssue(false);
  };

  const showHeader = () => {
    return (
      <div className="deliveryHeader">
        <div className="title">{UIText.Add_New_Issue}</div>
      </div>
    );
  };

  const showSubmitButton = () => {
    return (
      <div className="submit-btn-container">
        <Button className="submit-btn" onClick={addNewIssue}>
          {UIText.Save}
        </Button>
        <Button className="btn-cancel" onClick={props.onClose}>
          {UIText.Cancel}
        </Button>
      </div>
    );
  };

  return (
    <div className="new-issue-screen">
      <div className="issueContainer">
        {showHeader()}
        <NewIssueTable
          regions={props?.regions}
          onChange={updatePayload}
          selectedCountry={currentCountryCode}
        />
        {showSubmitButton()}
      </div>

      {addingIssue && (
        <span className="loader-container">
          <LoadingPanel message={UIText.Adding_the_issue} />
        </span>
      )}
    </div>
  );
};

const NewIssueTable = (props) => {
  const newIssueSchema = {
    actionParty: "CWR",
    action: "",
    status: "Open",
    loggedDate: new Date(),
    raisedDate: new Date(),
    closedDate: new Date(),
    shipTo: "",
    plant: "",
    sloc: "",
    regions: [],
    selectedRegion: [],
    selectedShipTo: null,
    allSiteIds: [],
    selectedMaterials: null,
    materials: [],
    batch: "",
    plantCode: "",
    siteDate: "",
    gsap: "",
    variance: "",
    natureOfScope: "Unknown",
    description: "",
  };
  const [newIssue, setNewIssue] = useState(newIssueSchema);

  const updateRegionValues = async () => {
    const regions = props?.regions?.map((item) => ({
      id: item?.region,
      text: item?.region,
      sites: item?.sites,
    }));
    const selectedRegion =
      regions?.length > NUMBERS.ONE ? [allOption] : regions;
    const regionList =
      regions?.length > NUMBERS.ONE ? [allOption, ...regions] : regions;
    setNewIssue((prev) => {
      return { ...prev, regions: regionList, selectedRegion };
    });
  };

  useEffect(() => {
    const allSiteIds = getShipTos(
      props?.regions,
      newIssue.selectedRegion,
      false
    );
    setNewIssue((prev) => {
      return { ...prev, allSiteIds, selectedShipTo: [] };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newIssue?.selectedRegion]);

  useEffect(() => {
    const materials = getMaterialsOfSite(newIssue?.selectedShipTo?.[0]);
    setNewIssue((prev) => {
      return { ...prev, materials, selectedMaterials: [] };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newIssue?.selectedShipTo]);

  useEffect(() => {
    updateRegionValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.onChange(newIssue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newIssue]);

  return (
    <div className="issueContent">
      <div className="row">
        <div className="labelContainer">{UIText.Raised_Date}</div>
        <div className="valueContainer">
          {getFormattedFullDate(newIssue?.raisedDate)}
        </div>
      </div>
      <div className="row">
        <div className="labelContainer">{UIText.Action_Party}</div>
        <div className="valueContainer">
          <SearchableDropdown
            value={{ text: newIssue.actionParty }}
            onSelect={(e) =>
              setNewIssue((prev) => {
                return { ...prev, actionParty: e.value.text };
              })
            }
            data={[
              UIText.RSAA_WSFP,
              UIText.CWR,
              UIText.OTD,
              UIText.BTC,
              UIText.WS,
            ]}
          />
        </div>
      </div>
      <div className="row">
        <div className="labelContainer">{UIText.Action}</div>
        <div className="valueContainer">
          <Input
            maxLength={NUMBERS.HUNDRED}
            onChange={(e) =>
              setNewIssue((prev) => {
                return { ...prev, action: e.value };
              })
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="labelContainer">{UIText.CWR_Status}</div>
        <div className="valueContainer">
          <SearchableDropdown
            value={{ text: newIssue.status }}
            onSelect={(e) =>
              setNewIssue((prev) => {
                return { ...prev, status: e.value.text };
              })
            }
            data={[UIText.Open, UIText.Monitoring, UIText.Closed]}
          />
        </div>
      </div>
      <div className="row">
        <div className="labelContainer required">{UIText.Transaction_Date}</div>
        <div className="valueContainer">
          <DatePicker
            value={newIssue.loggedDate}
            onChange={(e) =>
              setNewIssue((prev) => {
                return { ...prev, loggedDate: e.value };
              })
            }
          />
        </div>
      </div>
      {newIssue.regions?.length > NUMBERS.ONE && (
        <div className="row">
          <div className="labelContainer required">{UIText.Region}</div>
          <div className="valueContainer">
            <SearchableDropdown
              onSelect={(e) =>
                setNewIssue((prev) => {
                  const selectedRegion = newIssue.regions.filter(
                    (region) => region?.text === e.value.text
                  );
                  return {
                    ...prev,
                    selectedRegion,
                  };
                })
              }
              value={{ text: newIssue?.selectedRegion?.[0]?.text }}
              data={newIssue.regions.map((data) => data.text)}
            />
          </div>
        </div>
      )}

      <div className="row">
        <div className="labelContainer required">{UIText.ShipTo}</div>
        <div className="valueContainer">
          <SearchableDropdown
            onSelect={(e) =>
              setNewIssue((prev) => {
                const selectedShipTo = newIssue.allSiteIds.filter(
                  (siteId) => siteId?.id === e.value.text
                );
                return {
                  ...prev,
                  selectedShipTo,
                };
              })
            }
            value={{ text: newIssue?.selectedShipTo?.[0]?.text }}
            data={newIssue.allSiteIds.map((data) => data.text)}
          />
        </div>
      </div>

      <div className="row">
        <div className="labelContainer required">{UIText.Material_No}</div>
        <div className="valueContainer">
          <SearchableDropdown
            onSelect={(e) =>
              setNewIssue((prev) => {
                const selectedMaterials = newIssue.materials.filter(
                  (material) => material?.text === e.value.text
                );
                return {
                  ...prev,
                  selectedMaterials,
                };
              })
            }
            textField="text"
            dataItemKey="id"
            value={{ text: newIssue?.selectedMaterials?.[0]?.text }}
            data={newIssue.materials.map((data) => data.text)}
          />
        </div>
      </div>
      <div className="row">
        <div className="labelContainer required">{UIText.Variance}</div>
        <div className="valueContainer">
          <Input
            onChange={(e) =>
              setNewIssue((prev) => {
                return { ...prev, variance: e.value };
              })
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="labelContainer">{UIText.Batch}</div>
        <div className="valueContainer">
          <Input
            maxLength={30}
            onChange={(e) =>
              setNewIssue((prev) => {
                return { ...prev, batch: e.value };
              })
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="labelContainer">{UIText.GSAP}</div>
        <div className="valueContainer">
          <Input
            onChange={(e) =>
              setNewIssue((prev) => {
                return { ...prev, gsap: e.value };
              })
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="labelContainer">{UIText.Nature_of_scope}</div>
        <div className="valueContainer">
          <SearchableDropdown
            onSelect={(e) =>
              setNewIssue((prev) => {
                return { ...prev, natureOfScope: e.value.text };
              })
            }
            value={{ text: newIssue.natureOfScope }}
            data={[
              IssueRootCause.Blocked_Gauges_Probe_Issue,
              IssueRootCause.Confirmed_PLIP,
              IssueRootCause.Confirmed_ATG_Defect,
              IssueRootCause.WSMA_system_Issue,
              IssueRootCause.Invalid_Overstated_Delivery,
              IssueRootCause.Missing_Confirmed_Deliveries,
              IssueRootCause.Missing_Understated_Delivery,
              IssueRootCause.Missing_Understated_Sales,
              IssueRootCause.Possible_ATG_Defect,
              IssueRootCause.No_End_Stock_Data,
              IssueRootCause.Possible_PLIP,
              IssueRootCause.Possible_Test_Pump,
              IssueRootCause.Site_system_Issue,
              IssueRootCause.Temporarily_Closed_Site,
              IssueRootCause.Timing_difference_on_delivery,
              IssueRootCause.Waiting_for_confirmed_delivery,
              IssueRootCause.Unknown,
            ]}
          />
        </div>
      </div>

      <div className="row">
        <div className="labelContainer">
          {UIText.Issue_Description_Updates_250_Chars_Max}
        </div>
        <div className="valueContainer">
          <TextArea
            onChange={(e) =>
              setNewIssue((prev) => {
                return { ...prev, description: e.value };
              })
            }
            maxLength={NUMBERS.TWO_FIVE_ZERO}
            placeholder={UIText.Write_an_issue_description}
          />
        </div>
      </div>
    </div>
  );
};

export { AddIssueScreen };
