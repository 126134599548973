import { NUMBERS, triggerErrorNotification } from "../common-utils";
import { UIText } from "../label-constants";

export const handleErrors = (response) => {
  const errorResponse = response?.response;
  const status = errorResponse?.data?.status;
  const errorDetail = errorResponse?.data?.detail;
  const errorObj = errorResponse?.data?.errors;
  if (status >= NUMBERS.FOUR_HUNDRED && status < NUMBERS.FIVE_HUNDRED) {
    if (errorDetail) {
      triggerErrorNotification(errorDetail);
    } else if (errorObj) {
      Object.keys(errorObj)?.forEach((errorKey) => {
        const message = errorObj?.[errorKey]?.[0];
        if (message?.length > 0) {
          triggerErrorNotification(message);
        }
      });
    }
  } else {
    triggerErrorNotification(
      UIText.Unexpected_error_occurred_Please_try_after_some_time
    );
  }
};
