import sha256 from "crypto-js/sha256";
import qs from "qs";
import base64 from "crypto-js/enc-base64";

import { appConfig } from "../configs/app-config";
import { httpService } from "../services/http";
import { tokenManager } from "./token-manager";
import { userService } from "./user";
import { loggerService } from "../services/logger";

import { store } from "../state-management/store";
import {
  setAccessToken,
  setRefreshToken,
  setIdToken,
  setUserScreenList,
} from "../state-management/actions";
import { API } from "./utils";
import {
  goToLoginPage,
  NINE_MINS,
  NUMBERS,
  REFRESH_MS,
  triggerErrorNotification,
  triggerSuccessNotification,
} from "../ui/pages/reports/components/common-utils";
import { UIText } from "../ui/pages/reports/components/label-constants";

/**
 * This service will help the use log into the system depending on what kind of user they are.
 */
class LoginService {
  CODE_VERIFIER = "";
  CODE_CHALLENGE = "";
  ACCESS_TOKEN = "";
  REFRESH_TOKEN = "";
  ID_TOKEN = "";

  PING = appConfig.auth.ping;
  CIPM = appConfig.auth.cipm;

  urlContentType = "application/x-www-form-urlencoded";

  /**
   * This function Generates a code verifier which is a random string of 43 chars, it will be used for the PKCE login flow.
   * @param   {number} length - The length of the string
   */
  generateCodeVerifier(length) {
    let text = "";
    const possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  /**
   * This function Generates a code challenge corrosponding to the code verifier. It is used in the PKCE login flow.
   */
  generateCodeChallenge() {
    const codeLength = 43;
    this.CODE_VERIFIER = this.generateCodeVerifier(codeLength);
    return this.base64URL(sha256(this.CODE_VERIFIER));
  }

  /**
   * A utility function that encodes the verifier to base 64 encoding.
   * @param   {string} verifier  - The code verifier that needs to be encoded.
   */
  base64URL(verifier) {
    return verifier
      .toString(base64)
      .replace(/=/g, "")
      .replace(/\+/g, "-")
      .replace(/\//g, "_");
  }

  /**
   * A function that helps the shell user log into the system by redirecting them to the PING's login portal.
   * This is a PKCE flow, to learn more about it check out this video. https://youtu.be/yf2Hge3VHKY
   */
  shellUserLogin() {
    this.CODE_CHALLENGE = this.generateCodeChallenge();
    const loginPayload = {
      loginType: "shell",
      codeVerifier: this.CODE_VERIFIER,
    };
    localStorage.setItem("loginPayload", JSON.stringify(loginPayload));

    const {
      baseUrl,
      clientId,
      redirectUrl,
      responseType,
      codeChallengeMethod,
      authorizationGrantType,
    } = this.PING;
    const URL = `${baseUrl}/as/authorization.oauth2?client_id=${clientId}&redirect_uri=${redirectUrl}&response_type=${responseType}&code_challenge=${this.CODE_CHALLENGE}&code_challenge_method=${codeChallengeMethod}&grant_type=${authorizationGrantType}`;
    window.location.replace(URL);
  }

  /**
   * A function that helps the shell user log into the system by redirecting them to the PING's login portal.
   * This is a PKCE flow, to learn more about it check out this video. https://youtu.be/yf2Hge3VHKY
   */
  nonShellUserLogin() {
    this.CODE_CHALLENGE = this.generateCodeChallenge();
    const loginPayload = {
      loginType: "nonshell",
      codeVerifier: this.CODE_VERIFIER,
    };
    localStorage.setItem("loginPayload", JSON.stringify(loginPayload));

    const { baseUrl, clientId, redirectUrl } = this.CIPM;

    const URL = `${baseUrl}/authorize?redirect_uri=${redirectUrl}&client_id=${clientId}&response_type=code&state=f86C4Bgtc4&scope=openid&ui_locales=en-US&code_challenge=${this.CODE_CHALLENGE}&code_challenge_method=S256`;
    window.location.replace(URL);
  }
  async nonShellUserLogout() {
    const state = store.getState()?.login;
    const token = state.access_token;

    const response = await httpService.post(
      `${appConfig.auth.cipm.baseUrl}/idp/v1/account/logout`,
      { client_id: appConfig.auth.cipm.clientId, all_sessions: true },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const URL = `${appConfig.auth.cipm.baseUrl}/endsession?post_logout_redirect_uri=${appConfig.auth.cipm.redirectUrl}&client_id=${appConfig.auth.cipm.clientId}`;
    window.location.replace(URL);

    if (response.Error) {
      return response;
    }
    return response?.data;
  }
  async logOutUser() {
    const state = store.getState()?.login;
    const token = state.access_token;
    return httpService.get(`/userdetails/Logout`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }

  /**
   * This function continues the login flow after redirection.
   * i.e When the user is redirected back to the website after they've logged into PING.
   */
  async handelUserAfterPINGRedirection(code = null, codeVerifier = null) {
    if (!code || !codeVerifier) {
      return null;
    }
    const shellToken = await this.tryFetchShellToken(code, codeVerifier);
    if (shellToken) {
      const decodedToken = tokenManager.decodeToken(shellToken);
      if (decodedToken?.hasOwnProperty("uid")) {
        const UID = decodedToken.uid;
        const shellUser = await userService.fetchUser(UID);
        if (shellUser?.hasOwnProperty("UserFirstName")) {
          triggerSuccessNotification(
            `Welcome ${shellUser?.UserFirstName}. You're now logged in.`
          );
          store.dispatch(setUserScreenList(shellUser?.ScreenList));
          return shellUser;
        }
      }
    }
    triggerErrorNotification(UIText.Unable_to_fetch_user);
    return null;
  }

  /**
   * This function continues the login flow after redirection.
   * i.e When the user is redirected back to the website after they've logged into CIPM.
   */
  async handelUserAfterCIPMRedirection(code = null, codeVerifier = null) {
    if (!code || !codeVerifier) {
      return null;
    }
    const nonShellToken = await this.tryFetchNonShellToken(code, codeVerifier);
    if (nonShellToken) {
      const decodedToken = tokenManager.decodeToken(nonShellToken);
      if (decodedToken?.hasOwnProperty("sub")) {
        const UID = decodedToken.sub;
        const nonShellUser = await userService.fetchUser(UID);
        if (nonShellUser?.hasOwnProperty("UserFirstName")) {
          triggerSuccessNotification(
            `Welcome ${nonShellUser.UserFirstName}. You're now logged in.`
          );

          return nonShellUser;
        }
      }
    }
    triggerErrorNotification(UIText.Unable_to_fetch_user);
    return null;
  }

  /**
   * A function that exchanges the auth code with PING's server to get a JWT token.
   * @param {string} code  - The auth code from you get in query params, after redirection.
   * @param {string} codeVerifier  - The code verifier that got generated when user initiated this login.
   */
  async tryFetchShellToken(code = null, codeVerifier = null) {
    const { clientId, redirectUrl, authorizationGrantType, baseUrl } =
      this.PING;

    const payload = {
      client_id: clientId,
      code,
      code_verifier: codeVerifier,
      redirect_uri: redirectUrl,
      grant_type: authorizationGrantType,
    };

    const headers = {
      headers: {
        "Content-Type": this.urlContentType,
      },
      loginTokenCall: true,
    };

    const tokenUrl = `${baseUrl}/as/token.oauth2`;

    try {
      const response = await httpService.post(
        tokenUrl,
        qs.stringify(payload),
        headers
      );
      if (response?.hasOwnProperty("data")) {
        this.ACCESS_TOKEN = response.data.access_token;
        this.REFRESH_TOKEN = response.data.refresh_token;

        store.dispatch(setAccessToken(this.ACCESS_TOKEN));
        store.dispatch(setRefreshToken(this.REFRESH_TOKEN));
        this.initiateSilentRefreshOfPingToken();
        return this.ACCESS_TOKEN;
      }
    } catch (err) {
      triggerErrorNotification(
        appConfig.isDev && err?.hasOwnProperty("message")
          ? `${UIText.Unable_to_fetch_shell_token} : ${err.message}`
          : UIText.Something_went_wrong
      );

      loggerService.dev(err);
    }
    return null;
  }

  async refreshAuthToken() {
    const refresh_token = store.getState()?.login?.refresh_token;
    const { clientId, baseUrl } = appConfig.auth.ping;
    const payload = {
      client_id: clientId,
      grant_type: "refresh_token",
      refresh_token: refresh_token,
    };
    const pingTokenUrl = `${baseUrl}/as/token.oauth2`;
    const options = {
      headers: {
        "Content-Type": this.urlContentType,
      },
      loginTokenCall: true,
    };
    const response = await httpService.post(
      pingTokenUrl,
      qs.stringify(payload),
      options
    );
    if (
      response &&
      response?.status >= NUMBERS.TWO_HUNDRED &&
      response?.status < NUMBERS.THREE_HUNDRED
    ) {
      this.REFRESH_TOKEN = response.data.refresh_token;
      this.ACCESS_TOKEN = response.data.access_token;

      store.dispatch(setAccessToken(this.ACCESS_TOKEN));
      store.dispatch(setRefreshToken(this.REFRESH_TOKEN));
      return true;
    } else {
      goToLoginPage();
      return false;
    }
  }

  initiateSilentRefreshOfPingToken() {
    setInterval(async () => {
      const { clientId, baseUrl } = appConfig.auth.ping;
      const payload = {
        client_id: clientId,
        grant_type: "refresh_token",
        refresh_token: this.REFRESH_TOKEN,
      };
      const pingTokenUrl = `${baseUrl}/as/token.oauth2`;
      const options = {
        headers: {
          "Content-Type": this.urlContentType,
        },
        loginTokenCall: true,
      };
      const response = await httpService.post(
        pingTokenUrl,
        qs.stringify(payload),
        options
      );
      this.REFRESH_TOKEN = response.data.refresh_token;
      this.ACCESS_TOKEN = response.data.access_token;

      store.dispatch(setAccessToken(this.ACCESS_TOKEN));
      store.dispatch(setRefreshToken(this.REFRESH_TOKEN));
    }, NINE_MINS * 1000); // Silently refresh the ping token every 15 min
  }

  initiateSilentRefreshOfCipmToken() {
    setInterval(async () => {
      const { clientId, baseUrl, redirectUrl } = appConfig.auth.cipm;
      const payload = {
        client_id: clientId,
        grant_type: "refresh_token",
        refresh_token: this.REFRESH_TOKEN,
        redirect_uri: redirectUrl,
        code_verifier: this.CODE_VERIFIER,
      };
      const cipmTokenUrl = `${baseUrl}/token`;
      const options = {
        headers: {
          "Content-Type": this.urlContentType,
        },
      };
      const response = await httpService.post(
        cipmTokenUrl,
        qs.stringify(payload),
        options
      );
      this.REFRESH_TOKEN = response.data.refresh_token;
      this.ACCESS_TOKEN = response.data.access_token;

      store.dispatch(setAccessToken(this.ACCESS_TOKEN));
      store.dispatch(setRefreshToken(this.REFRESH_TOKEN));
    }, REFRESH_MS * 1000); // Silently refresh the cipm token every 1 hour
  }

  /**
   * A function that exchanges the auth code with PING server to get a JWT token.
   * @param {string} code  - The auth code from you get in query params, after redirection.
   * @param {string} codeVerifier  - The code verifier that got generated when user initiated this login.
   */
  async tryFetchNonShellToken(code = null, codeVerifier = null) {
    const { clientId, redirectUrl, baseUrl } = this.CIPM;

    const URL = `${baseUrl}/token?client_id=${clientId}&grant_type=authorization_code&code=${code}&redirect_uri=${redirectUrl}&code_verifier=${codeVerifier}`;

    try {
      const response = await httpService.post(URL);
      if (response?.hasOwnProperty("data")) {
        const { id_token, access_token, refresh_token } = response.data;
        this.ACCESS_TOKEN = access_token;
        this.REFRESH_TOKEN = refresh_token;
        this.ID_TOKEN = id_token;
        store.dispatch(setAccessToken(this.ACCESS_TOKEN));
        store.dispatch(setRefreshToken(this.REFRESH_TOKEN));
        store.dispatch(setIdToken(this.ID_TOKEN));
        this.initiateSilentRefreshOfCipmToken();
        return id_token;
      }
    } catch (err) {
      triggerErrorNotification(
        appConfig.isDev && err?.hasOwnProperty("message")
          ? `${UIText.Unable_to_fetch_non_shell_token} : ${err.message}`
          : UIText.Something_went_wrong
      );
      loggerService.dev(err);
    }
    return null;
  }

  async captureLoginAttempt(user) {
    const URL = `${API.userManagement}/User/LoginTracking/${user?.InternalUserId}`;
    try {
      const response = await httpService.post(URL);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
}

loggerService.dev("Creating Login Service");
const loginService = new LoginService();

loggerService.dev("Exporting Login Service");
export { loginService };
