import { calculateWidth } from "../../eod-summary-screen/eod-summary-utils";
import { CustomHeaderCell } from "../../account-for-deliveries/account-for-deliveries-utils";
import { DECIMAL } from "../../common-utils";
import { ActionButtonCell, ActionType, ShipToLinkCell } from "../../common-grid-cells";
import { UIText } from "../../label-constants";

export const getActionParties = () => {
  return [
    {
      id: UIText.All,
      text: UIText.All,
    },
    {
      id: UIText.RSAA_WSFP,
      text: UIText.RSAA_WSFP,
    },
    {
      id: UIText.CWR,
      text: UIText.CWR,
    },
    {
      id: UIText.OTD,
      text: UIText.OTD,
    },
    {
      id: UIText.BTC,
      text: UIText.BTC,
    },
    {
      id: UIText.WS,
      text: UIText.WS,
    },
  ];
};

export const getStatusList = () => {
  return [
    {
      id: UIText.All,
      text: UIText.All,
    },
    {
      id: UIText.Open,
      text: UIText.Open,
    },
    {
      id: UIText.Closed,
      text: UIText.Closed,
    },
  ];
};

export const colsToShow = (
  issues,
  handleIssueUpdate,
  handleShipToLinkAction
) => {
  const columns = [
    {
      title: UIText.Incident_Id,
      field: "incidentId",
      filterable: true,
      sortable: true,
      headerCell: CustomHeaderCell,
      width: calculateWidth("incidentId", issues) * DECIMAL.POINT_EIGHT_ZERO,
    },
    {
      title: UIText.Nature_of_issue,
      field: "natureOfOpenIssue",
      filterable: true,
      sortable: true,
      headerCell: CustomHeaderCell,
    },
    {
      title: UIText.ShipTo,
      field: "globalSiteId",
      filterable: true,
      sortable: true,
      headerCell: CustomHeaderCell,
      cell: (props) => {
        return (
          <ShipToLinkCell {...props} linkAction={handleShipToLinkAction} />
        );
      },
    },
    {
      title: UIText.Description,
      field: "issueDescription",
      filterable: true,
      sortable: true,
      headerCell: CustomHeaderCell,
    },
    {
      title: UIText.Status,
      field: "status",
      filterable: true,
      sortable: true,
      headerCell: CustomHeaderCell,
      width: calculateWidth("status", issues),
    },
    {
      title: UIText.Transaction_Date,
      field: "transactionDate",
      filterable: true,
      sortable: true,
      headerCell: CustomHeaderCell,
      width: calculateWidth("transactionDate", issues) * DECIMAL.ONE_POINT_TWO,
    },
    {
      title: UIText.Raised_Date,
      field: "raisedDate",
      filterable: true,
      sortable: true,
      headerCell: CustomHeaderCell,
      width: calculateWidth("raisedDate", issues) * DECIMAL.ONE_POINT_TWO,
    },
    {
      title: UIText.Action_Party,
      field: "actionParty",
      filterable: true,
      sortable: true,
      headerCell: CustomHeaderCell,
      width: calculateWidth("actionParty", issues) * DECIMAL.ONE_POINT_TWO,
    },
    {
      title: UIText.Actions,
      field: "",
      filterable: true,
      sortable: true,
      headerCell: CustomHeaderCell,
      cell: (props) => {
        return (
          <ActionButtonCell
            {...props}
            handleAction={handleIssueUpdate}
            title={"Click here to update the issue"}
            actionType={ActionType.Save}
          />
        );
      },
    },
  ];

  return columns;
};
