import { DropDownList } from "@progress/kendo-react-dropdowns";
import { addAllItem } from "../../reportUtils";
import { Button } from "@progress/kendo-react-buttons";
import { DECIMAL, isDachCluster } from "../common-utils";
import { getFormattedDate } from "../../../../../services/utils";
import { AFDTabOptions } from "./account-for-deliveries-screen";
import { store } from "../../../../../state-management/store";
import { ShipToLinkCell } from "../common-grid-cells";
import { UIText } from "../label-constants";

const getAllSlocs = (sites) => {
  let slocs = [];
  sites?.forEach((siteData) => {
    if (siteData?.sloc) {
      const isExist = slocs?.find((item) => item.id === siteData?.sloc);
      if (!isExist) {
        const sloc = {
          id: siteData?.sloc,
          text: siteData?.sloc,
        };
        slocs = [...slocs, sloc];
      }
    }
  });

  return slocs;
};

export const getRegionSiteIds = (regions, selectedRegion) => {
  const region = regions?.find(
    (regionData) => regionData?.region === selectedRegion
  );
  if (region) {
    const allSiteIds = region?.sites?.map((item) => ({
      id: item?.globalSiteId,
      text: item?.globalSiteId,
    }));
    return allSiteIds?.length > 0 ? allSiteIds : [];
  }
  return [];
};

export const getRegionForSiteId = (regions, siteId) => {
  let region = null;
  regions?.some((regionData) => {
    const currentSites = regionData?.sites;
    const siteFound = currentSites?.find(
      (site) => site?.globalSiteId === siteId
    );
    if (siteFound) {
      region = regionData?.region;
      return true;
    }
    return false;
  });
  if (region) {
    return [
      {
        id: region?.toUpperCase(),
        text: region?.toUpperCase(),
      },
    ];
  }
  return [];
};

const allRegionsSites = (filterData) => {
  let sites = [];
  filterData?.forEach((regionData) => {
    const currentSites = regionData?.sites;
    if (currentSites?.length > 0) {
      sites = [...sites, ...currentSites];
    }
  });

  return sites;
};

const getAllPlants = (sites) => {
  let plants = [];
  sites?.forEach((siteData) => {
    if (siteData?.plant) {
      const isExist = plants?.find((item) => item.id === siteData?.plant);
      if (!isExist) {
        const plant = {
          id: siteData?.plant,
          text: siteData?.plant,
        };
        plants = [...plants, plant];
      }
    }
  });

  return plants;
};

export const getSlocValues = (filterData, filters = null) => {
  const selectedRegion = filters?.selectedRegions?.[0];
  if (!selectedRegion) {
    return [];
  } else {
    let slocs = [];
    const sites = getPlantAndSlocSites(selectedRegion, filterData, filters);
    const selectedShipTo = filters?.selectedShipTo?.[0]?.id;

    if (selectedShipTo === "all") {
      slocs = getAllSlocs(sites);
    } else {
      slocs = getSlocsForSites(filters, sites, slocs);
    }
    slocs = slocs?.length > 1 ? addAllItem(slocs) : slocs;
    return slocs;
  }
};
export const getPlantValues = (
  filterData,
  filters = null,
  addAllOption = true
) => {
  const selectedRegion = filters?.selectedRegions?.[0];
  if (!selectedRegion) {
    return [];
  } else {
    let plants = [];
    const sites = getPlantAndSlocSites(selectedRegion, filterData, filters);

    const selectedShipTo = filters?.selectedShipTo?.[0]?.id;

    if (selectedShipTo === "all") {
      plants = getAllPlants(sites);
    } else {
      plants = getPlantsForSites(filters, sites, plants);
    }
    return plants?.length > 1 && addAllOption ? addAllItem(plants) : plants;
  }
};

const DropDownCell = (props) => {
  const {
    dataItem,
    field,
    onChange,
    commentsOptions,
    disabled = false,
  } = props;

  const handleDropDownChange = (event) => {
    onChange({
      dataItem: { ...dataItem, [field]: event.target.value },
      field: field,
      syntheticEvent: event,
    });
  };
  const options = commentsOptions;
  return (
    <td className="dropDownCellContainer">
      <DropDownList
        data={options}
        value={dataItem?.[field] || UIText.Select}
        onChange={handleDropDownChange}
        disabled={disabled}
      />
    </td>
  );
};

export const CustomHeaderCell = (props) => {
  const columnTitle = props?.columnMenuWrapperProps?.column?.title;
  const classNames = "k-customHeader";
  return (
    <th {...props}>
      <div className={classNames} title={props?.label || columnTitle}>
        {columnTitle}
        {props.children}
      </div>
    </th>
  );
};

export const colsToShow = (commentsOptions, handleShipToLinkAction) => {
  const columns = [
    {
      title: UIText.Document_Date,
      field: "documentDate",
      headerCell: CustomHeaderCell,
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.ShipTo,
      field: "shipTo",
      headerCell: CustomHeaderCell,
      cell: (props) => {
        return (
          <ShipToLinkCell {...props} linkAction={handleShipToLinkAction} />
        );
      },
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.Plant,
      field: "plant",
      headerCell: CustomHeaderCell,
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.SLOC,
      field: "sloc",
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.Material_No,
      field: "materialNumber",
      headerCell: CustomHeaderCell,
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.OutBound_Delivery_No,
      field: "outBoundDeliveryId",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * DECIMAL.POINT_ZERO_EIGHT_FIVE,
      filterable: true,
      sortable: true,
    },
    {
      title: isDachCluster()
        ? UIText.Calculated_Delivery
        : UIText.Detected_Delivery,
      field: "calculatedDelivery",
      headerCell: CustomHeaderCell,
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.Confirmed_Delivery,
      field: "confirmedDelivery",
      headerCell: CustomHeaderCell,
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.Difference,
      field: "difference",
      headerCell: CustomHeaderCell,
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.Comment,
      field: "comment",
      sortable: true,
      headerCell: CustomHeaderCell,
      cell: (props) => {
        return <DropDownCell {...props} commentsOptions={commentsOptions} />;
      },
      width: window.innerWidth * DECIMAL.POINT_ONE_TWO,
    },
  ];

  return columns;
};

export const auditColsToShow = (handleShipToLinkAction) => {
  const columns = [
    {
      title: UIText.Document_Date,
      field: "documentDate",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * DECIMAL.POINT_ZERO_SIX,
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.ShipTo,
      field: "shipTo",
      headerCell: CustomHeaderCell,
      cell: (props) => {
        return (
          <ShipToLinkCell {...props} linkAction={handleShipToLinkAction} />
        );
      },
      width: window.innerWidth * DECIMAL.POINT_ZERO_SIX,
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.Material_No,
      field: "materialNumber",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * DECIMAL.POINT_ZERO_SIX,
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.Tank,
      field: "tankId",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * DECIMAL.POINT_ZERO_SIX,
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.Entity,
      field: "entity",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * DECIMAL.POINT_ZERO_EIGHT,
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.OutBound_Delivery_No,
      field: "outBoundDeliveryId",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * DECIMAL.POINT_ZERO_NINE,
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.Confirmed_Delivery,
      field: "confirmedDelivery",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * DECIMAL.POINT_ZERO_SEVEN,
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.Comment,
      field: "comment",
      headerCell: CustomHeaderCell,
      sortable: true,
    },
    {
      title: UIText.Modified_By,
      field: "userName",
      filterable: true,
      sortable: true,
      editable: false,
      headerCell: CustomHeaderCell,
    },
    {
      title: UIText.Modified_On,
      field: "modifiedOn",
      filterable: true,
      sortable: true,
      editable: false,
      headerCell: CustomHeaderCell,
      width: window.innerWidth * DECIMAL.POINT_ZERO_EIGHT,
    },
  ];

  return columns;
};

export const colsToExport = () => {
  const columns = [
    {
      title: UIText.Plant,
      field: "plant",
    },
    {
      title: UIText.Document_Date,
      field: "documentDate",
    },
    {
      title: UIText.ShipTo,
      field: "shipTo",
    },
    {
      title: UIText.SLOC,
      field: "sloc",
    },
    {
      title: UIText.Material_No,
      field: "materialNumber",
    },
    {
      title: UIText.OutBound_Delivery_Ids,
      field: "outBoundDeliveryId",
    },
    {
      title: UIText.Tank_Material_Level,
      field: "type",
    },
    {
      title: UIText.Delivery_Start_Date,
      field: "deliveryStartDate",
    },
    {
      title: UIText.Start_Temperature,
      field: "startTemperature",
    },
    {
      title: UIText.Delivery_End_Date,
      field: "deliveryEndDate",
    },
    {
      title: UIText.End_Temperature,
      field: "endTemperature",
    },
    {
      title: UIText.Tank,
      field: "tank",
    },
    {
      title: isDachCluster()
        ? UIText.Calculated_Delivery
        : UIText.Detected_Delivery,
      field: "calculatedDelivery",
    },
    {
      title: UIText.Confirmed_Delivery,
      field: "confirmedDelivery",
    },
    {
      title: UIText.Difference,
      field: "difference",
    },
    {
      title: UIText.Comment,
      field: "comment",
    },
  ];

  return columns;
};

const EditDeliveryCell = (props) => {
  const { rowItem, field, dataItem } = props;
  const title = dataItem?.[field];
  const EditIcon = require("../../../../../assets/edit-icon.png");
  const className = title === UIText.Edit ? "edit-btn" : "cancel-btn";
  const saveEnabled = dataItem?.saveEnabled;
  return (
    <td style={{ paddingTop: "0.8vh" }}>
      <Button
        className={className}
        disabled={!saveEnabled && title === UIText.Save}
        onClick={(event) => {
          if (title === UIText.Save) {
            const x = event.clientX;
            const y = event.clientY;
            const offset = {
              left: x,
              top: y,
            };
            props?.onSave(rowItem, dataItem, offset);
          } else {
            props?.onEdit(rowItem, dataItem);
          }
        }}
      >
        {title === UIText.Edit ? (
          <img
            className="editIcon"
            src={EditIcon}
            alt={UIText.Edit}
            title={UIText.Edit_Details}
          />
        ) : (
          title
        )}
      </Button>
    </td>
  );
};

const CancelDeliveryCell = (props) => {
  const { rowItem, field, dataItem } = props;
  const title = dataItem?.[field];
  const className = "cancel-btn";
  return title === UIText.Cancel ? (
    <td style={{ paddingTop: "0.8vh" }}>
      <Button
        className={className}
        onClick={() => {
          props?.onCancel(rowItem, dataItem);
        }}
      >
        {title}
      </Button>
    </td>
  ) : (
    <td></td>
  );
};

export const detailColsToShow = ({ rowItem, onEdit, onSave, onCancel }) => {
  const columns = [
    {
      title: UIText.Delivery_Start_Time,
      field: "deliveryStartDate",
      headerCell: CustomHeaderCell,
      editable: false,
    },
    {
      title: UIText.Start_Temperature,
      field: "startTemperature",
      editable: false,
      headerCell: CustomHeaderCell,
    },
    {
      title: UIText.Delivery_End_Time,
      field: "deliveryEndDate",
      editable: false,
      headerCell: CustomHeaderCell,
    },
    {
      title: UIText.End_Temperature,
      field: "endTemperature",
      editable: false,
      headerCell: CustomHeaderCell,
    },
    {
      title: UIText.OutBound_Delivery_No,
      field: "outBoundDeliveryId",
      headerCell: CustomHeaderCell,
      editable: true,
      editor: "text",
    },
    {
      title: UIText.Tank,
      field: "tank",
      editable: false,
      headerCell: CustomHeaderCell,
    },
    {
      title: isDachCluster()
        ? UIText.Calculated_Delivery
        : UIText.Detected_Delivery,
      field: "calculatedDelivery",
      headerCell: CustomHeaderCell,
      editable: false,
    },
    {
      title: UIText.Confirmed_Delivery,
      field: "confirmedDelivery",
      headerCell: CustomHeaderCell,
      editable: true,
      editor: "text",
    },
    {
      title: UIText.Difference,
      field: "difference",
      editable: false,
      headerCell: CustomHeaderCell,
    },
    {
      title: "",
      field: "title",
      width: window.innerWidth * DECIMAL.POINT_ZERO_THREE_FIVE,
      headerCell: () => <></>,
      cell: (props) => {
        return (
          <EditDeliveryCell
            {...props}
            rowItem={rowItem}
            onEdit={onEdit}
            onSave={onSave}
          />
        );
      },
    },
    {
      title: "",
      field: "subTitle",
      headerCell: () => <></>,
      cell: (props) => {
        return (
          <CancelDeliveryCell
            {...props}
            rowItem={{}}
            childItem={{}}
            onCancel={onCancel}
          />
        );
      },
    },
  ];

  return columns;
};

const getPlantsForSites = (filters, sites, plants) => {
  filters?.selectedShipTo?.forEach((shipTo) => {
    const siteData = sites?.find((site) => site?.globalSiteId === shipTo.id);
    if (siteData?.plant) {
      const isExist = plants?.find((item) => item.id === siteData?.plant);
      if (!isExist) {
        const plant = {
          id: siteData?.plant,
          text: siteData?.plant,
        };
        plants = [...plants, plant];
      }
    }
  });
  return plants;
};

const getPlantAndSlocSites = (selectedRegion, filterData, filters) => {
  let sites = [];
  if (selectedRegion.id === "all") {
    sites = allRegionsSites(filterData);
  } else {
    filters?.selectedRegions?.forEach((currentRegion) => {
      const regionData = filterData?.find(
        (region) => region.region === currentRegion.id
      );
      const currentSites = regionData?.sites;
      if (currentSites?.length > 0) {
        sites = [...sites, ...currentSites];
      }
    });
  }
  return sites;
};

const getSlocsForSites = (filters, sites, slocs) => {
  filters?.selectedShipTo?.forEach((shipTo) => {
    const siteData = sites?.find((site) => site?.globalSiteId === shipTo.id);
    if (siteData?.sloc) {
      const isExist = slocs?.find((item) => item.id === siteData?.sloc);
      if (!isExist) {
        const sloc = {
          id: siteData?.sloc,
          text: siteData?.sloc,
        };
        slocs = [...slocs, sloc];
      }
    }
  });
  return slocs;
};

export const getAFDPayload = (filter) => {
  const regions = filter.selectedRegions?.map((region) => region?.text);
  const fromDate = filter.selectedDates.start;
  const toDate = filter.selectedDates.end;
  const startDate = fromDate && getFormattedDate(fromDate);
  const endDate = toDate && getFormattedDate(toDate);

  const currentCountries = filter.selectedRegions?.map(
    (region) => region?.countryCode
  );

  const countryCode = currentCountries?.[0];
  const payload = {
    startDate: startDate,
    endDate: endDate,
    countryCode: countryCode,
    regions,
    shipTos: filter.selectedShipTo?.map((shipTo) => shipTo.text),
    materialNos: filter.selectedMaterials?.map((material) =>
      material.id === "all" ? "All" : material.id
    ),
    slocs: filter.selectedSlocs?.map((sloc) => sloc.text),
    plants: filter.selectedPlants?.map((plant) => plant.text),
  };
  return payload;
};

export const hasEmptyValues = (filters, selectedTab) => {
  let emptySelection =
    filters?.selectedDates?.length === 0 ||
    filters?.selectedRegions?.length === 0 ||
    filters?.selectedShipTo?.length === 0 ||
    filters?.selectedMaterials?.length === 0;

  if (selectedTab === AFDTabOptions.DELIVERY && !emptySelection) {
    emptySelection =
      emptySelection ||
      filters?.selectedSlocs?.length === 0 ||
      filters?.selectedPlants?.length === 0;
  }

  return emptySelection;
};

export const createSubmitPayload = (changedItems) => {
  const { selectedCluster } = store.getState().reports;
  return changedItems?.map((item) => {
    const comment = item.comment === UIText.Select ? "" : item.comment;
    return {
      onlyCommentUpdated: item?.onlyCommentUpdated ? true : false,
      countryCode: item?.countryCode,
      rowKey: item.rowKey,
      shipTo: item?.shipTo,
      materialNumber: item.materialNumber,
      tankId: item?.tankId,
      outBoundDeliveryId: item?.outBoundDeliveryId,
      documentDate: item?.documentDate,
      reportDate: getFormattedDate(new Date()),
      calculatedDelivery: item.calculatedDelivery,
      confirmedDelivery: item?.confirmedDelivery,
      difference: item?.difference,
      Comment: comment,
      userName: store.getState().user?.user?.UserEmail,
      market: selectedCluster,
    };
  });
};

export const updateExportData = (
  accountForDeliveriesData,
  setExportExcelData
) => {
  let data = [];
  accountForDeliveriesData?.forEach((item) => {
    const tankData = {
      plant: item?.plant,
      documentDate: item?.documentDate,
      shipTo: item?.shipTo,
      sloc: item?.sloc,
      materialNumber: item?.materialNumber,
      outBoundDeliveryIdStr: item?.outBoundDeliveryIdStr,
      type: UIText.Material_Level,
      calculatedDelivery: item?.calculatedDelivery,
      confirmedDelivery: item?.confirmedDelivery,
      difference: item?.difference,
      comment: item?.comment,
    };
    data = [...data, tankData];
    item?.deliveries?.forEach((deliveryItem) => {
      const deliveryData = {
        plant: item?.plant,
        documentDate: item?.documentDate,
        shipTo: item?.shipTo,
        sloc: item?.sloc,
        materialNumber: item?.materialNumber,
        outBoundDeliveryId: deliveryItem?.outBoundDeliveryId,
        type: UIText.Tank_Level,
        calculatedDelivery: deliveryItem?.calculatedDelivery,
        confirmedDelivery: deliveryItem?.confirmedDelivery,
        difference: item?.difference,
        comment: item?.comment,
        deliveryStartDate:
          deliveryItem?.deliveryStartDate?.length > 0
            ? deliveryItem?.deliveryStartDate
            : null,
        startTemperature: deliveryItem?.startTemperature
          ? `${deliveryItem?.startTemperature}°C`
          : "",
        deliveryEndDate:
          deliveryItem?.deliveryEndDate?.length > 0
            ? deliveryItem?.deliveryEndDate
            : null,
        endTemperature: deliveryItem?.endTemperature
          ? `${deliveryItem?.endTemperature}°C`
          : "",
        tank: deliveryItem?.tank,
      };
      data = [...data, deliveryData];
    });
  });

  setExportExcelData(data);
};
