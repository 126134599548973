import { React, useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import {
  saveLoggedInUserUUID,
  setCurrentCluster,
  setCurrentCountry,
} from "../../../state-management/actions";
import { useNavigate } from "react-router-dom";
import "./UserPreference.scss";
import { userService } from "../../../services/user";
import { saveLoggedInUser } from "../../../state-management/actions";
import {
  getAllClusterCountries,
  triggerErrorNotification,
  triggerSuccessNotification,
} from "../reports/components/common-utils";
import { LoadingPanel } from "../../components/loading-panel/loading-panel";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { UIText } from "../reports/components/label-constants";

const UserPreferencePage = () => {
  const store = useStore();
  const dispatch = useDispatch();
  const loggedInUser = store.getState().user?.user;
  const [userPreferenceData, setUserPreferenceData] = useState({
    defaultCountry: loggedInUser?.UserPreference?.DefaultCountry,
    Region: loggedInUser?.UserPreference?.Region || UIText.ALL,
  });
  const [countries, setCountries] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useState(() => {
    const items = getAllClusterCountries();
    const options = items?.map((country) => ({
      title: country,
      value: country,
    }));
    setCountries(options);
  }, []);

  useState(() => {
    setUserPreferenceData({
      ...userPreferenceData,
      defaultCountry: {
        title: loggedInUser?.UserPreference?.DefaultCountry || "",
        value: loggedInUser?.UserPreference?.DefaultCountry || "",
      },
    });
  }, [loggedInUser]);
  const getUserDetailsFromStore = () => {
    if (!loggedInUser) {
      navigate("/login");
      return;
    }
    setUserPreferenceData({
      ...userPreferenceData,
      defaultCountry: {
        title: loggedInUser?.UserPreference?.DefaultCountry || "",
        value: loggedInUser?.UserPreference?.DefaultCountry || "",
      },
    });
  };
  const onClickSavePreference = async () => {
    setLoading(true);
    const data = await userService.UpdateProfileService({
      Region: userPreferenceData.Region,
      DefaultCountry: userPreferenceData?.defaultCountry?.value,
    });
    if (data.data.Status === "Success") {
      const currentUser = store.getState().user?.user;
      if (!currentUser) {
        navigate("/login");
        return;
      }
      const { clusters } = store.getState().reports;

      const selectedCluster = Object?.keys(clusters)?.find((clusterName) => {
        const selectedItem = clusters?.[clusterName].find(
          (item) => item === userPreferenceData?.defaultCountry?.value
        );
        if (selectedItem) {
          return clusterName;
        }
        return null;
      });

      if (selectedCluster) {
        dispatch(setCurrentCluster(selectedCluster));
        dispatch(setCurrentCountry(userPreferenceData?.defaultCountry?.value));
      }

      const user = await userService.fetchUser(currentUser.UniqueUserId);
      store.dispatch(saveLoggedInUser(user));
      store.dispatch(saveLoggedInUserUUID(user?.UniqueUserId));
      triggerSuccessNotification(UIText.Preferences_saved_successfully);
    } else {
      triggerErrorNotification(
        UIText.Error_occurred_while_saving_the_preference
      );
    }
    setLoading(false);
  };
  const handelCountryChange = (option) => {
    const selected = option.value;
    setUserPreferenceData({
      ...userPreferenceData,
      defaultCountry: selected,
    });
  };
  useEffect(() => {
    getUserDetailsFromStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-fluid">
      <div className="row profile-page">
        <div className="container user-details-container">
          <div
            className="row"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="col-md-6 col-sm-6 col-xs-6 col-lg-6 col-xl-6 user-details-table-container">
              <div className="user-details-heading">
                {UIText.User_Preferences}
              </div>
              <div className="country-container">
                <div className="country-label">{UIText.Country}</div>
                <div>
                  <DropDownList
                    className="dropdownList"
                    data={countries}
                    textField="value"
                    dataItemKey="title"
                    value={userPreferenceData.defaultCountry}
                    onChange={handelCountryChange}
                  />
                </div>
              </div>
              <div className="btn-container">
                <button
                  className="home-btn"
                  disabled={
                    userPreferenceData?.defaultCountry ===
                    loggedInUser?.UserPreference?.DefaultCountry
                  }
                  onClick={onClickSavePreference}
                >
                  {UIText.Save}
                </button>
                <button
                  className="logout-btn"
                  onClick={() => navigate("/home")}
                >
                  {UIText.Back}
                </button>
              </div>
            </div>
            {loading && <LoadingPanel message={UIText.Submitting_the_values} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export { UserPreferencePage };
