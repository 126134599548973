import jwtDecode from "jwt-decode";
import { loggerService } from "../services/logger";
import {
  THIRTY_MINS,
  triggerSuccessNotification,
} from "../ui/pages/reports/components/common-utils";
import { UIText } from "../ui/pages/reports/components/label-constants";
/**
 * This service will help in token management.
 * It is capable of decoding, refreshing and disposing a token.
 */
class TokenManager {
  sessionTimer = null;
  token = null;

  /**
   * This function will decode a given JWT into human readable JSON format.
   * @param {string} token - A valid JWT token.
   */
  decodeToken(token) {
    this.token = token;
    try {
      return token ? jwtDecode(token) : null;
    } catch (err) {
      triggerSuccessNotification(UIText.Unable_to_decode_the_token);
      return null;
    }
  }

  /**
   * This method starts a session timeout, once the timeout runs out the session will be disposed off and user will be logged out.
   */
  triggerSessionTimeout() {
    this.sessionTimer = setTimeout(() => {
      this.disposeSession();
    }, THIRTY_MINS); // A session timeout of 30 mins
  }

  /**
   * This method resets the session timeout, and gives the user another 30 min.
   */
  resetSessionTimeout() {
    clearTimeout(this.sessionTimer);
    this.triggerSessionTimeout();
  }

  /**
   * This method is used to dispose off an ongoing session and log the user out of system.
   */
  disposeSession() {
    clearTimeout(this.sessionTimer);
    window.location.replace("/login");
  }
}

loggerService.dev("Creating Token Manager Service");
const tokenManager = new TokenManager();

loggerService.dev("Exporting Token Manager Service");
export { tokenManager };
