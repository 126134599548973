import "./issue-list.scss";
import { Button, DropDownList } from "@progress/kendo-react-all";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { issueService } from "../../../../../../services/issue";
import { store } from "../../../../../../state-management/store";
import { useSelector } from "react-redux";
import {
  getDateAfterDays,
  getDaysBeforeDate,
  getFormattedDate,
  getFormattedFullDate,
} from "../../../../../../services/utils";
import { LoadingPanel } from "../../../../../components/loading-panel/loading-panel";
import {
  colsToShow,
  getActionParties,
  getStatusList,
} from "./issue-list-utils";
import { SiteInfoScreen } from "../../out-of-tolerance-trend-analysis/out-of-tolerance-site-info";
import { AddIssueScreen } from "../issue-add/issue-add";
import { UpdateIssueScreen } from "../issue-update/issue-update";
import { NUMBERS, triggerErrorNotification } from "../../common-utils";
import { reportsService } from "../../../../../../services/reports";
import { UIText } from "../../label-constants";
import { getFilterRegions } from "../../../reportUtils";
import {
  onFilterSelectionChange,
  onOBNChange,
  onRegionChange,
  onShipToValueChange,
  onSiteNameChange,
} from "../../common-report-filters";
import { MultiSelectionDropDown } from "../../../../../components/multi-select-dropdown/multi-select-dropdown";
import { DateRangePickerComponent } from "../../../../../components/date-range-picker/date-range-picker";
import { EndDateInput, StartDateInput } from "../../common-grid-cells";
import { AppDataGrid } from "../../../../../components/data-grid/dataGrid";

const DropDownSelection = {
  ShipTo: NUMBERS.ZERO,
  SiteName: NUMBERS.ONE,
  OBN: NUMBERS.TWO,
};

const IssueListScreen = () => {
  const pageSize = NUMBERS.FIFTY;
  const requestInProgress = useRef(false);
  const [showAddIssue, setShowAddIssue] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState(null);
  const [showUpdateIssue, setShowUpdateIssue] = useState(false);
  const [issues, setIssueList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [currentField, setCurrentField] = useState(null);
  const [showError, setShowError] = useState(false);
  const defaultCountry = useSelector(
    (state) => state?.reports?.selectedCountry
  );
  const [dateRestriction, setDateRestriction] = useState(false);
  const [masterData, setMasterData] = useState();
  const [currentCountryCode, setCurrentCountryCode] = useState(defaultCountry);
  const [issueToUpdate, setIssueToUpdate] = useState();
  const [showSiteInfoModal, setShowSiteInfoModal] = useState(false);
  const [selectedSite, setSelectedSite] = useState();
  const [maxReached, setMaxReached] = useState(false);
  const pageNo = NUMBERS.ONE;

  const defaultFilters = {
    country: currentCountryCode,
    selectedRegions: [],
    regions: [],
    selectedShipTo: [],
    allSiteIds: [],
    selectedSiteName: [],
    allSiteNames: [],
    selectedOBN: [],
    allOBNs: [],
    raisedDateIsoString: new Date().toISOString(),
    closedDate: null,
    closedDateIsoString: new Date().toISOString(),
    selectedDates: {
      start: getDaysBeforeDate(NUMBERS.SIX),
      end: new Date(),
    },
    status: {
      id: UIText.Open,
      text: UIText.Open,
    },
    page: null,
    limit: null,
    statusList: getStatusList(),
    actionParty: {
      id: UIText.CWR,
      text: UIText.CWR,
    },
    actionParties: getActionParties(),
  };

  const [filters, setFilters] = useState(defaultFilters);

  const [regionSelectedValues, setRegionSelectedValues] = useState(
    filters.selectedRegions
  );

  const [shipToSelectedValues, setShipToSelectedValues] = useState(
    filters.selectedShipTo
  );
  const [siteNameSelectedValues, setSiteNameSelectedValues] = useState(
    filters.selectedSiteName
  );

  const [obnSelectedValues, setObnSelectedValues] = useState(
    filters.selectedOBN
  );

  useEffect(() => {
    const regions = getFilterRegions(masterData, false, currentCountryCode);
    const selectedRegions = regions?.length > 0 ? [regions?.[0]] : [];
    setFilters((prev) => {
      return {
        ...prev,
        regions,
        selectedRegions,
      };
    });
    setRegionSelectedValues(selectedRegions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterData]);

  useEffect(() => {
    onRegionChange({
      filters,
      setFilters,
      masterData,
      setShipToSelectedValues,
      setObnSelectedValues,
      setSiteNameSelectedValues,
      addAllOption: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedRegions]);

  useEffect(() => {
    if (filters.regions?.length > NUMBERS.ONE) {
      setFilters((prev) => {
        return {
          ...prev,
          selectedRegions: regionSelectedValues,
        };
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionSelectedValues]);

  useEffect(() => {
    if (currentField === DropDownSelection.ShipTo) {
      onShipToValueChange(
        filters,
        setFilters,
        shipToSelectedValues,
        setObnSelectedValues,
        setSiteNameSelectedValues
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipToSelectedValues]);

  useEffect(() => {
    if (currentField === DropDownSelection.SiteName) {
      onSiteNameChange(
        filters,
        setFilters,
        siteNameSelectedValues,
        setShipToSelectedValues,
        setObnSelectedValues
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteNameSelectedValues]);

  useEffect(() => {
    if (currentField === DropDownSelection.OBN) {
      onOBNChange(
        filters,
        setFilters,
        obnSelectedValues,
        setShipToSelectedValues,
        setSiteNameSelectedValues
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obnSelectedValues]);

  const getData = async () => {
    setLoading(true);
    try {
      setFilters((_prev) => defaultFilters);
      setObnSelectedValues([]);
      setRegionSelectedValues([]);
      await fetchMasterData();
    } catch (error1) {
      triggerErrorNotification(
        UIText.Server_error_Unable_to_submit_the_request
      );
    }
    setLoading(false);
  };

  store.subscribe(() => {
    const { selectedCountry } = store.getState().reports;
    setCurrentCountryCode(selectedCountry);
  });

  useEffect(() => {
    getData();
    setFilters((prev) => ({
      ...prev,
      country: currentCountryCode,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountryCode]);

  const fetchMasterData = async () => {
    try {
      setIssueList([]);
      setShowError(false);
      setFetching(true);

      const response = await reportsService.fetchGainAndLossMasterData({
        country: currentCountryCode,
      });
      setFetching(false);
      if (response) {
        setMasterData([...response]);
      } else {
        triggerErrorNotification(UIText.Unable_to_fetch_the_data);
      }
    } catch (error1) {
      triggerErrorNotification(
        UIText.Server_error_Unable_to_submit_the_request
      );
      setFetching(false);
    }
  };

  const isValuesSelected = (filter) => {
    const selectedShipTo = filter?.selectedShipTo?.[0]?.id;
    return selectedShipTo;
  };

  const getIssues = async (pageNumber, refresh = false) => {
    const filter = refresh ? appliedFilters : filters;
    const valuesSelected = isValuesSelected(filter);
    if (valuesSelected) {
      if (!refresh) {
        setAppliedFilters(filter);
      }
      setFetching(true);
      try {
        let updatedIssues = await issueService.getAllIssues({
          ...filter,
          country: currentCountryCode,
          pageNo: pageNumber,
        });
        requestInProgress.current = false;
        updatedIssues = updatedIssues?.map((issue, index) => {
          const transactionDate = issue?.transactionDate
            ? getFormattedDate(new Date(issue?.transactionDate))
            : "";
          const raisedDate = issue?.raisedDate
            ? getFormattedFullDate(new Date(issue?.raisedDate))
            : "";

          return {
            ...issue,
            transactionDate: transactionDate,
            raisedDate: raisedDate,
            Index: index + issues?.length,
          };
        });

        if (updatedIssues?.length > 0) {
          setIssueList((prev) => {
            return [...prev, ...updatedIssues];
          });
        } else {
          setMaxReached(true);
        }
      } catch (error1) {
        requestInProgress.current = false;
        triggerErrorNotification(
          UIText.Server_error_Unable_to_submit_the_request
        );
      }
      setFetching(false);
    } else {
      setShowError(true);
    }
  };

  const onDateSelection = (event) => {
    if (event.value?.start && event.value?.end) {
      setDateRestriction(false);
    } else {
      setDateRestriction(true);
    }
    setFilters((prev) => {
      return {
        ...prev,
        selectedDates: event.value,
      };
    });
  };

  const onRegionSelection = (event) => {
    onFilterSelectionChange(event, setRegionSelectedValues, filters?.regions);
  };

  const onShipToSelection = (event) => {
    setCurrentField(DropDownSelection.ShipTo);
    onFilterSelectionChange(
      event,
      setShipToSelectedValues,
      filters?.allSiteIds,
      true
    );
  };

  const onSiteNameSelection = (event) => {
    setCurrentField(DropDownSelection.SiteName);
    onFilterSelectionChange(
      event,
      setSiteNameSelectedValues,
      filters?.allSiteNames
    );
  };

  const onOBNSelection = (event) => {
    setCurrentField(DropDownSelection.OBN);
    onFilterSelectionChange(
      event,
      setObnSelectedValues,
      filters?.allOBNs,
      true
    );
  };

  const setActionParty = (e) => {
    const actionParty = e?.target?.value;
    setFilters((prev) => {
      return { ...prev, actionParty };
    });
  };

  const setStatus = (e) => {
    const status = e?.target?.value;
    setFilters((prev) => {
      return { ...prev, status };
    });
  };
  const handleIssueUpdate = (issue) => {
    setIssueToUpdate(issue);
    setShowUpdateIssue(true);
  };

  const handleIssueAdd = () => {
    setShowAddIssue(true);
  };

  const headerButtons = () => {
    return (
      <div className="buttonsContainer">
        <div className="submitButtonContainer">
          <span className="filter-button">
            <Button
              className="get-data-btn"
              onClick={() => {
                setMaxReached(false);
                setIssueList([]);
                getIssues(pageNo);
              }}
              disabled={loading}
            >
              {loading ? UIText.Fetching : UIText.Get_data}
            </Button>
          </span>
          <span className="filter-button">
            <Button
              className="refresh-btn"
              onClick={() => {
                setIssueList([]);
                setMaxReached(false);
                getIssues(pageNo, true);
              }}
              disabled={!appliedFilters}
            >
              {UIText.Refresh}
            </Button>
          </span>
        </div>
        <div className="submitButtonContainer" />
        <div className="submitButtonContainer">
          <span className="filter-button">
            <Button
              className="exportButton"
              onClick={exportData}
              disabled={exporting}
            >
              {exporting ? UIText.Exporting : UIText.Export}
            </Button>
          </span>

          <span className="filter-button">
            <Button className="btn-primary" onClick={handleIssueAdd}>
              {UIText.Add_a_new_issue}
            </Button>
          </span>
        </div>
      </div>
    );
  };

  const exportData = async () => {
    setExporting(true);
    const fromDate = filters?.selectedDates?.start;
    const toDate = filters?.selectedDates?.end;
    const startDate = getFormattedDate(fromDate);
    const endDate = getFormattedDate(toDate);
    const payload = {
      region: filters.region,
      status: filters.status,
      startDate: startDate,
      endDate: endDate,
      globalSiteId: filters.selectedSiteId,
      country: currentCountryCode,
      actionParty: filters.actionParty,
    };
    try {
      await issueService.downloadIssueExcel(payload);
    } catch (error1) {
      triggerErrorNotification(
        UIText.Server_error_Unable_to_submit_the_request
      );
    }
    setExporting(false);
  };

  const startDateInput = (propValues) => (
    <StartDateInput {...propValues} showError={showError} />
  );

  const endDateInput = (propValues) => (
    <EndDateInput {...propValues} showError={showError} />
  );

  const filterHeader = () => {
    const startDate = filters?.selectedDates?.start;
    const maxValue =
      dateRestriction && startDate
        ? getDateAfterDays(startDate, NUMBERS.THIRTY)
        : undefined;
    const minValue = dateRestriction && startDate ? startDate : undefined;
    return (
      <div className="header-container">
        <span className="filters-container">
          <div className="filter-date">
            <DateRangePickerComponent
              defaultValue={filters.selectedDates}
              value={filters.selectedDates}
              startDateInput={startDateInput}
              endDateInput={endDateInput}
              onChange={onDateSelection}
              max={maxValue}
              min={minValue}
            />
          </div>
          {filters.regions?.length > NUMBERS.ONE && (
            <span className="filter">
              <label htmlFor="region">{UIText.Region}</label>
              <MultiSelectionDropDown
                data={filters.regions}
                values={regionSelectedValues}
                onChange={onRegionSelection}
                filterable={false}
                showError={showError}
                customStyle={{
                  width: "9vw",
                  minHeight: "2vh",
                }}
              />
            </span>
          )}
          <span className="filter">
            <label htmlFor="shipTo">{UIText.ShipTo}</label>
            <MultiSelectionDropDown
              data={filters.allSiteIds}
              values={shipToSelectedValues}
              onChange={onShipToSelection}
              filterable={true}
              showError={showError}
              customStyle={{ width: "7.5vw", minHeight: "3vh" }}
              maxSelection={NUMBERS.ONE}
            />
          </span>
          <span className="filter">
            <label htmlFor="siteName">{UIText.Site_Name}</label>
            <MultiSelectionDropDown
              data={filters.allSiteNames}
              values={siteNameSelectedValues}
              onChange={onSiteNameSelection}
              filterable={true}
              showError={showError}
              customStyle={{ width: "15vw", minHeight: "3vh" }}
              maxSelection={NUMBERS.ONE}
            />
          </span>
          <span className="filter">
            <label htmlFor="OBN">{UIText.OBN}</label>
            <MultiSelectionDropDown
              data={filters.allOBNs}
              values={obnSelectedValues}
              onChange={onOBNSelection}
              filterable={true}
              showError={showError}
              customStyle={{ width: "6vw", minHeight: "3vh" }}
              maxSelection={NUMBERS.ONE}
            />
          </span>
          <span className="filter">
            <label htmlFor="actionParty">{UIText.Action_Party}</label>
            <DropDownList
              data={filters?.actionParties}
              textField="text"
              dataItemKey="id"
              onChange={setActionParty}
              value={filters.actionParty}
              style={{
                width: "7vw",
                marginRight: "1vw",
                fontSize: "10px",
              }}
            />
          </span>
          <span className="filter">
            <label htmlFor="status">{UIText.Status}</label>
            <DropDownList
              filterable
              data={filters?.statusList}
              textField="text"
              dataItemKey="id"
              onChange={setStatus}
              value={filters.status}
              style={{
                width: "7vw",
                marginRight: "1vw",
                fontSize: "10px",
              }}
            />
          </span>
        </span>
      </div>
    );
  };

  const showUpdateScreen = () => {
    return (
      <UpdateIssueScreen
        issue={issueToUpdate}
        onClose={() => setShowUpdateIssue(false)}
        onSave={async () => {
          setShowUpdateIssue(false);
          await getIssues(pageNo);
        }}
      />
    );
  };

  const handleShipToLinkAction = (siteId, country) => {
    setSelectedSite({ siteId: siteId, country: country });
    setShowSiteInfoModal(true);
  };

  const closeModal = () => {
    setShowSiteInfoModal(false);
  };

  const requestData = (pageNumber) => {
    if (requestInProgress.current) {
      return;
    }
    requestInProgress.current = true;
    getIssues(pageNumber);
  };

  const requestIfNeeded = (rowIndex) => {
    if (rowIndex + pageSize >= issues?.length) {
      const newPage = parseInt(issues?.length / pageSize);
      const pageNumber = newPage + NUMBERS.ONE;
      requestData(pageNumber);
    }
  };

  const pageChange = (rowIndex) => {
    if (!maxReached) {
      requestIfNeeded(rowIndex);
    }
  };

  const showListScreen = () => {
    return (
      <div className="issue-log-screen">
        {filterHeader()}
        {headerButtons()}
        {showSiteInfoModal && (
          <SiteInfoScreen
            globalSiteId={selectedSite?.siteId}
            countryCode={currentCountryCode}
            close={closeModal}
          />
        )}
        {showAddIssue && (
          <AddIssueScreen
            regions={masterData}
            issue={issueToUpdate}
            onClose={() => setShowAddIssue(false)}
            onSave={async () => {
              setShowAddIssue(false);
              await getIssues(pageNo);
            }}
          />
        )}
        {exporting && <LoadingPanel message={UIText.Exporting_the_data} />}
        {fetching && <LoadingPanel message={UIText.Fetching_the_data} />}
        <div className="row issue-logs-container">
          {loading ? (
            <LoadingPanel message={UIText.Fetching_the_data} />
          ) : (
            <div>
              <AppDataGrid
                rowHeight={NUMBERS.FIFTY}
                data={issues}
                pageSize={pageSize}
                pageChange={pageChange}
                virtualScroll={true}
                style={{
                  height: "63vh",
                }}
                filterable={true}
                columnsToShow={colsToShow(
                  issues,
                  handleIssueUpdate,
                  handleShipToLinkAction
                )}
                sortable={true}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  return showUpdateIssue ? showUpdateScreen() : showListScreen();
};
export { IssueListScreen };
